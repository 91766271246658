import React from "react";
import { useNavigate } from "react-router-dom";

//libraries
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Select, Space } from "antd";

const { Option } = Select;

export default function Pagination({
  query,
  activeproject,
  source,
  projecturls,
  projecturlsCount,
  filterDataCount,
}) {
  let navigate = useNavigate();

  const parsedQuery = query !== null && query !== "" && JSON.parse(query);

  const page = parsedQuery?.pagination?.page
    ? parsedQuery?.pagination?.page
    : 1;
  const limit = parsedQuery?.pagination?.limit
    ? parsedQuery?.pagination?.limit
    : 50;

  const [pageState, setpageState] = React.useState(page);

  const prev = () => {
    setpageState(page - 1);
    const stringQuery = JSON.stringify({
      ...parsedQuery,
      pagination: { page: page - 1, limit: limit },
    });
    navigate(
      `/project/${source ? source : "research"}/${
        activeproject?._id
      }?query=${stringQuery}`
    );
  };

  const next = () => {
    setpageState(page + 1);
    const stringQuery = JSON.stringify({
      ...parsedQuery,
      pagination: { page: page + 1, limit: limit },
    });
    navigate(
      `/project/${source ? source : "research"}/${
        activeproject?._id
      }?query=${stringQuery}`
    );
  };

  const changeLimit = (value) => {
    const stringQuery = JSON.stringify({
      ...parsedQuery,
      pagination: { page: 1, limit: value },
    });
    navigate(
      `/project/${source ? source : "research"}/${
        activeproject?._id
      }?query=${stringQuery}`
    );
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        marginTop: 20,
      }}
    >
      <Space>
        <Select
          size="small"
          defaultValue={limit}
          style={{ width: 100 }}
          onChange={(value) => changeLimit(value)}
        >
          <Option value={50}>50</Option>
          <Option value={100}>100</Option>
          <Option value={200}>200</Option>
        </Select>

        <Button size="small" disabled={page <= 1} onClick={prev}>
          <LeftOutlined />
        </Button>

        <Select
          size="small"
          value={pageState}
          onChange={(value) => {
            const stringQuery = JSON.stringify({
              ...parsedQuery,
              pagination: { page: value, limit: limit },
            });
            navigate(
              `/project/${source ? source : "research"}/${
                activeproject?._id
              }?query=${stringQuery}`
            );
          }}
        >
          {filterDataCount && filterDataCount > 0 ? (
            Array.from(Array(Math.ceil(filterDataCount / limit)).keys()).map(
              (i) => (
                <Option key={i} value={i + 1}>
                  {i + 1}
                </Option>
              )
            )
          ) : projecturlsCount && projecturlsCount > 0 ? (
            Array.from(Array(Math.ceil(projecturlsCount / limit)).keys()).map(
              (i) => (
                <Option key={i} value={i + 1}>
                  {i + 1}
                </Option>
              )
            )
          ) : (
            <Option value={1}>1</Option>
          )}
        </Select>
        {/* {projecturlsCount && projecturlsCount > 0 ? (
            Array.from(Array(Math.ceil(projecturlsCount / limit)).keys()).map(
              (i) => (
                <Option key={i} value={i + 1}>
                  {i + 1}
                </Option>
              )
            )
          ) : (
            <Option value={1}>1</Option>
          )}
        </Select> */}

        <Button
          size="small"
          onClick={next}
          disabled={projecturls?.length < limit}
        >
          <RightOutlined />
        </Button>
      </Space>
    </div>
  );
}
