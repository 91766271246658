import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, Modal, Space } from "antd";
import { anchorApi } from "../../../utils/axios";
import HandleError from "../../../utils/errorHandle";
import AppSpin from "../../AppSpin";

export const Assignee = ({ activeproject, setactiveproject }) => {
  const [manageAssignee, setManageAssignee] = useState(false);
  const [projectUsers, setProjectUsers] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState(activeproject?.assignedTo);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    anchorApi
      .get(`/project/get-project-users/${activeproject?._id}`)
      .then((res) => {
        setProjectUsers(res.data);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
      });
  }, [activeproject]);

  const selectAllTeamMembers = (e) => {
    if (e.target.checked) {
      //set all the users as assigned if the checkbox is checked and not already assigned
      const users = projectUsers?.map((user) => {
        const userExists = assignedUsers?.find((item) => item._id === user._id);
        if (!userExists) {
          return user;
        }
      });

      const onlyUsers = users?.filter((item) => item !== undefined);

      setAssignedUsers([...assignedUsers, ...onlyUsers]);
    } else {
      setAssignedUsers([]);
    }
  };
  const selectSingleTeamMember = (e, teamMember) => {
    if (e.target.checked) {
      const userExists = assignedUsers?.find((item) => item._id === teamMember._id);

      if (!userExists) {
        setAssignedUsers((prev) => [...prev, teamMember]);
      }
    } else {
      setAssignedUsers(
        assignedUsers?.filter((item) => item._id !== teamMember._id)
      );
    }
  };

  const handleAssigneeSubmit = () => {
    setLoading(true);
    anchorApi
      .post(`/project/update-project-assigne/${activeproject?._id}`, {
        assignedTo: assignedUsers.map(user => user._id)
      })
      .then((res) => {
        setManageAssignee(false);
        setLoading(false);
        setactiveproject(res.data);
        setAssignedUsers(res.data.assignedTo);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        size="small"
        type="dashed"
        onClick={() => setManageAssignee(true)}
      >
        <span>
          {activeproject?.assignedTo?.length === 0
            ? "No Assignee"
            : activeproject?.assignedTo?.map(user => user.fullName).join(", ")}
          <EditOutlined />
        </span>
      </Button>

      {manageAssignee && (
        <Modal
          visible={manageAssignee}
          onCancel={() => {
            setManageAssignee(false);
            setAssignedUsers(activeproject?.assignedTo);
          }}
          footer={null}
          title="Manage Assignee"
        >
          <AppSpin spining={loading}>
            {projectUsers?.length === 0 ? (
              <Alert title="No team members has permission for this project"/>
            ) : (
              <>
                <Space>
                  <Checkbox
                    onChange={(e) => selectAllTeamMembers(e)}
                    size="small"
                    checked={assignedUsers?.length === projectUsers?.length}
                  >
                    <span
                      style={{
                        color: "#828588",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      Permitted Team Members
                    </span>
                  </Checkbox>
                </Space>

                <div style={{ marginTop: 14, marginLeft: 23 }}>
                  {projectUsers?.map((teamMember, i) => (
                    <div key={i} style={{ marginBottom: 6 }}>
                      <Checkbox
                        onChange={(e) => selectSingleTeamMember(e, teamMember)}
                        checked={
                          assignedUsers?.filter(
                            (item) => item._id === teamMember._id
                          ).length !== 0
                        }
                      >
                        {teamMember.fullName}
                      </Checkbox>
                    </div>
                  ))}
                </div>

                <Button
                  type="primary"
                  style={{ marginTop: 14 }}
                  onClick={handleAssigneeSubmit}
                  disabled={loading || projectUsers === null}
                >
                  Save Chages
                </Button>
              </>
            )}
          </AppSpin>
        </Modal>
      )}
    </>
  );
};
