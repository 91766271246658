import React, { useContext, useEffect, useState } from "react";

import { Alert, Cascader, Form, Modal, Select } from "antd";
import HandleError from "../../utils/errorHandle";
import HandleSuccess from "../../utils/successHandle";
import PopupFooter from "../PopupFooter";
import AppSpin from "../AppSpin";
import ProjectFolderContext from "../../context/projectFolders";
import { ApartmentOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { anchorApi } from "../../utils/axios";

const { Option } = Select;

export default function CopyToAnotherProject({
  selctedurls,
  copyToAnotherProjec,
  setCopyToAnotherProjectmoalvis,
  activeproject
}) {
  const [actionloading, setactionloading] = useState(false);

  const [options, setOptions] = useState([]);

  const { getAllProjectsFolders, folders } = useContext(ProjectFolderContext);

  useEffect(() => {
    if (folders === null) {
      getAllProjectsFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders]);

  useEffect(() => {
    //format for cascader
    const formatForCascader = (folders) => {
      return folders
        .filter((fItem) => fItem.name !== "Drafted")
        .filter((fItem) => fItem.projects?.length !== 0)
        .map((folder) => {
          return {
            value: folder._id,
            label: (
              <span>
                {" "}
                {folder.projects === undefined ? (
                  <ApartmentOutlined />
                ) : (
                  <FolderOpenOutlined />
                )}{" "}
                {folder.name}
              </span>
            ),
            children:
              folder.projects?.length > 0
                ? formatForCascader(folder.projects)
                : [],
          };
        });
    };

    if (folders !== null) {
       //filter and remove the active project from the projects list of each folder
      const filteredFolders = folders.map((folder) => {
        return {
          ...folder,
          projects: folder.projects.filter(
            (project) => project._id !== activeproject?._id
          ),
        };
      });

      const allFolders = formatForCascader(filteredFolders);
      setOptions(allFolders);
    }
  }, [folders]);

  const updateUrlCaller = (values) => {
    let value = null;

    if (values?.targetProject?.length === 2) {
      value = values?.targetProject[1];
    }

    if (value === null) {
      HandleError({
        type: "string",
        error: "Sorry something went wrong.",
      });
      return;
    }
    setactionloading(true);
    anchorApi
      .post(`/project/move-global-urls-to-project`, {
        urlIds: selctedurls,
        targetProject: value,
      })
      .then((response) => {
        HandleSuccess({
          msg: "Urls copied successfully.",
        });
        setCopyToAnotherProjectmoalvis(false);
        setactionloading(false);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
        setactionloading(true);
      });
  };

  return (
    <Modal
      visible={copyToAnotherProjec}
      onCancel={() => setCopyToAnotherProjectmoalvis(false)}
      title="Copy To Another Project"
      footer={null}
    >
      <AppSpin spining={actionloading || folders === null}>

      <Alert
            message="Only unique urls will be copied to the target project."
            type="info"
            style={{  marginBottom: 10 }}
          />

        <Form layout="vertical" onFinish={updateUrlCaller}>
          {folders !== null && (
            <Form.Item
              label="Target Project"
              name="targetProject"
              rules={[
                {
                  required: true,
                  message: "Target project is required",
                },
              ]}
            >
              <Cascader
                options={options}
                style={{ width: "100%" }}
                placeholder="Filter By Project"
              />
            </Form.Item>
          )}

          <PopupFooter
            action={setCopyToAnotherProjectmoalvis}
            btnText="Copy To Another Project"
          />
        </Form>
      </AppSpin>
    </Modal>
  );
}
