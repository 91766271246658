import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Space } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

//contexts
import UserContext from "../context/User";

//components
import Sidebar from "./Sidebar";
import Header from "./Header";
import Loading from "./Loading";
import CenterLayout from "./CenterLayout";
import ProjectList from "./Projects/List";

//utils
import { anchorApi } from "../utils/axios";
import HandleError from "../utils/errorHandle";

function AppLayout() {
  const location = useLocation();
  let navigate = useNavigate();

  const { user, setuser } = useContext(UserContext);

  const [hidesidebar, sethidesidebar] = useState(() =>
    localStorage.getItem("hidesidebar") === "false" ? false : true
  );
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    anchorApi
      .get("/account/me")
      .then((res) => {
        setuser(res.data);
        setloading(false);
        if(location.pathname === "/"){
          return navigate("/dashboard");
        }
      })
      .catch((err) => {
        HandleError({
          type: "string",
          error: "You have been loggedout, redirecting...",
        });
        return navigate("/login");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (user === null) {
    return (
      <CenterLayout height="100vh">
        <Loading loading={loading} />
      </CenterLayout>
    );
  }
  return (
    <Space size={0}>
      <Sidebar />
      {!hidesidebar && <ProjectList />}
      <div
        style={{
          background: "red",
          position: "fixed",
          top: 40,
          left: !hidesidebar ? 220 + 60 : 42,
          zIndex: 1000,
          borderRadius: "50%",
          height: 26,
          width: 26,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          color: "#fff",
          backgroundColor: "#8b3dff",
          fontSize: "14px",
          fontWeight: "500",
        }}
        onClick={() => {
          sethidesidebar((prev) => !prev);
          localStorage.setItem("hidesidebar", !hidesidebar);
        }}
      >
        {!hidesidebar ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </div>
      <div
        style={{
          height: "100vh",
          width: !hidesidebar
            ? "calc(100vw -  210px  - 56px - 30px)"
            : "calc(100vw - 56px)",
          margin: 0,
          padding: 0,
          overflowY: "auto",
        }}
      >
        <Header />

        {/* {loading ? (
          <CenterLayout height="100%">
            <Loading />
          </CenterLayout>
        ) : (
          <Outlet />
        )} */}

        <Outlet />
      </div>
    </Space>
  );
}

export default AppLayout;
