import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//utils
import { anchorApi } from "../utils/axios";
import HandleError from "../utils/errorHandle";

const UploadProjectUrlContext = React.createContext();

export const UploadProjectUrlProvider = (props) => {
  const location = useLocation();
  let navigate = useNavigate();

  const [current, setcurrent] = useState(0);
  const [activetemplate, setactivetemplate] = useState(null);
  const [createdproject, setcreatedproject] = useState(null);
  const [formloading, setformloading] = useState(false);

  const [loading, setloading] = useState(false);

  const [latestdraftedproject, setlatestdraftedproject] = useState(null);

  //keywords

  const [columnTemplate, setColumnTemplate] = useState(null);
  const [keyWordsMethod, setKeyWordsMethod] = useState("1");

  const [uploadLoading, setUploading] = useState(false);
  const [showPreview, setshowPreview] = useState(false);
  const [keywordFileData, setKeywordFileData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  //keywords

  //project urls
  const [urlsfilepploading, seturlsfilepploading] = useState(false);
  const [urlsfiledata, seturlsfiledata] = useState(null);
  const [urlsfilename, seturlsfilename] = useState(null);
  const [totalurlsfiledata, settotalurlsfiledata] = useState(null);
  const [selectedcolumns, setselectedcolumns] = useState([]);
  const [filteredcolumns, setFilteredcolumns] = useState([]);
  const [socketresult, setsocketresult] = useState(0);

  const [socketloading, setsocketloading] = useState(false);

  const [validationresult, setvalidationresult] = useState(false);

  //projecturls

  //set the action completed when somewhere navigated
  useEffect(() => {
    setcurrent(0);
    setsocketresult(0);
    setactivetemplate(null);
    setcreatedproject(null);
    setlatestdraftedproject(null);
    setKeywordFileData(null);
    setSelectedOptions([]);
    setshowPreview(false);
    setKeyWordsMethod("1");
  }, [location.pathname]);

  const getProjectDetail = (projectId, setactiveproject, setpagetitle) => {
    setloading(true);
    anchorApi
      .get(`/project/detail/${projectId}`)
      .then((res) => {
        setactiveproject(res.data);
        setpagetitle(res.data?.name);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        HandleError(err.response?.data);
        navigate("/dashboard");
      });
  };

  return (
    <UploadProjectUrlContext.Provider
      value={{
        current,
        setcurrent,
        activetemplate,
        setactivetemplate,
        createdproject,
        setcreatedproject,
        formloading,
        setformloading,
        loading,
        setloading,
        latestdraftedproject,
        columnTemplate,
        setColumnTemplate,
        keyWordsMethod,
        setKeyWordsMethod,
        uploadLoading,
        setUploading,
        showPreview,
        setshowPreview,
        keywordFileData,
        setKeywordFileData,
        selectedOptions,
        setSelectedOptions,
        urlsfilepploading,
        seturlsfilepploading,
        urlsfiledata,
        seturlsfiledata,
        urlsfilename,
        seturlsfilename,
        totalurlsfiledata,
        settotalurlsfiledata,
        selectedcolumns,
        setselectedcolumns,
        filteredcolumns,
        setFilteredcolumns,
        socketresult,
        setsocketresult,
        socketloading,
        setsocketloading,
        validationresult,
        setvalidationresult,
        getProjectDetail,
      }}
    >
      {props.children}
    </UploadProjectUrlContext.Provider>
  );
};

export default UploadProjectUrlContext;
