import { anchorApi } from "./axios";

export const getProjectJobs = async (projectId, query, status) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .get(`/project/uploadUrlsJobStatus/${projectId}`)
      .then((response) => {
        return res(response.data);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const getProjectData = async (projectId, query, status) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .get(`/project/urls/${projectId}`, {
        params: {
          query: query !== null && query !== "" && (await JSON.parse(query)),
          status: status,
        },
      })
      .then((response) => {
        return res(response.data);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const getProjectUploadBatchData = async (projectId, query, log) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .get(`/project/getUploadBatchUrls/${projectId}`, {
        params: {
          query: query !== null && query !== "" && (await JSON.parse(query)),
          log: log,
        },
      })
      .then((response) => {
        return res(response.data);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const approveUrl = (
  projectId,
  selectedUrls,
  allprojecturlsselected,
  includeLiveLinks
) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/approve-urls/${projectId}`, {
        selectedUrls,
        allprojecturlsselected,
        includeLiveLinks,
      })
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};
export const editUrlColumn = (projectId, values, urlId, status) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/update-url-column/${projectId}`, {
        values,
        urlId,
        status,
      })
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const backToResearch = (
  projectId,
  selectedUrls,
  allprojecturlsselected,
  includeLiveLinks
) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/move-to-research/${projectId}`, {
        selectedUrls,
        allprojecturlsselected,
        includeLiveLinks,
      })
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const backToManagement = (
  projectId,
  selectedUrls,
  allprojecturlsselected,
  includeLiveLinks
) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/move-to-management/${projectId}`, {
        selectedUrls,
        allprojecturlsselected,
        includeLiveLinks,
      })
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const deapproveUrl = (
  projectId,
  selectedUrls,
  allprojecturlsselected,
  includeLiveLinks
) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/deapprove-urls/${projectId}`, {
        selectedUrls,
        allprojecturlsselected,
        includeLiveLinks,
      })
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const blacklistUrl = (
  projectId,
  selectedUrls,
  allprojecturlsselected,
  includeLiveLinks
) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/blacklist-urls/${projectId}`, {
        selectedUrls,
        allprojecturlsselected,
        includeLiveLinks,
      })
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const getKeyWords = (projectId) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .get(`/project/keywords/${projectId}`)
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const addNewKeyword = (projectId, values) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/addkeyword/${projectId}`, values)
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const clientAnchors = (projectId, keywordId) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .get(`/project/clientAnchors/${projectId}/${keywordId}`)
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const competitorAnchors = (projectId, keywordId) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .get(`/project/competitorAnchors/${projectId}/${keywordId}`)
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const updateUrl = (
  projectId,
  urlIds,
  values,
  allprojecturlsselected,
  includeLiveLinks,
  source
) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/update-url/${projectId}`, {
        values,
        urlIds,
        allprojecturlsselected,
        includeLiveLinks,
        source,
      })
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};
export const updateUrlFormApi = (
  projectId,
  urlId,
  values,
  allprojecturlsselected,
  includeLiveLinks
) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/update-url-column/${projectId}`, {
        values,
        urlId,
        allprojecturlsselected,
        includeLiveLinks,
      })
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const deleteUrls = (
  projectId,
  urlIds,
  allprojecturlsselected,
  includeLiveLinks
) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/delete-url/${projectId}`, {
        urlIds,
        allprojecturlsselected,
        includeLiveLinks,
      })
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};

export const updateColumn = (projectId, columnId, values) => {
  return new Promise(async (res, rej) => {
    anchorApi
      .post(`/project/update-column/resize-column/${projectId}/${columnId}`, {
        values,
      })
      .then((response) => {
        return res(response);
      })
      .catch((err) => {
        return rej(err);
      });
  });
};
