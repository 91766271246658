import React, { Suspense } from "react";
import "antd/dist/antd.less";
import "react-quill/dist/quill.snow.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//layout
import AppLayout from "./components/AppLayout";
import ProjectLayout from "./components/Projects/ProjectLayout";
import ColumnTemplatLayout from "./components/ColumnTemplateLayout";
import GlobalLayout from "./components/GlobalLayout";
import UserManagementLayout from "./components/UserManagementLayout";
import FilterLayoutLayout from "./components/Filters/FilterLayout";
import BlockedUrlsLayout from "./pages/BlockedUrls/BlockedUrlsLayout";
import EmailTemplatLayout from "./components/EmailTemplateLayout";

//Components
import AppSpin from "./components/AppSpin";
import CenterLayout from "./components/CenterLayout";

//providers
import { SettingProvider } from "./context/Setting";
import { UserProvider } from "./context/User";
import { NewProjectProvider } from "./context/NewProject";
import { ProjectFolderProvider } from "./context/projectFolders";
import { TeamMemberProvider } from "./context/teamMember";
import { ColumnTemplateProvider } from "./context/columnTemplates";
import { KeywordProvider } from "./context/keyword";
import { EmailTemplateProvider } from "./context/emailTemplates";
import { ProjectProvider } from "./context/projectContext";
import { UploadProjectUrlProvider } from "./context/uploadProjectUrls";
import { ProjectInboxProvider } from "./context/inboxComponent";
import { EmailScrapperProvider } from "./context/emailScrapper";
import { GlobalInboxProvider } from "./context/globalInbox";
import PreviewUrlContextProvider from "./context/previewUrl";
import UploadLog from "./pages/Proejct/UploadLog";

//pages
const Login = React.lazy(() => import("./pages/Auth/Login"));
const Signup = React.lazy(() => import("./pages/Auth/Signup"));
const ForgotPassword = React.lazy(() => import("./pages/Auth/ForgetPassword"));
const Dashboard = React.lazy(() => import("./pages/Dashboard/Index"));
const Projects = React.lazy(() => import("./pages/Proejct/Index"));
const AddNewProject = React.lazy(() => import("./pages/Proejct/AddNew"));
const ColumnTemplat = React.lazy(() => import("./pages/ColumnTemplates/Index"));
const UserList = React.lazy(() => import("./pages/GlobalSettings/Users/List"));
const AddNewUser = React.lazy(() =>
  import("./pages/GlobalSettings/Users/AddNew")
);
const AddNewColumnTemplate = React.lazy(() =>
  import("./pages/ColumnTemplates/AddNew")
);
const EditColumnTemplate = React.lazy(() =>
  import("./pages/ColumnTemplates/Edit")
);
const EditUser = React.lazy(() => import("./pages/GlobalSettings/Users/Edit"));
const EditDraftProject = React.lazy(() => import("./pages/Proejct/EditDraft"));
const Outreach = React.lazy(() => import("./pages/Proejct/Outreach"));
const Research = React.lazy(() => import("./pages/Proejct/Research"));
const Analytics = React.lazy(() => import("./pages/Proejct/Analytics"));
const EmailTemplate = React.lazy(() => import("./pages/EmailTemplate"));
const AddNewEmailTemplate = React.lazy(() =>
  import("./pages/EmailTemplate/AddNew")
);
const AddProjectUrls = React.lazy(() =>
  import("./pages/Proejct/AddUrls/Index")
);
const Management = React.lazy(() => import("./pages/Proejct/Management"));
const ProjectInbox = React.lazy(() => import("./pages/Proejct/Inbox"));
const GlobalInbox = React.lazy(() => import("./pages/inbox"));
const EditEmailTemplate = React.lazy(() =>
  import("./pages/EmailTemplate/Edit")
);
const GlobalBlockedUrls = React.lazy(() =>
  import("./pages/BlockedUrls/GlobalBlockedUrls")
);
const ProjectBlockedUrls = React.lazy(() =>
  import("./pages/BlockedUrls/ProjectBlockedUrls")
);
const Filters = React.lazy(() => import("./pages/filters/filters"));
const ArchivedProjects = React.lazy(() =>
  import("./pages/Proejct/ArchivedProject")
);
const AllNonBlockedUrls = React.lazy(() =>
  import("./pages/BlockedUrls/AllNonBlockedUrls")
);
const UrlPreview = React.lazy(() => import("./pages/UrlPreview"));
const ProjectReport = React.lazy(() => import("./pages/Proejct/Report"));

export default function App() {
  return (
    <Suspense
      fallback={
        <CenterLayout height="100vh">
          <AppSpin />
        </CenterLayout>
      }
    >
      <Router>
        <SettingProvider>
          <UserProvider>
            <GlobalInboxProvider>
              <ProjectProvider>
                <NewProjectProvider>
                  <UploadProjectUrlProvider>
                    <ProjectFolderProvider>
                      <ProjectInboxProvider>
                        <EmailScrapperProvider>
                          <TeamMemberProvider>
                            <ColumnTemplateProvider>
                              <PreviewUrlContextProvider>
                                <KeywordProvider>
                                  <EmailTemplateProvider>
                                    <Routes>
                                      <Route path="/" element={<AppLayout />}>
                                        <Route
                                          path="dashboard"
                                          element={<Dashboard />}
                                        />
                                        <Route
                                          path="projects"
                                          element={<Projects />}
                                        />
                                        <Route
                                          path="archived/projects"
                                          element={<ArchivedProjects />}
                                        />
                                        <Route
                                          path="inbox"
                                          element={<GlobalInbox />}
                                        />
                                        <Route
                                          exact
                                          path="/project/add-new-project"
                                          element={<AddNewProject />}
                                        />
                                        <Route
                                          path="project"
                                          element={<ProjectLayout />}
                                        >
                                          <Route
                                            exact
                                            path="research/:id"
                                            element={<Research />}
                                          />
                                          <Route
                                            exact
                                            path="outreach/:id"
                                            element={<Outreach />}
                                          />
                                          <Route
                                            exact
                                            path="analytics/:id"
                                            element={<Analytics />}
                                          />
                                          <Route
                                            exact
                                            path="add-urls/:id"
                                            element={<AddProjectUrls />}
                                          />
                                          <Route
                                            exact
                                            path="management/:id"
                                            element={<Management />}
                                          />
                                          <Route
                                            exact
                                            path="inbox/:id"
                                            element={<ProjectInbox />}
                                          />
                                          <Route
                                            exact
                                            path="report/:id"
                                            element={<ProjectReport />}
                                          />
                                          <Route
                                            exact
                                            path="upload-log/:id"
                                            element={<UploadLog />}
                                          />
                                        </Route>
                                        <Route
                                          exact
                                          path="edit-draft-project/:id"
                                          element={<EditDraftProject />}
                                        />
                                        <Route
                                          path="column-templates"
                                          element={<ColumnTemplatLayout />}
                                        >
                                          <Route
                                            path="list"
                                            element={<ColumnTemplat />}
                                          />
                                          <Route
                                            path="add-new"
                                            element={<AddNewColumnTemplate />}
                                          />
                                          <Route
                                            path="edit/:id"
                                            element={<EditColumnTemplate />}
                                          />
                                        </Route>
                                        <Route
                                          path="email-templates"
                                          element={<EmailTemplatLayout />}
                                        >
                                          <Route
                                            path="list"
                                            element={<EmailTemplate />}
                                          />
                                          <Route
                                            path="add-new"
                                            element={<AddNewEmailTemplate />}
                                          />
                                          <Route
                                            path="edit/:id"
                                            element={<EditEmailTemplate />}
                                          />
                                        </Route>

                                        <Route
                                          path="urls-db"
                                          element={<BlockedUrlsLayout />}
                                        >
                                          <Route
                                            path="all-non-blocked-urls"
                                            element={<AllNonBlockedUrls />}
                                          />
                                          <Route
                                            path="global-blocked"
                                            element={<GlobalBlockedUrls />}
                                          />
                                          <Route
                                            path="project-blocked"
                                            element={<ProjectBlockedUrls />}
                                          />
                                        </Route>

                                        <Route
                                          path="filters"
                                          element={<FilterLayoutLayout />}
                                        >
                                          <Route
                                            path="list"
                                            element={<Filters />}
                                          />
                                        </Route>

                                        <Route
                                          path="setting"
                                          element={<GlobalLayout />}
                                        >
                                          <Route
                                            path="team"
                                            element={<UserManagementLayout />}
                                          >
                                            <Route
                                              exact
                                              path="list"
                                              element={<UserList />}
                                            />
                                            <Route
                                              exact
                                              path="add-new"
                                              element={<AddNewUser />}
                                            />
                                            <Route
                                              path="edit/:id"
                                              element={<EditUser />}
                                            />
                                          </Route>
                                        </Route>
                                      </Route>

                                      <Route
                                        exact
                                        path="/url-preview"
                                        element={<UrlPreview />}
                                      />

                                      <Route
                                        exact
                                        path="/login"
                                        element={<Login />}
                                      />

                                      <Route
                                        exact
                                        path="/signup"
                                        element={<Signup />}
                                      />
                                      <Route
                                        exact
                                        path="/forget-password"
                                        element={<ForgotPassword />}
                                      />
                                      <Route
                                        path="*"
                                        element={
                                          <p>There's nothing here: 404!</p>
                                        }
                                      />
                                    </Routes>
                                  </EmailTemplateProvider>
                                </KeywordProvider>
                              </PreviewUrlContextProvider>
                            </ColumnTemplateProvider>
                          </TeamMemberProvider>
                        </EmailScrapperProvider>
                      </ProjectInboxProvider>
                    </ProjectFolderProvider>
                  </UploadProjectUrlProvider>
                </NewProjectProvider>
              </ProjectProvider>
            </GlobalInboxProvider>
          </UserProvider>
        </SettingProvider>
      </Router>
    </Suspense>
  );
}
