import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const SettingContext = React.createContext();

export const SettingProvider = (props) => {
  const location = useLocation();

  const [activenavlink, setactivenavlink] = useState("dashboard");
  const [pagetitle, setpagetitle] = useState("Dashboard");

  const [activeproject, setactiveproject] = useState(null);

  const [windowheight, setwindowheight] = useState(window.innerHeight);

  React.useEffect(() => {
    function handleResize() {
      setwindowheight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (!location.pathname.includes("/project/")) {
      setactiveproject(null);
    }

    if (location.pathname.includes("/project/")) {
      setactivenavlink("projects");
      return;
    }

    if (location.pathname.includes("/urls-db/")) {
      setactivenavlink("blockedUrls");
      return;
    }

    if (location.pathname === "/projects") {
      setactivenavlink("projects");
      setpagetitle("Projects");
      return;
    }
    if (
      location.pathname === "/project/add-new-project" ||
      location.pathname === "/project/add-new-project/"
    ) {
      setactivenavlink("projects");
      setpagetitle("Add New Project");
      return;
    }

    if (
      location.pathname === "/column-templates/list" ||
      location.pathname === "/column-templates/add-new"
    ) {
      setactivenavlink("columnTemplates");
      setpagetitle("Column Templates");
      return;
    }

    if (
      location.pathname === "/filters/list" ||
      location.pathname === "/filters/add-new"
    ) {
      setactivenavlink("filters");
      setpagetitle("Saved Filters");
      return;
    }

    if (location.pathname.includes("/column-templates/edit/")) {
      setactivenavlink("columnTemplates");
      setpagetitle("Update Column Template");
      return;
    }

    if (
      location.pathname === "/email-templates/list" ||
      location.pathname === "/email-templates/add-new"
    ) {
      setactivenavlink("emailTemplates");
      setpagetitle("Email Templates");
      return;
    }

    if (location.pathname.includes("/email-templates/edit/")) {
      setactivenavlink("emailTemplates");
      setpagetitle("Update Email Template");
      return;
    }

    if (location.pathname.includes("setting/team/edit/")) {
      setactivenavlink("teamManagement");
      setpagetitle("Update Team Member");
      return;
    }

    if (location.pathname.includes("edit-draft-project/")) {
      setactivenavlink("projects");
      setpagetitle("Draft Project");
      return;
    }

    if (
      location.pathname === "/setting/team/list" ||
      location.pathname === "/setting/team/add-new"
    ) {
      setactivenavlink("teamManagement");
      setpagetitle("Team Management");
      return;
    }

    if (location.pathname === "/inbox" || location.pathname === "/inbox/") {
      setactivenavlink("inboxGlobal");
      setpagetitle("Global Inbox");
      return;
    }
    if (location.pathname === "/dashboard" || location.pathname === "/dashboard/") {
      setactivenavlink("dashboard");
      setpagetitle("Dashboard");
      return;
    }

    // setactivenavlink("dashboard");
    // setpagetitle("Dashboard");
  }, [location.pathname]);

  return (
    <SettingContext.Provider
      value={{
        activenavlink,
        setactivenavlink,
        pagetitle,
        setpagetitle,
        activeproject,
        setactiveproject,
        windowheight,
        setwindowheight,
      }}
    >
      {props.children}
    </SettingContext.Provider>
  );
};

export default SettingContext;
