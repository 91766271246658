import React, { useContext } from "react";
import { Form, Input, Modal } from "antd";

//components
import PopupFooter from "../../PopupFooter";
import AppSpin from "../../AppSpin";
import ProjectFolderContext from "../../../context/projectFolders";

export default function AddNewProjectFolder({ setpopupopended }) {
  const { createProjectFolder, formloading } = useContext(ProjectFolderContext);

  const addFolder = (values) => {
    createProjectFolder(values, setpopupopended);
  };

  return (
    <Modal
      visible={true}
      title="Add New Folder"
      footer={null}
      onCancel={() => setpopupopended(false)}
    >
      <AppSpin spining={formloading}>
        <Form layout="vertical" onFinish={addFolder} requiredMark={false}>
          <Form.Item
            label="Folder Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Name field is required!",
              },
            ]}
          >
            <Input placeholder="Folder name" size="large" />
          </Form.Item>
          <PopupFooter action={setpopupopended} />
        </Form>
      </AppSpin>
    </Modal>
  );
}
