/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

//libraries
import { Checkbox } from "antd";
import { LinkOutlined } from "@ant-design/icons";


const KeywordContext = React.createContext();

export const KeywordProvider = (props) => {
  const [keywords, setkeywords] = useState(null);
  const [activekeyword, setactivekeyword] = useState(null);
  const [modelvis, setmodelvis] = useState(false);

  const columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      render: (text, record) => (
        <a href="#" onClick={() => setactivekeyword(record)}>
          <Checkbox checked={activekeyword?._id === record._id} size="small">
            {text}
          </Checkbox>
        </a>
      ),
    },
    {
      title: "Target URL",
      dataIndex: "targetUrl",
      key: "targetUrl",
      render: (text, record) => (
        <a href={text} target="_blank" rel="noreferrer">
          <LinkOutlined /> {text}
        </a>
      ),
    },
    {
      title: "Last Modified",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  return (
    <KeywordContext.Provider
      value={{
        keywords,
        setkeywords,
        activekeyword,
        setactivekeyword,
        columns,
        modelvis,
        setmodelvis,
      }}
    >
      {props.children}
    </KeywordContext.Provider>
  );
};

export default KeywordContext;
