import React from "react";

import { Empty, Form, Input, Modal } from "antd";

//components
import PopupFooter from "../PopupFooter";
import AppSpin from "../AppSpin";

export default function DeleteProject({
  deleteProject,
  project,
  setdeleteprojectpopup,
  formloading,
  setfolders,
  activeproject,
}) {
  return (
    <Modal
      title="Delete Project"
      visible={true}
      footer={null}
      onCancel={() => setdeleteprojectpopup(false)}
    >
      <AppSpin spining={formloading}>
        <Empty
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxiQ_pU9StomrYj25siy9Y1zrofAjWGqDFMQ&usqp=CAU"
          imageStyle={{
            height: 90,
          }}
          description={
            <>
              <h3>Are you sure?</h3>
              <span>
                Once you delete this project every records <br></br>will be
                deleted belongs to this project.
              </span>
            </>
          }
        />

        <Form
          layout="vertical"
          requiredMark={false}
          style={{ marginTop: 20 }}
          onFinish={() =>
            deleteProject(
              project,
              setdeleteprojectpopup,
              setfolders,
              activeproject
            )
          }
        >
          <Form.Item
            label={`Type - ${project.name}`}
            name="project"
            rules={[
              {
                required: true,
                message: "Please confirm this project name!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("project") === project.name) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Project name you typed do not match!")
                  );
                },
              }),
            ]}
          >
            <Input size="large" placeholder={`Type - ${project.name}`} />
          </Form.Item>
          <PopupFooter btnText="Delete" action={setdeleteprojectpopup} />
        </Form>
      </AppSpin>
    </Modal>
  );
}
