import React, { useState } from "react";

//urils
import { anchorApi } from "../utils/axios";
import HandleError from "../utils/errorHandle";
import HandleSuccess from "../utils/successHandle";

const ProjectFolderContext = React.createContext();

export const ProjectFolderProvider = (props) => {
  const [folders, setfolders] = useState(null);
  const [popupopened, setpopupopended] = useState(false);
  const [formloading, setformloading] = useState(false);

  const [loading, setloading] = useState(false);

  const getAllProjectsFolders = (param) => {
    setloading(true);
    anchorApi
      .get("/projectFolder?isArchived=" + (param === undefined ? false : true))
      .then((res) => {
        setfolders(res.data.map((item) => ({ ...item, opened: false })));
        setloading(false);
      })
      .catch((err) => {
        console.log("err", err);

        setloading(false);
        HandleError(err.response?.data);
      });
  };

  const createProjectFolder = (values, setpopupopended) => {
    setformloading(true);
    anchorApi
      .post("/projectFolder/create", values)
      .then((res) => {
        setfolders((prev) =>
          prev === null
            ? [{ ...res.data.folder, open: false }]
            : [{ ...res.data.folder, open: false }, ...prev]
        );
        setformloading(false);
        setpopupopended(false);
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const deleteFolder = (folderId, setdeletepopup) => {
    setformloading(true);
    anchorApi
      .post(`/projectFolder/delete/${folderId}`)
      .then((res) => {
        setfolders((prev) =>
          prev === null
            ? null
            : prev.filter((folder) => folder._id !== folderId)
        );
        setformloading(false);
        setpopupopended(false);
        HandleSuccess(res.data);
        setdeletepopup(false);
        window.location.href = "/projects";
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  return (
    <ProjectFolderContext.Provider
      value={{
        folders,
        setfolders,
        popupopened,
        setpopupopended,
        getAllProjectsFolders,
        createProjectFolder,
        formloading,
        setformloading,
        deleteFolder,
        loading,
      }}
    >
      {props.children}
    </ProjectFolderContext.Provider>
  );
};

export default ProjectFolderContext;
