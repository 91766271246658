import React, { useContext } from "react";
import { Link, Outlet } from "react-router-dom";

import { Space, Tabs } from "antd";

import {
  ExportOutlined,
  FileDoneOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { checkParentItemCondition } from "../utils/checkPermission";
import UserContext from "../context/User";

const { TabPane } = Tabs;

export default function GlobalLayout() {
  const { user } = useContext(UserContext);

  return (
    <>
      <Tabs defaultActiveKey={1} tabPosition={"top"} size="small">
        {user !== null && checkParentItemCondition("Users", user) && (
          <TabPane
            tab={
              <Link to="/setting/team/list">
                <Space size={6}>
                  <UserSwitchOutlined style={{ marginRight: 0 }} /> Team
                  Management
                </Space>
              </Link>
            }
            key={1}
          ></TabPane>
        )}
        <TabPane
          tab={
            <Link to="/dashboard">
              <Space size={6}>
                <ExportOutlined style={{ marginRight: 0 }} /> Projects Email
              </Space>
            </Link>
          }
          key={2}
        ></TabPane>
        <TabPane
          tab={
            <Link to="/dashboard">
              <Space size={6}>
                <FileDoneOutlined style={{ marginRight: 0 }} /> Others Settings
              </Space>
            </Link>
          }
          key={3}
        ></TabPane>
      </Tabs>
      <div style={{ width: "100%", padding: "20px" }}>
        <Outlet />
      </div>
    </>
  );
}
