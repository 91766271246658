import React, { useContext } from "react";

//libraries
import { Table } from "antd";

//contexts
import SettingContext from "../../context/Setting";

export default function ProojectTable({
  rowSelection,
  mergeColumns,
  projecturls,
  ResizableTitle,
}) {
  const { windowheight } = useContext(SettingContext);

  return (
    <Table
      tableLayout="fixed"
      rowSelection={{ ...rowSelection }}
      size="small"
      columns={mergeColumns}
      dataSource={projecturls}
      pagination={false}
      scroll={{
        y: windowheight - 310,
        x: 1600,
      }}
      components={{
        header: {
          cell: ResizableTitle,
        },
      }}
    />
  );
}
