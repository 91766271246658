import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//utils
import { anchorApi } from "../utils/axios";
import HandleError from "../utils/errorHandle";
import ErrorHandle from "../utils/errorHandle";
import HandleSuccess from "../utils/successHandle";

const TeamMemberContext = React.createContext();

export const TeamMemberProvider = (props) => {
  const location = useLocation();
  let navigate = useNavigate();

  const [current, setcurrent] = useState(0);
  const [modulepermissions, setmodulepermissions] = useState(null);
  const [formloading, setformloading] = useState(false);

  const [actioncompleted, setactioncompleted] = useState(false);

  const [teammembers, setteammembers] = useState([]);

  const [fieldvalues, setfieldvalues] = useState(null);
  const [permittedProjectFolders, setPermittedProjectFolders] = useState([]);
  const [permittedProjects, setPermittedProjects] = useState([]);

  const [editingteammember, seteditingteammember] = useState(null);

  const [loading, setloading] = useState(false);

  //set the action completed when somewhere navigated
  useEffect(() => {
    setactioncompleted(false);
    setcurrent(0);
    setfieldvalues(null);
    setPermittedProjectFolders([]);
    setPermittedProjects([]);
    setmodulepermissions(null);
  }, [location.pathname]);

  const getAllTeamMembers = () => {
    setloading(true);
    anchorApi
      .get("/team")
      .then((res) => {
        setteammembers(res.data);
        setloading(false);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setloading(false);
      });
  };

  const deleteteammember = (memberId, setdeletepopup) => {
    setformloading(true);
    anchorApi
      .post(`/team/delete/${memberId}`)
      .then((res) => {
        setteammembers((prev) =>
          prev.filter((memeber) => memeber._id !== memberId)
        );
        setformloading(false);
        HandleSuccess(res.data);
        setdeletepopup(false);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const getPermissionList = () => {
    if (editingteammember === null) {
      anchorApi
        .get("/team/getPermissionList")
        .then((res) => {
          setmodulepermissions(res.data);
        })
        .catch((err) => {
          ErrorHandle(err.response?.data);
        });
      return;
    }

    setmodulepermissions(editingteammember.permissions);
  };

  const submitAddUser = (form) => {
    if (current === 0) {
      setcurrent(1);
      return;
    }

    if (current === 1) {
      setcurrent(2);
      return;
    }

    if (editingteammember !== null) {
      updateTeamMember();
      return;
    }

    setformloading(true);
    anchorApi
      .post("/team/create", {
        ...fieldvalues,
        permissions: modulepermissions,
        projectFolders: permittedProjectFolders.map((item) => item._id),
        projects: permittedProjects.map((item) => item._id),
      })
      .then((res) => {
        setteammembers((prev) => [...prev, res.data.teamMemeber]);
        setformloading(false);
        setactioncompleted(true);
        form.resetFields();
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const getteammember = (memberId, form) => {
    anchorApi
      .get(`/team/detail/${memberId}`)
      .then((res) => {
        seteditingteammember(res.data);

        console.log(res.data);

        form.setFieldsValue({
          fullName: res.data?.fullName,
          email: res.data?.email,
          type: res.data?.type,
        });
        setPermittedProjectFolders(res.data?.projectFolders);
        setPermittedProjects(res.data?.projects);
      })
      .catch((err) => {
        navigate("/setting/team/list");
        HandleError(err.response?.data);
      });
  };

  const updateTeamMember = () => {
    setformloading(true);
    anchorApi
      .post(`/team/update/${editingteammember._id}`, {
        ...fieldvalues,
        permissions: modulepermissions,
        projectFolders: permittedProjectFolders.map((item) => item._id),
        projects: permittedProjects.map((item) => item._id),
      })
      .then((res) => {
        HandleSuccess(res.data);
        setformloading(false);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  return (
    <TeamMemberContext.Provider
      value={{
        current,
        setcurrent,
        modulepermissions,
        setmodulepermissions,
        formloading,
        setformloading,
        submitAddUser,
        getPermissionList,
        teammembers,
        setteammembers,
        fieldvalues,
        setfieldvalues,
        actioncompleted,
        setactioncompleted,
        getAllTeamMembers,
        loading,
        deleteteammember,
        permittedProjectFolders,
        setPermittedProjectFolders,
        permittedProjects,
        setPermittedProjects,
        editingteammember,
        seteditingteammember,
        getteammember,
      }}
    >
      {props.children}
    </TeamMemberContext.Provider>
  );
};

export default TeamMemberContext;
