import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { primaryColor } from "../utils/variables";

const AppSpin = ({ tip, size, children, spining }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: size ? size : 32,
        color: primaryColor,
      }}
      spin
    />
  );
  return (
    <Spin spinning={spining} indicator={antIcon} tip={tip}>
      {children}
    </Spin>
  );
};

export default AppSpin;
