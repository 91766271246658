import React, { useState } from "react";

import { Col, DatePicker, Form, Input, Modal, Row, Select, Space } from "antd";
import { updateUrl } from "../../../utils/projectData";
import HandleError from "../../../utils/errorHandle";
import HandleSuccess from "../../../utils/successHandle";
import PopupFooter from "../../PopupFooter";
import AppSpin from "../../AppSpin";
import moment from "moment";

const { Option } = Select;

export default function BulkUpdate({
  activeproject,
  actionloading,
  setactionloading,
  query,
  selctedurls,
  bulkppdatemoalvis,
  setbulkppdatemoalvis,
  projecturls,
  setprojecturls,
  setselctedurls,
  allprojecturlsselected,
  setallprojecturlsselected,
  source,
}) {
  const [dateValues, setDateValues] = useState({});

  const updateUrlCaller = (values) => {
    setactionloading(true);

    const parsedQuery = query !== null && query !== "" && JSON.parse(query);

    const includeLiveLinks =
      parsedQuery !== null && parsedQuery.includeLiveLinks !== undefined
        ? true
        : false;

    updateUrl(
      activeproject?._id,
      selctedurls,
      {
        ...values,
        ...dateValues,
        Cycle: values.Cycle ? JSON.parse(values.Cycle) : null,
      },
      allprojecturlsselected,
      includeLiveLinks,
      source
    )
      .then((res) => {
        setactionloading(false);
        HandleSuccess(res.data);

        // const updatingCell = Object.keys({ ...values, ...dateValues });

        // const mergedValues = { ...values, ...dateValues };

        // const newData = [...projecturls];
        // const testData = newData.map((item) => {
        //   if (selctedurls.includes(item._id)) {
        //     let oldObject = { ...item };

        //     updatingCell.map(
        //       (cell) =>
        //         (oldObject[cell] =
        //           mergedValues[cell] === "" || !mergedValues[cell]
        //             ? oldObject[cell]
        //             : mergedValues[cell])
        //     );
        //     console.log("old", oldObject);
        //     return oldObject;
        //   } else {
        //     return item;
        //   }
        // });
        // setbulkppdatemoalvis(false);
        // setprojecturls(testData);
        // setallprojecturlsselected(false);
        // setselctedurls([]);

        window.location.reload()

      })
      .catch((err) => {
        setactionloading(false);
        HandleError(err?.response?.data);
      });
  };

  const updateDateValue = (value, columnName) => {
    setDateValues((prev) => ({ ...prev, [columnName]: value }));
  };

  return (
    <Modal
      visible={bulkppdatemoalvis}
      onCancel={() => setbulkppdatemoalvis(false)}
      title="Udpate URLS"
      footer={null}
      width={800}
    >
      <AppSpin spining={actionloading}>
        <Form layout="vertical" onFinish={updateUrlCaller}>
          <Row gutter={16}>
            {activeproject?.columns.map(
              (column, i) =>
                column.name !== "Domain" &&
                column.name !== "URL" &&
                column.name !== "Date Added" &&
                column.name !== "Last Modified" &&
                column.name !== "Email" &&
                column.name !== "Status" &&
                (column.type === "text" || column.type === "link" ? (
                  <Col span={8}>
                    <Form.Item label={column.name} name={column.name} key={i}>
                      <Input placeholder={column.name} />
                    </Form.Item>
                  </Col>
                ) : column.type === "cycle" ? (
                  <Col span={8}>
                    <Form.Item label={column.name} key={i} name={column.name}>
                      <Select placeholder={column.name}>
                        {activeproject.cycleList?.reverse()?.map((item) => (
                          <Option value={JSON.stringify(item)}>
                              {moment(item?.start).format("MMM DD YYYY")} -{" "}
                              {moment(item?.end).format("MMM DD YYYY")}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : (
                  <>
                    {column.type === "date" ? (
                      <Col span={8}>
                        <Form.Item label={column.name} key={i} name={column.name}>
                          <DatePicker
                            style={{ width: "100%" }}
                            picker="date"
                            onChange={(date, dateString) =>
                              updateDateValue(dateString, column.name)
                            }
                            format="MM/DD/YYYY"
                          />
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col span={8}>
                        <Form.Item
                          label={column.name}
                          name={column.name}
                          key={i}
                        >
                          <Select
                            mode={column.multipleFieldsMap ? "tags" : ""}
                            placeholder={column.name}
                          >
                            {column.options?.map((item) => (
                              <Option value={item.label}>
                                <Space>
                                  {item.color && (
                                    <div
                                      style={{
                                        height: "12px",
                                        width: "12px",
                                        backgroundColor: item.color,
                                      }}
                                    ></div>
                                  )}

                                  {item.label}
                                </Space>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </>
                ))
            )}

            {activeproject?.columns.map(
              (column, i) =>
                column.name === "Status" && (
                  <Col span={8}>
                    <Form.Item label={column.name} name={column.name} key={i}>
                    <Select
                          placeholder={column.name}
                          onChange={(value) =>
                            updateUrlCaller({ [column.name]: value })
                          }
                        >
                          {column.options
                            ?.filter(
                              (oI) =>
                                oI.label === "Approved" ||
                                oI.label === "Rejected" ||
                                oI.label === "Review Price / Website"
                            )
                            ?.sort((a, b) => {
                              if (a.label < b.label) {
                                return -1;
                              }
                              if (a.label > b.label) {
                                return 1;
                              }
                              return 0;
                            })
                            ?.map((item) => (
                              <Option value={item.label}>
                                <Space>
                                  <div
                                    style={{
                                      height: "12px",
                                      width: "12px",
                                      backgroundColor: item.color,
                                    }}
                                  ></div>
                                  {item.label}
                                </Space>
                              </Option>
                            ))}
                            {column.options
                            ?.filter(
                              (oI) =>
                                oI.label !== "Approved" &&
                                oI.label !== "Rejected" &&
                                oI.label !== "Review Price / Website"
                            )
                            ?.sort((a, b) => {
                              if (a.label < b.label) {
                                return -1;
                              }
                              if (a.label > b.label) {
                                return 1;
                              }
                              return 0;
                            })
                            ?.map((item) => (
                              <Option value={item.label}>
                                <Space>
                                  <div
                                    style={{
                                      height: "12px",
                                      width: "12px",
                                      backgroundColor: item.color,
                                    }}
                                  ></div>
                                  {item.label}
                                </Space>
                              </Option>
                            ))}
                        </Select>
                    </Form.Item>
                  </Col>
                )
            )}
          </Row>
          <PopupFooter action={setbulkppdatemoalvis} />
        </Form>
      </AppSpin>
    </Modal>
  );
}
