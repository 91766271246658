import React, { useContext, useState } from "react";
import "./style.css";

import {
  Button,
  Card,
  Form,
  Input,
  List,
  Modal,
  Popover,
  Radio,
  Space,
  Typography,
  Select,
  Tooltip,
  Popconfirm,
  Switch,
  Badge,
  DatePicker,
} from "antd";
import {
  CalendarOutlined,
  CommentOutlined,
  ContactsOutlined,
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
  LinkOutlined,
  MailOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";

import SettingContext from "../../../context/Setting";
import HandleError from "../../../utils/errorHandle";
import HandleSuccess from "../../../utils/successHandle";
import { updateUrl, updateUrlFormApi } from "../../../utils/projectData";
import { useEffect } from "react";
import { anchorApi } from "../../../utils/axios";
import AppSpin from "../../AppSpin";
import { ValidDomain, ValidURL } from "../../../utils/helpers";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { oldestDate, primaryColor } from "../../../utils/variables";

const { Link, Paragraph } = Typography;
const { Option } = Select;

const isNumber = (value) => {
  return !isNaN(value);
};

const numberWithCommas = (value) => {
  if (value === null) return false;

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function TableColunItem({
  column,
  text,
  record,
  setprojecturls,
  isUploadLog
}) {
  let navigate = useNavigate();

  const { activeproject, setactiveproject } = useContext(SettingContext);

  const [searchParams] = useSearchParams();

  const [urlModal, setUrlModal] = useState(false);

  const [emailForm] = Form.useForm();

  const [options, setoptions] = useState(column?.options);

  const [currentvalue, setcurrentvalue] = useState(text);
  const [editing, setediting] = useState(false);

  const [vis, setvis] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const search = (value) => {
    if (value === "") {
      setoptions(column?.options);
      return;
    }

    setoptions(
      column?.options.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const addEmail = (values) => {
    anchorApi
      .post(
        `/project/url/add-new-email/${activeproject._id}/${record._id}`,
        values
      )
      .then((res) => {
        if (currentvalue?.length === 0) {
          setcurrentvalue([{ email: values["Email"], primary: true }]);
        } else {
          if (currentvalue === undefined || currentvalue === null) {
            setcurrentvalue([{ email: values["Email"], primary: true }]);
          } else {
            setcurrentvalue((prev) => [
              ...prev,
              { email: values["Email"], primary: false },
            ]);
          }
        }
        emailForm.resetFields();
        HandleSuccess(res.data);
      })
      .catch((err) => HandleError(err?.response?.data));
  };

  const changePrimaryEmail = (email) => {
    setFormLoading(true);
    anchorApi
      .post(
        `/project/url/switch-primary-email/${activeproject._id}/${record._id}`,
        { Email: email }
      )
      .then((res) => {
        setcurrentvalue(res.data);
        setFormLoading(false);
      })
      .catch((err) => {
        setFormLoading(false);
        HandleError(err?.response?.data);
      });
  };
  const deleteEmail = (email) => {
    setFormLoading(true);
    anchorApi
      .post(`/project/url/delete-email/${activeproject._id}/${record._id}`, {
        Email: email,
      })
      .then((res) => {
        setcurrentvalue(res.data);
        setFormLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setFormLoading(false);
        HandleError(err?.response?.data);
      });
  };

  const updateUrlCaller = (target, columnName) => {
    updateUrl(activeproject?._id, [record._id], { [columnName]: target.value })
      .then((res) => {
        setcurrentvalue(target.value);
        // HandleSuccess(res.data);
        if (column.type === "tags") {
          setactiveproject(res.data.project);
        }
        setediting(editing ? false : editing);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
      });
  };

  const updateTextValue = (e, columnName) => {
    e.preventDefault();
    updateUrl(activeproject?._id, [record._id], {
      [columnName]: currentvalue,
    })
      .then((res) => {
        setcurrentvalue(currentvalue);
        // HandleSuccess(res.data);
        setediting(editing ? false : editing);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
      });
  };

  const updateLinkValue = (e, columnName) => {
    e.preventDefault();

    let link = currentvalue;

    if (currentvalue !== "") {
      if (!ValidURL(currentvalue)) {
        if (ValidDomain(currentvalue)) {
          link = `https://${currentvalue}`;
        } else {
          HandleError({ type: "string", error: "Invalid URL" });
          setcurrentvalue("");
          link = "";
          setediting(false);
          return;
        }
      }
    }

    updateUrl(activeproject?._id, [record._id], {
      [columnName]: link,
    })
      .then((res) => {
        setcurrentvalue(link);
        // HandleSuccess(res.data);
        setediting(editing ? false : editing);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
      });
  };

  const updateUrlForm = (values) => {
    updateUrlFormApi(activeproject?._id, record._id, values)
      .then((res) => {
        setcurrentvalue(values.URL);
        HandleSuccess(res.data);

        //find the url in the projecturls and update it
        setprojecturls((prev) => {
          let newurls = prev.map((url) => {
            if (url._id === record._id) {
              console.log("Founded");
              return {
                ...url,
                Domain: res.data.url.data.Domain,
                uniqueField: res.data.url.uniqueField,
              };
            } else {
              return url;
            }
          });
          return newurls;
        });

        setUrlModal(false);
      })
      .catch((err) => {
        console.log("err", err);
        HandleError(err?.response?.data);
      });
  };

  const updateDateValue = (value, columnName) => {
    updateUrl(activeproject?._id, [record._id], {
      [columnName]: value,
    })
      .then((res) => {
        setcurrentvalue(value);
        // HandleSuccess(res.data);
        setediting(editing ? false : editing);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
      });
  };

  useEffect(() => {
    setcurrentvalue(text);
  }, [text]);

  const navigateTo = () => {
    const query = searchParams.get("query");
    const log = searchParams.get("log");
    if (query !== null) {

      if(isUploadLog){

        navigate(
          `/project/upload-log/${
            activeproject._id
          }?current_url=${record._id}&query=${query}&log=${log}`
        );

      }else{
        navigate(
          `/project/${record.status === "initial" ? "research" : record.status}/${
            activeproject._id
          }?current_url=${record._id}&query=${query}`
        );
      }

      
    } else {

      if(isUploadLog){

        navigate(
          `/project/upload-log/${
            activeproject._id
          }?current_url=${record._id}&log=${log}`
        );

      }else{
        navigate(
          `/project/${record.status === "initial" ? "research" : record.status}/${
            activeproject._id
          }?current_url=${record._id}`
        );
      }

      
    }
  };

  const getFormatterUrl = (urlString) => {
    if (urlString === "") {
      return "";
    }
    //check if the url is valid
    if (!ValidURL(urlString)) {
      if (ValidDomain(urlString)) {
        return `https://${urlString}`;
      } else {
        return "";
      }
    } else {
      return urlString;
    }
  };

  const getPrimaryEmail = () => {
    const primaryEmail = currentvalue.filter((item) => item.primary);

    return (
      <Badge count={currentvalue?.length} offset={[15, 5]}>
        <Button
          type="ghost"
          size="small"
          onClick={() => setvis(true)}
          icon={<MailOutlined />}
        >
          {primaryEmail[0]?.email}
        </Button>
      </Badge>
    );
  };

  if (column?.name === "Domain") {
    return (
      <Button
        type="text"
        size="small"
        onClick={() => navigateTo()}
        icon={<GlobalOutlined />}
      >
        {text} {record?.notesCount === undefined ? "" : <CommentOutlined />}
      </Button>
    );
  }

  if (column?.type === "link") {
    return (
      <div
        style={{
          height: 24,
          width: "100%",
          padding: 0,
          margin: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {currentvalue !== undefined &&
          currentvalue !== null &&
          currentvalue !== "" && (
            <Link href={getFormatterUrl(currentvalue)} target="_blank">
              <LinkOutlined style={{ marginRight: 4 }} />
            </Link>
          )}

        {column.name !== "URL" ? (
          <>
            {editing ? (
              <form onSubmit={(e) => updateLinkValue(e, column?.name)}>
                <Input
                  onBlur={(e) => updateLinkValue(e, column?.name)}
                  value={currentvalue}
                  onChange={(e) => setcurrentvalue(e.target.value)}
                  size="small"
                  defaultValue={currentvalue}
                  autoFocus={true}
                />
              </form>
            ) : (
              <div
                onClick={() => setediting(true)}
                style={{ height: 24, width: "100%", padding: 0, margin: 0 }}
              >
                {getFormatterUrl(currentvalue)}
              </div>
            )}
          </>
        ) : (
          <Space size="small">
            {" "}
            <Button
              onClick={() => setUrlModal(true)}
              icon={<EditOutlined />}
              size="small"
              type="link"
            ></Button>{" "}
            {currentvalue}
            {urlModal && (
              <Modal
                visible={urlModal}
                width={300}
                height={200}
                footer={null}
                closable={false}
              >
                <Form onFinish={updateUrlForm}>
                  <Form.Item
                    name="URL"
                    style={{ marginBottom: 10 }}
                    rules={[
                      {
                        required: true,
                        message: "Please input a URL",
                        type: "url",
                      },
                    ]}
                    initialValue={currentvalue}
                  >
                    <Input value={currentvalue} size="small" />
                  </Form.Item>
                  <Space>
                    <Button
                      onClick={() => setUrlModal(false)}
                      type="danger"
                      size="small"
                    >
                      Cancel
                    </Button>
                    <Button htmlType="submit" type="primary" size="small">
                      Update
                    </Button>
                  </Space>
                </Form>
              </Modal>
            )}
          </Space>
        )}
      </div>
    );
  }

  if (column?.type === "date") {
    if (column.name === "Date Added") {
      return (
        <span>
          <CalendarOutlined style={{ marginRight: 4 }} />
          {moment(record.created_at).format("MM/DD/YYYY")}
        </span>
      );
    } else if (column.name === "Last Modified") {
      return (
        <span>
          <CalendarOutlined style={{ marginRight: 4 }} />
          {moment(record.updated_at).format("MM/DD/YYYY")}
        </span>
      );
    } else {
      return (
        <DatePicker
          onChange={(date, dateString) =>
            updateDateValue(dateString, column.name)
          }
          format="MM/DD/YYYY"
          size="small"
          defaultValue={
            currentvalue === undefined ||
            currentvalue === "" ||
            currentvalue === null ||
            currentvalue === oldestDate
              ? ""
              : moment(currentvalue, "MM/DD/YYYY")
          }
        />
      );
    }
  }

  if (column?.type === "email") {
    return (
      <>
        {currentvalue === undefined ||
        currentvalue?.length === 0 ||
        currentvalue === null ? (
          <Button
            size="small"
            icon={<MailOutlined />}
            onClick={() => setvis(true)}
          >
            Add Email
          </Button>
        ) : (
          getPrimaryEmail()
        )}

        <AppSpin spining={formLoading}>
          <Modal
            title="Manage Emails"
            visible={vis}
            footer={null}
            onCancel={() => setvis(false)}
          >
            <Card title="Add New Email" size="small">
              <Form layout="vertical" onFinish={addEmail} form={emailForm}>
                <Form.Item
                  label="Email"
                  name={"Email"}
                  rules={[
                    {
                      required: true,
                      message: "A valid email is required",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="example@gmail.com" />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
              </Form>
            </Card>

            <List
              style={{ marginTop: 30 }}
              loading={false}
              size="small"
              bordered
              dataSource={
                currentvalue === undefined || currentvalue === null
                  ? []
                  : currentvalue
              }
              renderItem={(item) => (
                <List.Item>
                  <Tooltip placement="top" title={item.tooltip}>
                    <Paragraph>
                      <ContactsOutlined style={{ marginRight: "10px" }} />
                      {item.email}
                    </Paragraph>
                  </Tooltip>

                  <div>
                    <Space>
                      <Tooltip title="Primary contact">
                        <Switch
                          defaultChecked={item.primary}
                          disabled={item.primary}
                          onChange={() => changePrimaryEmail(item.email)}
                        />
                      </Tooltip>
                      <Popconfirm
                        title="Are you sure to delete this email?"
                        onConfirm={() => deleteEmail(item.email)}
                        okText="Yes"
                        cancelText="No"
                        disabled={item.primary}
                      >
                        <Button disabled={item.primary}>
                          <DeleteOutlined
                            style={{
                              color: "#eb2f96",
                              cursor: "pointer",
                            }}
                          />
                        </Button>
                      </Popconfirm>
                    </Space>
                  </div>
                </List.Item>
              )}
            />
          </Modal>
        </AppSpin>
      </>
    );
  }
  if (column?.type === "cycle") {
    return (
      <Popover
        onClick={(e) => e.preventDefault()}
        trigger="click"
        overlayInnerStyle={{ padding: 0 }}
        overlayStyle={{ padding: 0, width: 260 }}
        content={
          <Radio.Group
            onChange={({ target }) => {
              console.log(target.value);
              //find the cycle
              // const cycle = activeproject.cycleList.find(item => item.value === target.value)
              const cycle = JSON.parse(target.value);
              updateUrlCaller(
                {
                  value: cycle,
                },
                column?.name
              );
            }}
            defaultValue={JSON.stringify(currentvalue)}
          >
            {activeproject.cycleList?.reverse()?.map((item, index) => (
              <div key={index} style={{ marginBottom: 8 }}>
                <Space>
                  {item.color && (
                    <div
                      style={{
                        height: "12px",
                        width: "12px",
                        backgroundColor: primaryColor,
                      }}
                    ></div>
                  )}

                  <Radio value={JSON.stringify(item)}>
                    {moment(item?.start).format("MMM DD YYYY")} -{" "}
                    {moment(item?.end).format("MMM DD YYYY")}
                  </Radio>
                </Space>
              </div>
            ))}
          </Radio.Group>
        }
        placement="bottom"
      >
        {currentvalue ? (
          <Button
            size="small"
            icon={
              <EditOutlined
                style={{
                  color: options.filter(
                    (item) => item.label === currentvalue
                  )[0]?.color,
                }}
              />
            }
            style={{
              borderColor: options.filter(
                (item) => item.label === currentvalue
              )[0]?.color,
            }}
          >
            {moment(currentvalue?.start).format("MMM DD YYYY")} -{" "}
            {moment(currentvalue?.end).format("MMM DD YYYY")}
          </Button>
        ) : (
          <Button size="small" icon={<PlusCircleFilled />}>
            Add {column?.name}
          </Button>
        )}
      </Popover>
    );
  }

  if (column?.type === "select") {
    return (
      <Popover
        onClick={(e) => e.preventDefault()}
        trigger="click"
        title={
          <Input
            placeholder={`Search ${column?.name}`}
            bordered={false}
            onChange={(e) => search(e.target.value)}
          />
        }
        overlayInnerStyle={{ padding: 0 }}
        overlayStyle={{ padding: 0, width: 260 }}
        content={
          <Radio.Group
            onChange={({ target }) => updateUrlCaller(target, column?.name)}
            defaultValue={currentvalue}
          >
            {options
              ?.filter(
                (oI) => oI.label === "Approved" || oI.label === "Rejected" || oI.label === "Review Price / Website"
              )
              ?.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              })
              ?.map((item, index) => (
                <div key={index} style={{ marginBottom: 8 }}>
                  <Space>
                    {item.color && (
                      <div
                        style={{
                          height: "12px",
                          width: "12px",
                          backgroundColor: item.color,
                        }}
                      ></div>
                    )}

                    <Radio value={item.label}>{item.label}</Radio>
                  </Space>
                </div>
              ))}
            {options
              ?.filter(
                (oI) => oI.label !== "Approved" && oI.label !== "Rejected" && oI.label !== "Review Price / Website"
              )
              ?.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              })
              ?.map((item, index) => (
                <div key={index} style={{ marginBottom: 8 }}>
                  <Space>
                    {item.color && (
                      <div
                        style={{
                          height: "12px",
                          width: "12px",
                          backgroundColor: item.color,
                        }}
                      ></div>
                    )}

                    <Radio value={item.label}>{item.label}</Radio>
                  </Space>
                </div>
              ))}
          </Radio.Group>
        }
        placement="bottom"
      >
        {currentvalue !== undefined ? (
          <Button
            size="small"
            icon={
              <EditOutlined
                style={{
                  color: options.filter(
                    (item) => item.label === currentvalue
                  )[0]?.color,
                }}
              />
            }
            style={{
              borderColor: options.filter(
                (item) => item.label === currentvalue
              )[0]?.color,
            }}
          >
            {currentvalue}
          </Button>
        ) : (
          <Button size="small" icon={<PlusCircleFilled />}>
            Add {column?.name}
          </Button>
        )}
      </Popover>
    );
  }

  if (column?.type === "tags") {
    return (
      <Select
        size="small"
        mode="tags"
        style={{
          width: "100%",
        }}
        placeholder={column?.name}
        defaultValue={currentvalue}
        onChange={(value) => updateUrlCaller({ value: value }, column?.name)}
      >
        {column?.options?.map((v, i) => (
          <Option key={i} value={v.label}>
            <Space>
              {v.color && (
                <div
                  style={{
                    height: "12px",
                    width: "12px",
                    backgroundColor: v.color,
                  }}
                ></div>
              )}
              {v.label}
            </Space>
          </Option>
        ))}
      </Select>
    );
  }

  return editing ? (
    <form onSubmit={(e) => updateTextValue(e, column?.name)}>
      <Input
        onBlur={(e) => updateTextValue(e, column?.name)}
        value={currentvalue}
        onChange={(e) => setcurrentvalue(e.target.value)}
        size="small"
        defaultValue={currentvalue}
        autoFocus={true}
        style={{ textAlign: "center" }}
      />
    </form>
  ) : (
    <div
      onClick={() => setediting(true)}
      style={{
        height: 24,
        width: "100%",
        padding: 0,
        margin: 0,
        textAlign: "center",
      }}
    >
      {isNumber(currentvalue) ? numberWithCommas(currentvalue) : currentvalue}
    </div>
  );
}
