import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import { Space, Tabs, Typography } from "antd";

//variables
import { contentWidth } from "../../utils/variables";
import { DiffOutlined, UserSwitchOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

export default function FilterLayoutLayout() {
  const location = useLocation();

  const [activenavlink, setactivenavlink] = useState(null);

  useEffect(() => {
    if (location.pathname === "/filters/list") {
      setactivenavlink("1");
      return;
    }

    setactivenavlink("dashboard");
  }, [location.pathname]);

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: contentWidth,
        }}
      >
        <Space
          style={{
            marginBottom: 40,
            marginTop: 30,
            textAlign: "center",
            width: "100%",
          }}
          align="center"
          direction="vertical"
        >
          <Title
            level={2}
            style={{ textAlign: "center", color: "rgb(84, 81, 81)" }}
          >
            Manage Saved Filters
          </Title>
          <Text style={{ color: "rgb(107 102 102)" }}>
            These are the exsited saved Filters created by the team.<br></br>
            You can manage like giving specific permission to your team member
            about this column template module
          </Text>
        </Space>
        <Tabs
          tabPosition={"top"}
          size="small"
          defaultActiveKey={activenavlink}
          activeKey={activenavlink}
        >
          <TabPane
            tab={
              <Link to="/filters/list">
                <Space size={6}>
                  <DiffOutlined style={{ marginRight: 0 }} /> List
                </Space>
              </Link>
            }
            key={"1"}
          ></TabPane>
          <TabPane
            tab={
              <Link to="/filters/allowed-users">
                <Space size={6}>
                  <UserSwitchOutlined style={{ marginRight: 0 }} /> Allowed
                  Users
                </Space>
              </Link>
            }
            key={"3"}
          ></TabPane>
        </Tabs>

        <div style={{ marginTop: 30 }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
