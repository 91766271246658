import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Menu, Space } from "antd";

import {
  FolderOpenOutlined,
  EditOutlined,
  PlusCircleOutlined,
  ApiOutlined,
  EllipsisOutlined,
  EyeOutlined,
  UserSwitchOutlined,
  DeleteOutlined,
  MenuOutlined,
  ApartmentOutlined,
  CaretRightOutlined,
  CaretDownFilled,
} from "@ant-design/icons";

//contexts
import ProjectFolderContext from "../../context/projectFolders";

//layouts
import CenterLayout from "../CenterLayout";

//components
import Loading from "../Loading";
import DeleteFolder from "./Folder/Delete";
import SettingContext from "../../context/Setting";
import DeleteProject from "./Delete";
import NewProjectContext from "../../context/NewProject";

// eslint-disable-next-line no-unused-vars
const inActiveStyle = {
  color: "#d5d6d7",
  fontSize: "14px",
  fontWeight: "400",
  letterSpacing: "0.3px",
  paddingLeft: 30,
  cursor: "pointer",
  marginTop: 7,
};

const activeStyle = {
  color: "#d5d6d7",
  fontSize: "14px",
  fontWeight: "500",
  letterSpacing: "0.3px",
  padding: 4,
  background: "rgb(102 18 226 / 17%)",
  borderLeft: "2px solid #8b3dff",
  paddingLeft: 26,
  marginTop: 7,
  cursor: "pointer",
};

export default function ProjectList() {
  const {
    getAllProjectsFolders,
    folders,
    setfolders,
    deleteFolder,
    formloading,
  } = useContext(ProjectFolderContext);

  const { deleteProject, formloading: projectformloading } =
    useContext(NewProjectContext);

  const { activeproject } = useContext(SettingContext);

  const [deletefolderpopup, setdeletefolderpopup] = useState(false);

  const [deleteprojectpopup, setdeleteprojectpopup] = useState(false);

  useEffect(() => {
    getAllProjectsFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openFolder = (folderId) => {
    setfolders((prev) =>
      prev.map((folder) =>
        folder._id === folderId ? { ...folder, open: !folder.open } : folder
      )
    );
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "240px",
        backgroundColor: "#252424",
        margin: 0,
        padding: "10px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ padding: "0px 20px" }}>
        <h3
          style={{
            color: "#fff",
            fontSize: "17px",
            fontWeight: "600",
            margin: 0,
            padding: 0,
            marginBottom: 15,
          }}
        >
          Manage Projects
          <Link to="/projects">
            <Button size="small" type="primary">
              See All Projects
            </Button>
          </Link>
        </h3>

        <span
          style={{
            color: "#828588",
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          LIST
        </span>
        <div style={{ marginTop: 10 }}>
          {folders === null ? (
            <CenterLayout height={400}>
              <Loading />
            </CenterLayout>
          ) : (
            <ul style={{ padding: "0px", margin: "0px", listStyle: "none" }}>
              {folders.map((folder, i) => {
                return (
                  <li
                    key={folder._id}
                    style={{
                      color: "#d5d6d7",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginBottom: 7,
                      padding: 2,
                      paddingLeft: 15,
                      cursor: "pointer",
                    }}
                  >
                    <Space
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Space onClick={() => openFolder(folder._id)}>
                        {folder.open ||
                        folder._id === activeproject?.projectFolder ? (
                          <CaretDownFilled style={{ color: "#ddd" }} />
                        ) : (
                          <CaretRightOutlined style={{ color: "#ddd" }} />
                        )}
                        <FolderOpenOutlined />
                        {folder.name.substring(0, 13)}
                      </Space>
                      <Dropdown
                        placement="bottomLeft"
                        overlay={() => (
                          <Menu style={{ width: "200px" }}>
                            {folder.type !== "default" && (
                              <Menu.Item key="1" icon={<EditOutlined />}>
                                Change Details
                              </Menu.Item>
                            )}
                            {folder.type !== "default" && (
                              <Menu.Item key="4" icon={<PlusCircleOutlined />}>
                                <a
                                  href={`/project/add-new-project/?folder=${folder._id}`}
                                >
                                  New Project
                                </a>
                              </Menu.Item>
                            )}

                            <Menu.Item key="2" icon={<MenuOutlined />}>
                              All Projects
                            </Menu.Item>
                            <Menu.Item
                              key="15678"
                              icon={<UserSwitchOutlined />}
                            >
                              <a href="/">Allowed Users</a>
                            </Menu.Item>
                            {folder.type !== "default" && (
                              <Menu.Item
                                key="3"
                                icon={<DeleteOutlined />}
                                style={{ color: "#f5222d" }}
                                onClick={() => setdeletefolderpopup(folder)}
                              >
                                Delete
                              </Menu.Item>
                            )}
                          </Menu>
                        )}
                      >
                        <EllipsisOutlined />
                      </Dropdown>
                    </Space>

                    {activeproject !== null &&
                    folder._id === activeproject?.projectFolder ? (
                      <ul
                        style={{
                          padding: "0px",
                          margin: "0px",
                          listStyle: "none",
                          marginTop: 10,
                        }}
                      >
                        {folder?.projects?.map((project, i) => (
                          <li
                            className="testProject"
                            style={
                              activeproject._id === project._id
                                ? activeStyle
                                : inActiveStyle
                            }
                          >
                            <Space
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Space>
                                <ApartmentOutlined />
                                <a
                                  style={{ marginLeft: 5, color: "#d5d6d7" }}
                                  href={
                                    project.type !== "drafted"
                                      ? `/project/research/${project._id}`
                                      : `/edit-draft-project/${project._id}`
                                  }
                                >
                                  <Space>
                                    {project?.name.substring(0, 15)}
                                  </Space>
                                </a>
                              </Space>
                              {project.type !== "drafted" ? (
                                <Dropdown
                                  placement="bottomLeft"
                                  overlay={() => (
                                    <Menu style={{ width: "200px" }}>
                                      <Menu.Item
                                        key="23sds3"
                                        icon={<PlusCircleOutlined />}
                                      >
                                        <a
                                          href={`/project/add-urls/${project?._id}`}
                                        >
                                          Add URLS
                                        </a>
                                      </Menu.Item>
                                      <Menu.Item
                                        key="15678"
                                        icon={<UserSwitchOutlined />}
                                      >
                                        <a href="/">Allowed Users</a>
                                      </Menu.Item>
                                      <Menu.Item
                                        key="156576"
                                        icon={<EyeOutlined />}
                                      >
                                        <a
                                          href={`/project/research/${project?._id}?query={"includeLiveLinks":true}`}
                                        >
                                          Show Live Links
                                        </a>
                                      </Menu.Item>
                                      <Menu.Item
                                        key="15678"
                                        icon={<ApiOutlined />}
                                      >
                                        <a
                                          href={`/project/inbox/${project?._id}`}
                                        >
                                          Email Integration
                                        </a>
                                      </Menu.Item>
                                      <Menu.Item
                                        key="23sssds3"
                                        icon={<DeleteOutlined />}
                                        style={{ color: "#f5222d" }}
                                        onClick={() =>
                                          setdeleteprojectpopup(project)
                                        }
                                      >
                                        Delete
                                      </Menu.Item>
                                    </Menu>
                                  )}
                                >
                                  <EllipsisOutlined />
                                </Dropdown>
                              ) : (
                                <Dropdown
                                  placement="bottomLeft"
                                  overlay={() => (
                                    <Menu style={{ width: "200px" }}>
                                      <Menu.Item
                                        key="23sssds3"
                                        icon={<DeleteOutlined />}
                                        style={{ color: "#f5222d" }}
                                        onClick={() =>
                                          setdeleteprojectpopup(project)
                                        }
                                      >
                                        Delete
                                      </Menu.Item>
                                    </Menu>
                                  )}
                                >
                                  <EllipsisOutlined />
                                </Dropdown>
                              )}
                            </Space>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      folder.open && (
                        <ul
                          style={{
                            padding: "0px",
                            margin: "0px",
                            listStyle: "none",
                            marginTop: 10,
                          }}
                        >
                          {folder?.projects?.map((project, i) => (
                            <li className="testProject" style={inActiveStyle}>
                              <Space
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Space>
                                  <ApartmentOutlined />
                                  <a
                                    style={{ marginLeft: 5, color: "#d5d6d7" }}
                                    href={
                                      project.type !== "drafted"
                                        ? `/project/research/${project._id}`
                                        : `/edit-draft-project/${project._id}`
                                    }
                                  >
                                    <Space>
                                      {project?.name.substring(0, 15)}
                                    </Space>
                                  </a>
                                </Space>
                                {project.type !== "drafted" ? (
                                  <Dropdown
                                    placement="bottomLeft"
                                    overlay={() => (
                                      <Menu style={{ width: "200px" }}>
                                        <Menu.Item
                                          key="23sds3"
                                          icon={<PlusCircleOutlined />}
                                        >
                                          <a
                                            href={`/project/add-urls/${project?._id}`}
                                          >
                                            Add URLS
                                          </a>
                                        </Menu.Item>
                                        <Menu.Item
                                          key="15678"
                                          icon={<UserSwitchOutlined />}
                                        >
                                          <a href="/">Allowed Users</a>
                                        </Menu.Item>
                                        <Menu.Item
                                          key="156576"
                                          icon={<EyeOutlined />}
                                        >
                                          <a
                                            href={`/project/research/${project?._id}?query={"includeLiveLinks":true}`}
                                          >
                                            Show Live Links
                                          </a>
                                        </Menu.Item>
                                        <Menu.Item
                                          key="15678"
                                          icon={<ApiOutlined />}
                                        >
                                          <a
                                            href={`/project/inbox/${project?._id}`}
                                          >
                                            Email Integration
                                          </a>
                                        </Menu.Item>
                                        <Menu.Item
                                          key="23sssds3"
                                          icon={<DeleteOutlined />}
                                          style={{ color: "#f5222d" }}
                                          onClick={() =>
                                            setdeleteprojectpopup(project)
                                          }
                                        >
                                          Delete
                                        </Menu.Item>
                                      </Menu>
                                    )}
                                  >
                                    <EllipsisOutlined />
                                  </Dropdown>
                                ) : (
                                  <Dropdown
                                    placement="bottomLeft"
                                    overlay={() => (
                                      <Menu style={{ width: "200px" }}>
                                        <Menu.Item
                                          key="23sssds3"
                                          icon={<DeleteOutlined />}
                                          style={{ color: "#f5222d" }}
                                          onClick={() =>
                                            setdeleteprojectpopup(project)
                                          }
                                        >
                                          Delete
                                        </Menu.Item>
                                      </Menu>
                                    )}
                                  >
                                    <EllipsisOutlined />
                                  </Dropdown>
                                )}
                              </Space>
                            </li>
                          ))}
                        </ul>
                      )
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      {deletefolderpopup !== false && (
        <DeleteFolder
          folder={deletefolderpopup}
          deleteFolder={deleteFolder}
          setdeletefolderpopup={setdeletefolderpopup}
          formloading={formloading}
        />
      )}

      {deleteprojectpopup !== false && (
        <DeleteProject
          project={deleteprojectpopup}
          deleteProject={deleteProject}
          setdeleteprojectpopup={setdeleteprojectpopup}
          formloading={projectformloading}
          setfolders={setfolders}
          activeproject={activeproject}
        />
      )}
    </div>
  );
}
