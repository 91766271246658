import React, { useState } from "react";

//utils
import { anchorApi } from "../utils/axios";
import HandleError from "../utils/errorHandle";
import HandleSuccess from "../utils/successHandle";

const EmailScrapperContext = React.createContext();

export const EmailScrapperProvider = (props) => {
  const [scrapping, setscrapping] = useState([]);
  const [loading, setloading] = useState(false);

  const startScrapping = (projectId, type, selctedurls, setStartModal) => {
    setloading(true);
    anchorApi
      .post(`/scrapping/start-email-scrapping/${projectId}`, {
        type,
        selctedurls,
      })
      .then((res) => {
        console.log("res", res.data);
        HandleSuccess(res.data);
        setscrapping((prev) => [
          ...prev,
          {
            project: projectId,
            emailFindingInProgress: res.data?.emailFindingInProgress,
            emailFounded: 0,
          },
        ]);
        setStartModal(false);
        setloading(false);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
        setloading(false);
      });

    console.log(projectId);
  };

  const stopScrapping = (projectId) => {
    anchorApi
      .get(`/scrapping/stop-email-scrapping/${projectId}`)
      .then((res) => {
        setscrapping((prev) =>
          prev.filter((item) => item.project !== projectId)
        );
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
      });
  };

  const getStatus = (projectId) => {
    anchorApi
      .get(`/scrapping/get-email-scrapping-status/${projectId}`)
      .then((res) => {
        const result = scrapping.filter((s) => s.project === projectId);

        if (result.length === 0) {
          setscrapping((prev) => [
            ...prev,
            {
              project: projectId,
              emailFindingInProgress: res.data?.emailFindingInProgress,
              emailFounded: res.data?.emailFounded,
            },
          ]);
        } else {
          const index = scrapping.indexOf(result[0]);
          scrapping[index].emailFindingInProgress =
            res.data?.emailFindingInProgress;
          scrapping[index].emailFounded = res.data?.emailFounded;
          setscrapping([...scrapping]);
        }
      })
      .catch((err) => {
        console.log(err?.response?.data);
      });
  };

  return (
    <EmailScrapperContext.Provider
      value={{
        scrapping,
        setscrapping,
        startScrapping,
        stopScrapping,
        loading,
        setloading,
        getStatus,
      }}
    >
      {props.children}
    </EmailScrapperContext.Provider>
  );
};

export default EmailScrapperContext;
