import React, { useContext, useState } from "react";

import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
} from "antd";

import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

//components
import PopupFooter from "../../PopupFooter";

//variables
import { colorLabels } from "../../../utils/variables";

//utils
import ErrorHandle from "../../../utils/errorHandle";
import SettingContext from "../../../context/Setting";
import { anchorApi } from "../../../utils/axios";
import HandleError from "../../../utils/errorHandle";
import HandleSuccess from "../../../utils/successHandle";
import AppSpin from "../../AppSpin";

const { Option } = Select;

export default function NewColumnToProject({ setcolumns }) {
  const [form] = Form.useForm();
  const { activeproject, setactiveproject } = useContext(SettingContext);

  const [visible, setvisible] = useState(false);
  const [columntype, setcolumntype] = useState("text");
  const [showtooltip, setshowtooltip] = useState(false);

  const [actionloading, setactionloading] = useState(false);

  const addColumn = (values) => {
    //check the name unique status
    const checkNameStatus = activeproject?.columns.filter(
      (item) => item.name.toUpperCase() === values.name.toUpperCase()
    );

    if (checkNameStatus.length !== 0) {
      ErrorHandle({
        type: "string",
        error: "This column is already existed in the list",
      });
      return;
    }

    //check if the index is already taken
    const targetIndex = activeproject?.columns.length - 2;
    const checkTargetIndex = activeproject?.columns.filter(
      (item) => item.index === targetIndex
    );
    setactionloading(true);
    anchorApi
      .post(`/project/update-column/add-new/${activeproject?._id}`, {
        ...values,
        index: checkTargetIndex.length === 0 ? targetIndex : targetIndex + 1,
        default: false,
        type: values.category,
        included: true,
        mapAble: true,
        defaultInclude: true,
        dataIndex: values.name,
        editAble: true,
        multipleFieldsMap: values.category === "tags",
        hideonoutreach: false,
        hideonoresearch: false,
        width: 100,
      })
      .then((res) => {
        setactiveproject(res.data);

        setvisible(false);
        setshowtooltip(false);
        form.resetFields();
        HandleSuccess({ msg: "Column added successfully" });
        setactionloading(false);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
        setactionloading(false);
      });
  };
  return (
    <>
      <Button
        type="text"
        size="small"
        style={{ color: "#8b3dff" }}
        onClick={() => setvisible(true)}
      >
        <PlusCircleOutlined />
      </Button>

      <Modal
        visible={visible}
        title="Add New Column"
        footer={null}
        onCancel={() => setvisible(false)}
      >
        <AppSpin spining={actionloading}>
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={addColumn}
            form={form}
          >
            <Form.Item
              label="Label"
              rules={[
                {
                  required: true,
                  message: "Label field is required",
                },
              ]}
              name="name"
            >
              <Input size="large" placeholder="First name" />
            </Form.Item>
            <Form.Item
              label="Category"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Category field is required",
                },
              ]}
            >
              <Radio.Group onChange={(e) => setcolumntype(e.target.value)}>
                <Radio value="text">Text</Radio>
                <Radio value="link">Link</Radio>
                <Radio value="select">Select Box</Radio>
                <Radio value="tags">Tags</Radio>
                <Radio value="date">Date</Radio>
              </Radio.Group>
            </Form.Item>

            {(columntype === "select" || columntype === "tags") && (
              <Card
                title="Options"
                style={{ marginBottom: "15px" }}
                size="small"
              >
                <Form.List
                  name="options"
                  rules={[
                    {
                      validator: async (_, names) => {
                        if (!names || names.length < 1) {
                          return Promise.reject(
                            new Error("At least one option is required!")
                          );
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "label"]}
                            rules={[
                              {
                                required: true,
                                message: "Option lable is required.",
                              },
                            ]}
                          >
                            <Input placeholder="Option" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "color"]}
                            rules={[
                              {
                                required: true,
                                message: "Label color is required.",
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "160px" }}
                              placeholder="Label Color"
                            >
                              {colorLabels.map((color, i) => (
                                <Option value={color}>
                                  <Space>
                                    <div
                                      style={{
                                        height: "8px",
                                        width: "8px",
                                        backgroundColor: color,
                                      }}
                                    ></div>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {color}
                                    </span>
                                  </Space>
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            style={{ marginLeft: "5px" }}
                          />
                        </Space>
                      ))}

                      <Button
                        size="small"
                        type="text"
                        onClick={() => add()}
                        icon={<PlusCircleOutlined />}
                      >
                        Add Option
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </>
                  )}
                </Form.List>
              </Card>
            )}

            <Form.Item name="showtooltip" valuePropName="checked">
              <Checkbox onChange={() => setshowtooltip((prev) => !prev)}>
                Show tooltip when hovering over a column
              </Checkbox>
            </Form.Item>

            {showtooltip && (
              <Form.Item
                name="tooltip"
                rules={[
                  {
                    required: true,
                    message: "Tooltip field is requirde!",
                  },
                ]}
                label="Tooltip"
              >
                <Input
                  placeholder="Example: This is the number of links pointing to this domain"
                  size="large"
                />
              </Form.Item>
            )}
            <PopupFooter action={setvisible} btnText="Add Column" />
          </Form>
        </AppSpin>
      </Modal>
    </>
  );
}
