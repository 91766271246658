import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { primaryColor } from "../utils/variables";

const Loading = ({ tip, size, children }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: size ? size : 32,
        color: primaryColor,
      }}
      spin
    />
  );
  return <Spin indicator={antIcon} tip={tip} />;
};

export default Loading;
