/* eslint-disable react-hooks/exhaustive-deps */
import { PlayCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Modal, Space, Switch } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import EmailScrapperContext from "../../../context/emailScrapper";
import SettingContext from "../../../context/Setting";
import { anchorApi } from "../../../utils/axios";
import AppSpin from "../../AppSpin";
import CenterLayout from "../../CenterLayout";

export default function EmailScrapping({ selctedurls }) {
  const { activeproject } = useContext(SettingContext);

  const { scrapping, getStatus, startScrapping, stopScrapping, loading } =
    useContext(EmailScrapperContext);

  const [isThisProjectScrapping, setIsThisProjectScrapping] = useState(
    () => scrapping.filter((s) => s.project === activeproject?._id).length !== 0
  );

  const [stats, setStats] = useState(null);
  const [startModal, setStartModal] = useState(false);
  const [oldEmailCount, setOldEmailount] = useState(null);
  const [totalUrls, settotalUrls] = useState(null);

  useEffect(() => {
    const result = scrapping.filter((s) => s.project === activeproject?._id);

    setStats(result[0]);

    setIsThisProjectScrapping(result.length !== 0);
  }, [scrapping, activeproject]);

  useEffect(() => {
    getStatus(activeproject?._id);
  }, [activeproject]);

  useEffect(() => {
    anchorApi
      .get(`/scrapping/get-old-email-count/${activeproject._id}`)
      .then((res) => {
        setOldEmailount(res.data.emailFounded);
        settotalUrls(res.data.projectUrls);
      })
      .catch((err) => {
        console.log(err?.response?.data);
      });
  }, [activeproject]);

  const handleScraping = (value, type) => {
    if (value === true) {
      console.log("activeproject", activeproject);
      startScrapping(activeproject?._id, type, selctedurls, setStartModal);

      return;
    }
    stopScrapping(activeproject?._id);
  };

  return (
    <>
      <AppSpin spining={loading}>
        <Space>
          {isThisProjectScrapping ? (
            <Switch
              size="small"
              onChange={(value) => handleScraping(false)}
              checked={isThisProjectScrapping}
            />
          ) : (
            <Button size="small" onClick={() => setStartModal(true)}>
              Email Scrapping: <PlayCircleOutlined />
            </Button>
          )}
          {isThisProjectScrapping && (
            <Dropdown
              overlay={() => (
                <Card size="small" title="Statistics" style={{ width: 300 }}>
                  <Space
                    size={8}
                    style={{
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      Emails Founded: {stats?.emailFounded}
                    </span>
                  </Space>
                  <Space
                    size={8}
                    style={{
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      Emails Left To Be Founded: {stats?.emailFindingInProgress}
                    </span>
                  </Space>
                </Card>
              )}
              placement="top"
            >
              <Button type="text">
                Email Scrapping: <SyncOutlined spin />
              </Button>
            </Dropdown>
          )}
        </Space>
      </AppSpin>

      <Modal
        visible={startModal}
        onCancel={() => setStartModal(false)}
        title="Email Scrapping Settings"
        footer={null}
      >
        {oldEmailCount === null ? (
          <CenterLayout height={200}>
            <AppSpin />
          </CenterLayout>
        ) : (
          <AppSpin spining={loading}>
            <ul>
              <li>{selctedurls.length} Urls Selected</li>
              <li>{totalUrls - oldEmailCount} URLS without Email</li>
            </ul>
            <Space>
              <Button
                onClick={() => handleScraping(true, "selected")}
                type="primary"
                disabled={selctedurls.length === 0}
              >
                Find {selctedurls.length} Emails
              </Button>
              <Button onClick={() => handleScraping(true, "all")} type="danger">
                Find all empty Emails
              </Button>
            </Space>
          </AppSpin>
        )}
      </Modal>
    </>
  );
}
