import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

//libraies
import { Space, Tabs, Typography } from "antd";
import {
  DiffOutlined,
  PlusCircleOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";


//variables
import { contentWidth } from "../utils/variables";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

export default function EmailTemplatLayout() {
  const location = useLocation();

  const [activenavlink, setactivenavlink] = useState(null);

  useEffect(() => {
    if (location.pathname === "/email-templates/list") {
      setactivenavlink("1");
      return;
    }

    if (location.pathname === "/email-templates/add-new") {
      setactivenavlink("2");
      return;
    }
    setactivenavlink("dashboard");
  }, [location.pathname]);

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: contentWidth,
        }}
      >
        <Space
          style={{
            marginBottom: 40,
            marginTop: 30,
            textAlign: "center",
            width: "100%",
          }}
          align="center"
          direction="vertical"
        >
          <Title
            level={2}
            style={{ textAlign: "center", color: "rgb(84, 81, 81)" }}
          >
            Email Templates
          </Title>
          <Text style={{ color: "rgb(107 102 102)" }}>
            These are the exsited email template created by the team.<br></br>
            You can manage like delete, edit or add new template. Also you can
            manage specific permission to your team member about this email
            template module
          </Text>
        </Space>
        <Tabs
          tabPosition={"top"}
          size="small"
          defaultActiveKey={activenavlink}
          activeKey={activenavlink}
        >
          <TabPane
            tab={
              <Link to="/email-templates/list">
                <Space size={6}>
                  <DiffOutlined style={{ marginRight: 0 }} /> List
                </Space>
              </Link>
            }
            key={"1"}
          ></TabPane>
          <TabPane
            tab={
              <Link to="/email-templates/add-new">
                <Space size={6}>
                  <PlusCircleOutlined style={{ marginRight: 0 }} /> Add New
                  Template
                </Space>
              </Link>
            }
            key={"2"}
          ></TabPane>
          <TabPane
            tab={
              <Link to="/email-templates/allowed-users">
                <Space size={6}>
                  <UserSwitchOutlined style={{ marginRight: 0 }} /> Allowed
                  Users
                </Space>
              </Link>
            }
            key={"3"}
          ></TabPane>
        </Tabs>

        <div style={{ marginTop: 30 }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
