import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// libs
import { Button, Checkbox, Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

export default function UploadNoteFilter({ query, activeproject, log }) {
  let navigate = useNavigate();
  const [activefilter, setactivefilter] = useState(undefined);

  // submit filter
  const filterdata = (value) => {
    const parsedQuery = query !== null && query !== "" && JSON.parse(query);
    const stringQuery = JSON.stringify({
      ...parsedQuery,
      notStatus: value,
    });
    navigate(
      `/project/upload-log/${activeproject?._id}?query=${stringQuery}&log=${log}`
    );
  };

  useEffect(() => {
    const start = async () => {
      const urlData = await JSON.parse(query);
      if (urlData?.notStatus) {
        setactivefilter(urlData?.notStatus);
      } else {
        setactivefilter(undefined);
      }
    };
    start();
  }, [query]);

  // reset filter
  const reset = async () => {
    const parsedQuery = await JSON.parse(query);
    delete parsedQuery.notStatus;
    const queryString = JSON.stringify(parsedQuery);
    setactivefilter(undefined);
    navigate(`/project/upload-log/${activeproject?._id}?query=${queryString}`);
  };

  return (
    <Space>
      <Dropdown
        placement="bottomLeft"
        overlay={() => (
          <>
            <Menu style={{ width: "200px" }}>
              <Menu.Item key={"1"}>
                <Space>
                  <Checkbox
                    onChange={(value) => filterdata("yes")}
                    disabled={activefilter === "yes" ? true : false}
                    checked={activefilter === "yes" ? true : false}
                  >
                    Yes
                  </Checkbox>
                </Space>
              </Menu.Item>

              <Menu.Item key={"2"}>
                <Space>
                  <Checkbox
                    onChange={(value) => filterdata("no")}
                    disabled={activefilter === "no" ? true : false}
                    checked={activefilter === "no" ? true : false}
                  >
                    NO
                  </Checkbox>
                </Space>
              </Menu.Item>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <Button
                  type="danger"
                  size="small"
                  style={{
                    width: "100%",
                  }}
                  onClick={reset}
                >
                  Reset
                </Button>
              </div>
            </Menu>
          </>
        )}
      >
        {activefilter ? (
          <Button type="primary" size="small">
            Has Notes: {activefilter} <DownOutlined />
          </Button>
        ) : (
          <Button type="dashed" size="small">
            Has Notes: <DownOutlined />
          </Button>
        )}
      </Dropdown>
    </Space>
  );
}
