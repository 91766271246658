import { createContext, useState } from "react";

export const PreviewUrlContext = createContext();
const PreviewUrlContextProvider = ({ children }) => {
  const [urlLink, setUrlLink] = useState("null");
  const [selectedPreviewUrls, setSelectedPreviewUrls] = useState([]);
  const [isUrlLoading, setIsUrlLoading] = useState(false);
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [urlDetails, setUrlDetails] = useState({})

  return (
    <PreviewUrlContext.Provider
      value={{
        urlLink,
        setUrlLink,
        selectedPreviewUrls,
        setSelectedPreviewUrls,
        isUrlLoading,
        setIsUrlLoading,
        isIframeLoading,
        setIsIframeLoading, 
        urlDetails, setUrlDetails
      }}>
      {children}
    </PreviewUrlContext.Provider>
  );
};
export default PreviewUrlContextProvider;
