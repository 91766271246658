import React, { useEffect, useContext, useState } from "react";

import {
  Link,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import {
  Badge,
  Button,
  Dropdown,
  Input,
  Menu,
  Popover,
  Space,
  Tabs,
} from "antd";

import {
  SnippetsOutlined,
  ExportOutlined,
  FileDoneOutlined,
  BarChartOutlined,
  InboxOutlined,
  EditOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  ApiOutlined,
  DeleteOutlined,
  DownCircleOutlined,
  UpCircleOutlined,
  SendOutlined,
  DownOutlined,
  CheckSquareOutlined,
  CloseCircleFilled,
  SyncOutlined,
} from "@ant-design/icons";
import { Outlet } from "react-router-dom";

import NewProjectContext from "../../context/NewProject";
import SettingContext from "../../context/Setting";
import KeywordContext from "../../context/keyword";
import DeleteProject from "./Delete";
import ProjectFolderContext from "../../context/projectFolders";
import UpdateProjectBasicDetailsModal from "./UpdateBasic";
import ProjectContext from "../../context/projectContext";
import ArchiveProject from "./Archive";
import MoveToActiveProject from "../Project/MoveToActivate";
import { EmailCampaignList } from "../Project/Actions/Management/EmailCampaignList";
import { anchorApi } from "../../utils/axios";

const { TabPane } = Tabs;

export default function ProjectLayout() {
  const location = useLocation();
  const params = useParams();

  const [searchParams] = useSearchParams();

  const log = searchParams.get("log");

  const {
    getProjectDetail,
    deleteProject,
    moveToActive,
    formloading: projectformloading,
    archiveProject,
  } = useContext(NewProjectContext);
  const { setactiveproject, activeproject, setpagetitle } =
    useContext(SettingContext);

  const { setfolders } = useContext(ProjectFolderContext);
  const [showManageCampaigns, setShowManagecampaigns] = useState(false);

  const {
    updateModelVis,
    setUpdateModelVis,
    projecturlsCount,
    setprojecturlsCount,
  } = useContext(ProjectContext);

  const { setmodelvis: setkeywordmodelvis } = useContext(KeywordContext);

  const [deleteprojectpopup, setdeleteprojectpopup] = useState(false);
  const [archiveprojectpopup, setarchiveprojectpopup] = useState(false);
  const [movetoactivate, setmovetoactivate] = useState(false);
  const [uploadBatchList, setuploadBatchList] = useState(null);

  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    getProjectDetail(params.id, setactiveproject, setpagetitle);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (activeproject) {
      anchorApi
        .get(`/project/getUploadBatchList/${activeproject._id}`)
        .then((res) => {
          setuploadBatchList(res.data);
        })
        .catch((err) => {});
    }
  }, [activeproject]);

  useEffect(() => {
    if (activeproject) {
      if (location.pathname.includes("/project/upload-log/")) {
        if (!log) {
          window.location.href = `/project/research/${activeproject._id}`;
        }
      }
    }
  }, [log, activeproject]);

  useEffect(() => {
    setprojecturlsCount(null);

    if (location.pathname.includes("/project/research/")) {
      setActiveKey("1");
    }

    if (location.pathname.includes("/project/outreach/")) {
      setActiveKey("2");
    }

    if (location.pathname.includes("/project/management/")) {
      setActiveKey("3");
    }

    if (location.pathname.includes("/project/analytics/")) {
      setActiveKey("4");
    }

    if (location.pathname.includes("/project/inbox/")) {
      setActiveKey("5");
    }

    if (location.pathname.includes("/project/report/")) {
      setActiveKey("6");
    }

    if (location.pathname.includes("/project/add-urls/")) {
      setActiveKey("7");
    }
    if (location.pathname.includes("/project/upload-log/")) {
      setActiveKey("8");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, location.pathname]);

  const renderAddNewKeyword = () => {
    if (location.pathname.includes("/project/analytics/")) {
      return (
        <Button
          type="text"
          icon={<PlusCircleOutlined />}
          onClick={() => setkeywordmodelvis(true)}
        >
          New Keyword
        </Button>
      );
    }

    return null;
  };

  function kFormatter(number) {
    var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

    // what tier? (determines SI symbol)
    var tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }

  if (activeproject === null) {
    return null;
  }

  return (
    <>
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        tabPosition={"top"}
        size="small"
        tabBarExtraContent={
          <Space>
            {renderAddNewKeyword()}
            <Dropdown
              placement="bottomLeft"
              overlay={() => (
                <Menu style={{ width: "260px" }}>
                  <Menu.Item
                    key="0"
                    icon={<EditOutlined />}
                    onClick={() => setUpdateModelVis(true)}
                  >
                    Update Details
                  </Menu.Item>
                  <Menu.Item key="233" icon={<PlusCircleOutlined />}>
                    <Link
                      size={6}
                      to={`/project/add-urls/${activeproject?._id}`}
                    >
                      Add URls
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="1565" icon={<ExpandAltOutlined />}>
                    Export URLS
                  </Menu.Item> */}
                  <Menu.Item key="15678e" icon={<ApiOutlined />}>
                    <Link size={6} to={`/project/inbox/${activeproject?._id}`}>
                      Email Integration
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="15678"
                    icon={<SendOutlined />}
                    onClick={() => setShowManagecampaigns(true)}
                  >
                    Email Campaigns
                  </Menu.Item>

                  {showManageCampaigns && (
                    <EmailCampaignList
                      showManageCampaigns={showManageCampaigns}
                      setShowManagecampaigns={setShowManagecampaigns}
                    />
                  )}

                  {!activeproject?.isArchived && (
                    <Menu.Item
                      key="23sssds334"
                      icon={<DownCircleOutlined />}
                      style={{ color: "#faad14" }}
                      onClick={() => setarchiveprojectpopup(activeproject)}
                    >
                      Archive
                    </Menu.Item>
                  )}

                  {activeproject?.isArchived && (
                    <Menu.Item
                      key="23sssds334"
                      icon={<UpCircleOutlined />}
                      style={{ color: "teal" }}
                      onClick={() => setmovetoactivate(activeproject)}
                    >
                      Move To Active
                    </Menu.Item>
                  )}

                  <Menu.Item
                    key="23sssds3"
                    icon={<DeleteOutlined />}
                    style={{ color: "#f5222d" }}
                    onClick={() => setdeleteprojectpopup(activeproject)}
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              )}
            >
              <Button size="small" type="text" icon={<MoreOutlined />}>
                More
              </Button>
              {/* </Popconfirm> */}
            </Dropdown>

            {deleteprojectpopup !== false && (
              <DeleteProject
                project={deleteprojectpopup}
                deleteProject={deleteProject}
                setdeleteprojectpopup={setdeleteprojectpopup}
                formloading={projectformloading}
                setfolders={setfolders}
                activeproject={activeproject}
              />
            )}

            {archiveprojectpopup !== false && (
              <ArchiveProject
                project={archiveprojectpopup}
                deleteProject={archiveProject}
                setdeleteprojectpopup={setarchiveprojectpopup}
                formloading={projectformloading}
                setfolders={setfolders}
                activeproject={activeproject}
              />
            )}

            {movetoactivate !== false && (
              <MoveToActiveProject
                project={movetoactivate}
                deleteProject={moveToActive}
                setdeleteprojectpopup={setmovetoactivate}
                formloading={projectformloading}
                setfolders={setfolders}
                activeproject={activeproject}
              />
            )}

            {updateModelVis !== false && <UpdateProjectBasicDetailsModal />}
          </Space>
        }
      >
        <TabPane
          tab={
            <Link size={6} to={`/project/research/${activeproject?._id}`}>
              <SnippetsOutlined style={{ marginRight: 0 }} /> Research
              {activeKey === "1" && projecturlsCount !== null && (
                <Badge
                  count={kFormatter(projecturlsCount)}
                  overflowCount={projecturlsCount}
                  style={{ marginLeft: 10 }}
                  showZero={true}
                />
              )}
              <Popover
                onClick={(e) => e.preventDefault()}
                trigger="click"
                title={
                  <Input
                    placeholder={`Search Logs`}
                    bordered={false}
                    // onChange={(e) => search(e.target.value)}
                  />
                }
                overlayInnerStyle={{ padding: 0 }}
                overlayStyle={{ padding: 0, width: 360 }}
                content={
                  <>
                    {uploadBatchList !== null &&
                      uploadBatchList?.map((uploadBatch, index) => {
                        return (
                          <div
                            style={{ marginBottom: 12, cursor: "pointer" }}
                            key={index}
                          >
                            <Link
                              to={`/project/upload-log/${activeproject?._id}?log=${uploadBatch.name}`}
                            >
                              <Space>
                                <div>
                                  <CheckSquareOutlined />
                                </div>
                                {uploadBatch.name}
                              </Space>
                            </Link>
                          </div>
                        );
                      })}
                  </>
                }
              >
                {uploadBatchList === null ? (
                  <SyncOutlined
                    spin
                    style={{
                      marginLeft: 10,
                    }}
                  />
                ) : (
                  <DownOutlined
                    style={{
                      marginLeft: 10,
                    }}
                  />
                )}
              </Popover>
            </Link>
          }
          key={"1"}
        ></TabPane>
        <TabPane
          tab={
            <Link size={6} to={`/project/outreach/${activeproject?._id}`}>
              <ExportOutlined style={{ marginRight: 0 }} /> Outreach
              {activeKey === "2" && projecturlsCount !== null && (
                <Badge
                  count={kFormatter(projecturlsCount)}
                  overflowCount={kFormatter(projecturlsCount)}
                  style={{ marginLeft: 10 }}
                  showZero={true}
                />
              )}
            </Link>
          }
          key={"2"}
        ></TabPane>
        <TabPane
          tab={
            <Link size={6} to={`/project/management/${activeproject?._id}`}>
              <FileDoneOutlined style={{ marginRight: 0 }} /> Management
              {activeKey === "3" && projecturlsCount !== null && (
                <Badge
                  count={kFormatter(projecturlsCount)}
                  overflowCount={kFormatter(projecturlsCount)}
                  style={{ marginLeft: 10 }}
                  showZero={true}
                />
              )}
            </Link>
          }
          key={"3"}
        ></TabPane>
        <TabPane
          tab={
            <Link size={6} to={`/project/analytics/${activeproject?._id}`}>
              <BarChartOutlined style={{ marginRight: 0 }} /> Analytics
            </Link>
          }
          key={"4"}
        ></TabPane>
        <TabPane
          tab={
            <Link size={6} to={`/project/inbox/${activeproject?._id}`}>
              <InboxOutlined style={{ marginRight: 0 }} /> Inbox
            </Link>
          }
          key={"5"}
        ></TabPane>
        <TabPane
          tab={
            <Link size={6} to={`/project/report/${activeproject?._id}`}>
              <BarChartOutlined style={{ marginRight: 0 }} /> Report
            </Link>
          }
          key={"6"}
        ></TabPane>
        <TabPane
          tab={
            <Link size={6} to={`/project/add-urls/${activeproject?._id}`}>
              <PlusCircleOutlined style={{ marginRight: 0 }} /> Add URLS
            </Link>
          }
          key={"7"}
        ></TabPane>

        {location.pathname.includes("/project/upload-log/") && log && (
          <TabPane
            tab={
              <Link size={6} to={`/project/research/${activeproject?._id}`}>
                <CheckSquareOutlined style={{ marginRight: 0 }} /> {log}{" "}
                <CloseCircleFilled style={{ marginLeft: 7 }} />
              </Link>
            }
            key={"8"}
          ></TabPane>
        )}
      </Tabs>
      <div style={{ width: "100%", padding: "20px" }}>
        <Outlet />
      </div>
    </>
  );
}
