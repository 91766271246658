import { EyeInvisibleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useContext } from "react";
import SettingContext from "../../../context/Setting";
import { anchorApi } from "../../../utils/axios";
import ErrorHandle from "../../../utils/errorHandle";

export default function HideColumn({ setcolumns, whereToHide, columnId }) {
  const { activeproject, setactiveproject } = useContext(SettingContext);

  const chnageIncludeStatus = () => {
    anchorApi
      .post(`/project/editColumns/${activeproject?._id}/${columnId}`, {
        whereToHide,
        status: false,
      })
      .then((res) => {
        setcolumns((prev) =>
          prev.map((column) =>
            column._id === columnId
              ? { ...column, [whereToHide]: true }
              : column
          )
        );
        setactiveproject(res.data);
      })
      .catch((err) => {
        ErrorHandle({
          type: "string",
          error: "Sorry something went wrong, please try again later.",
        });
      });
  };

  return (
    <Button
      icon={<EyeInvisibleOutlined />}
      size="small"
      type="link"
      onClick={chnageIncludeStatus}
    ></Button>
  );
}
