import { message } from "antd";

const HandleError = (data) => {
  if (data.type === "string") {
    message.error(data.error);
  } else if (data.type === "array") {
    Object.keys(data.errors).map((item) => message.error(data.errors[item]));
  }
};

export default HandleError;
