import React, { useEffect, useState, useContext } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
// libraries
import { Space, Tabs } from "antd";
import { DiffOutlined, GlobalOutlined } from "@ant-design/icons";

// contexts
import SettingContext from "../../context/Setting";

const { TabPane } = Tabs;

export default function BlockedUrlsLayout() {
  const location = useLocation();

  const [activenavlink, setactivenavlink] = useState(null);

  const { setpagetitle } = useContext(SettingContext);

  useEffect(() => {
    if (location.pathname === "/urls-db/global-blocked") {
      setactivenavlink("1");
      setpagetitle("Globaly Blocked");
      return;
    }

    if (location.pathname === "/urls-db/project-blocked") {
      setactivenavlink("2");
      setpagetitle("Project Blocked");
      return;
    }

    if (location.pathname === "/urls-db/all-non-blocked-urls") {
      setactivenavlink("3");
      setpagetitle("All Non Blocked URLs");
      return;
    }

    // setactivenavlink("dashboard");
  }, [location.pathname]);

  return (
    <>
      <Tabs
        tabPosition={"top"}
        size="small"
        defaultActiveKey={activenavlink}
        activeKey={activenavlink}
      >
        <TabPane
          tab={
            <Link to="/urls-db/all-non-blocked-urls">
              <Space size={6}>
                <GlobalOutlined style={{ marginRight: 0 }} /> All Non Blocked
              </Space>
            </Link>
          }
          key={"3"}
        ></TabPane>

        <TabPane
          tab={
            <Link to="/urls-db/global-blocked">
              <Space size={6}>
                <DiffOutlined style={{ marginRight: 0 }} /> Globaly Blocked
              </Space>
            </Link>
          }
          key={"1"}
        ></TabPane>
        <TabPane
          tab={
            <Link to="/urls-db/project-blocked">
              <Space size={6}>
                <DiffOutlined style={{ marginRight: 0 }} /> Blocked From
                Projects
              </Space>
            </Link>
          }
          key={"2"}
        ></TabPane>
      </Tabs>

      <div style={{ width: "100%", padding: "20px" }}>
        <Outlet />
      </div>
    </>
  );
}
