import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//libraries
import moment from "moment";

//utils
import { anchorApi } from "../utils/axios";
import HandleError from "../utils/errorHandle";
import HandleSuccess from "../utils/successHandle";

const NewProjectContext = React.createContext();

export const NewProjectProvider = (props) => {
  const location = useLocation();
  let navigate = useNavigate();

  const [current, setcurrent] = useState(0);
  const [activetemplate, setactivetemplate] = useState(null);
  const [createdproject, setcreatedproject] = useState(null);
  const [formloading, setformloading] = useState(false);

  const [loading, setloading] = useState(false);

  const [latestdraftedproject, setlatestdraftedproject] = useState(null);

  //keywords
  const [columnTemplate, setColumnTemplate] = useState(null);
  const [keyWordsMethod, setKeyWordsMethod] = useState("1");

  const [uploadLoading, setUploading] = useState(false);
  const [showPreview, setshowPreview] = useState(false);
  const [keywordFileData, setKeywordFileData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  //project urls
  const [urlsfilepploading, seturlsfilepploading] = useState(false);
  const [urlsfiledata, seturlsfiledata] = useState(null);
  const [urlsfilename, seturlsfilename] = useState(null);
  const [totalurlsfiledata, settotalurlsfiledata] = useState(null);
  const [selectedcolumns, setselectedcolumns] = useState([]);
  const [filteredcolumns, setFilteredcolumns] = useState([]);
  const [socketresult, setsocketresult] = useState(0);

  const [socketloading, setsocketloading] = useState(false);

  const [validationresult, setvalidationresult] = useState(false);

  //projecturls

  //set the action completed when somewhere navigated
  useEffect(() => {
    setcurrent(0);
    setsocketresult(0);
    setactivetemplate(null);
    setcreatedproject(null);
    setlatestdraftedproject(null);
    setKeywordFileData(null);
    setSelectedOptions([]);
    setshowPreview(false);
    setKeyWordsMethod("1");
  }, [location.pathname]);

  const getProjectDetail = (projectId, setactiveproject, setpagetitle) => {
    setloading(true);
    anchorApi
      .get(`/project/detail/${projectId}`)
      .then((res) => {
        setactiveproject(res.data);
        setpagetitle(res.data?.name);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        HandleError(err.response?.data);
        navigate("/projects");
      });
  };

  const getLastestDraftedProject = () => {
    setloading(true);
    anchorApi
      .get("/project/getLatestDrafted")
      .then((res) => {
        setlatestdraftedproject(res.data);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const draftProject = (values, setfolders) => {
    if (createdproject !== null) {
      updateDraft(values, setfolders);
      return;
    }

    create(values, setfolders);
    return;
  };

  const create = (values, setfolders) => {
    setformloading(true);
    anchorApi
      .post("/project/create", values)
      .then((res) => {
        setcreatedproject(res.data?.project);
        setformloading(false);
        setcurrent(1);
        setfolders((prev) =>
          prev.map((folder) => {
            if (folder.type === "default") {
              return {
                ...folder,
                projects: [...folder.projects, res.data?.project],
              };
            }

            return folder;
          })
        );
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };
  const updateDraft = (values, setfolders) => {
    anchorApi
      .post(`/project/updateDraftBasic/${createdproject._id}`, values)
      .then((res) => {
        setcreatedproject(res.data?.project);
        setformloading(false);
        setcurrent(1);
        setfolders((prev) =>
          prev.map((folder) => {
            if (folder.type === "default") {
              return {
                ...folder,
                projects: folder.projects.map((project) =>
                  project._id === createdproject._id
                    ? res.data?.project
                    : project
                ),
              };
            }

            return folder;
          })
        );
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const attachColumnTemplate = (values, settemplates, setfolders) => {
    setformloading(true);
    anchorApi
      .post(`/project/attachColumnTemplate/${createdproject?._id}`, {
        ...values,
        templateId: activetemplate._id,
        columns: activetemplate.columns,
      })
      .then((res) => {
        if (values.saveastemplate) {
          settemplates((prev) => [...prev, res.data.template]);
        }

        if (createdproject?.type === "drafted") {
          setfolders((prev) =>
            prev.map((folder) => {
              if (folder.type === "default") {
                return {
                  ...folder,
                  projects: folder.projects.filter(
                    (project) => project._id !== createdproject._id
                  ),
                };
              }

              if (folder._id === res.data?.project?.projectFolder) {
                return {
                  ...folder,
                  projects: [...folder.projects, res.data?.project],
                };
              }

              return folder;
            })
          );
        }
        setcreatedproject(res.data?.project);

        setformloading(false);
        setcurrent(2);
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const deleteProject = (
    project,
    setdeletepopup,
    setfolders,
    activeproject
  ) => {
    setformloading(true);
    anchorApi
      .post(`/project/delete/${project._id}`)
      .then((res) => {
        setfolders((prev) =>
          prev.map((folder) => {
            if (folder._id === project.projectFolder) {
              const newProjects = folder.projects.filter(
                (projectPrev) => projectPrev._id !== project._id
              );

              return { ...folder, projects: newProjects };
            }

            return folder;
          })
        );
        setformloading(false);
        HandleSuccess(res.data);
        setdeletepopup(false);

        if (activeproject._id === project._id) {
          navigate("/projects");
        }
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const archiveProject = (
    project,
    setdeletepopup,
    setfolders,
    activeproject
  ) => {
    setformloading(true);
    anchorApi
      .post(`/project/archive/${project._id}`)
      .then((res) => {
        setfolders((prev) =>
          prev.map((folder) => {
            if (folder._id === project.projectFolder) {
              const newProjects = folder.projects.filter(
                (projectPrev) => projectPrev._id !== project._id
              );

              return { ...folder, projects: newProjects };
            }

            return folder;
          })
        );
        setformloading(false);
        HandleSuccess(res.data);
        setdeletepopup(false);

        if (activeproject._id === project._id) {
          navigate("/archived/projects");
        }
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const moveToActive = (
    project,
    setdeletepopup,
    setfolders,
    activeproject
  ) => {
    setformloading(true);
    anchorApi
      .post(`/project/activate/${project._id}`)
      .then((res) => {
        setfolders((prev) =>
          prev.map((folder) => {
            if (folder._id === project.projectFolder) {
              const newProjects = folder.projects.filter(
                (projectPrev) => projectPrev._id !== project._id
              );

              return { ...folder, projects: newProjects };
            }

            return folder;
          })
        );
        setformloading(false);
        HandleSuccess(res.data);
        setdeletepopup(false);

        if (activeproject._id === project._id) {
          navigate("/projects");
        }
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };


  const getDraftedProjectDetails = (projectId, form) => {
    setloading(true);
    anchorApi
      .get(`/project/getDraftDetail/${projectId}`)
      .then((res) => {
        setcreatedproject(res.data);
        console.log(res.data);
        form.setFieldsValue({
          name: res.data?.name,
          projectFolder: res.data?.draftedFolder,
          url: res.data?.url,
          email: res.data?.email,
          linkGoal: res.data?.linkGoal,
          startingDate: moment(res.data?.startingDate, "MM-DD-YYYY"),
          dueDate: moment(res.data?.dueDate, "MM-DD-YYYY"),
        });
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        HandleError(err.response?.data);
        navigate("/dashboard");
      });
  };

  const addBulkKeyword = (values) => {
    setformloading(true);
    anchorApi
      .post(`/project/addKeywordBulk/${createdproject?._id}`, {
        ...values,
        method: keyWordsMethod === "1" ? "form" : "file",
        selectedOptions: selectedOptions,
        file_name: keywordFileData?.file_name,
      })
      .then((res) => {
        setformloading(false);
        setcurrent(3);
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  return (
    <NewProjectContext.Provider
      value={{
        current,
        setcurrent,
        activetemplate,
        setactivetemplate,
        createdproject,
        setcreatedproject,
        formloading,
        setformloading,
        draftProject,
        loading,
        setloading,
        getLastestDraftedProject,
        latestdraftedproject,
        setlatestdraftedproject,
        attachColumnTemplate,
        deleteProject,
        columnTemplate,
        setColumnTemplate,
        keyWordsMethod,
        setKeyWordsMethod,
        uploadLoading,
        setUploading,
        showPreview,
        setshowPreview,
        keywordFileData,
        setKeywordFileData,
        selectedOptions,
        setSelectedOptions,
        getDraftedProjectDetails,
        addBulkKeyword,
        urlsfilepploading,
        seturlsfilepploading,
        urlsfiledata,
        seturlsfiledata,
        urlsfilename,
        seturlsfilename,
        totalurlsfiledata,
        settotalurlsfiledata,
        selectedcolumns,
        setselectedcolumns,
        filteredcolumns,
        setFilteredcolumns,
        socketresult,
        setsocketresult,
        socketloading,
        setsocketloading,
        validationresult,
        setvalidationresult,
        getProjectDetail,
        archiveProject,
        moveToActive
      }}
    >
      {props.children}
    </NewProjectContext.Provider>
  );
};

export default NewProjectContext;
