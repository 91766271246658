import React, { useContext, useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PushpinOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Empty,
  Form,
  Modal,
  Popconfirm,
  Space,
  Timeline,
} from "antd";
import Parser from "html-react-parser";
import moment from "moment";

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import AppSpin from "../AppSpin";
import CenterLayout from "../CenterLayout";
import Loading from "../Loading";
import UserContext from "../../context/User";

const HistoryNotes = ({
  actionloading,
  setAdingNote,
  addingNote,
  addNote,
  projectUsers,
  notes,
  changePinStatus,
  deleteNote,
  mentionUserIds,
  editNote,
  noteEditId,
  setNoteEditId,
}) => {
  const [editorStatsCustom, setEditorStateCustom] = useState(
    EditorState.createEmpty()
  );

  const [mentionUsers, setMentionUsers] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    mentionUserIds = projectUsers?.map((user) => user._id);
    if (projectUsers && user) {
      const users = projectUsers
        .filter((userF) => userF._id !== user._id)
        .map((user) => ({
          text: user.fullName,
          value: user.fullName,
          url: user._id,
        }));
      setMentionUsers(users);
    }
  }, [projectUsers, user]);

  const callAddNote = (values) => {
    const rawContentState = convertToRaw(editorStatsCustom.getCurrentContent());
    const markup = draftToHtml(
      convertToRaw(editorStatsCustom.getCurrentContent())
    );

    const entityMap = rawContentState.entityMap;
    const mentions = [];

    Object.keys(entityMap).forEach((key) => {
      const entity = entityMap[key];
      if (entity.type === "MENTION") {
        mentions.push(entity.data.url);
      }
    });

    addNote({
      ...values,
      note: markup,
      notifyTo: mentions,
      nofity: true,
    });

    //reset the editor
    setEditorStateCustom(EditorState.createEmpty());
  };

  const callEditNote = (values) => {
    const rawContentState = convertToRaw(editorStatsCustom.getCurrentContent());
    const markup = draftToHtml(
      convertToRaw(editorStatsCustom.getCurrentContent())
    );

    const entityMap = rawContentState.entityMap;
    const mentions = [];

    Object.keys(entityMap).forEach((key) => {
      const entity = entityMap[key];
      if (entity.type === "MENTION") {
        mentions.push(entity.data.url);
      }
    });
    editNote({
      ...values,
      noteId: noteEditId,
      note: markup,
      notifyTo: mentions,
    });

    //reset the editor
    setEditorStateCustom(EditorState.createEmpty());
  };

  return (
    <>
      <AppSpin spining={actionloading}>
        <Card
          size="small"
          title="History & Notes"
          style={{ marginTop: 20 }}
          bodyStyle={{
            maxHeight: 600,
            overflow: "hidden",
            overflowY: "auto",
          }}
          extra={
            <Button
              onClick={() => {
                setEditorStateCustom(EditorState.createEmpty());
                setAdingNote(true)}
              }
              size="small"
              icon={<PlusCircleOutlined />}
            >
              New Note
            </Button>
          }
        >
          {addingNote && (
            <Form onFinish={callAddNote} layout="vertical">
              <Form.Item
                label="type @ to tag a team member"
                rules={[
                  {
                    required: true,
                    message: "Note field is required",
                  },
                ]}
              >
                <Editor
                  mention={{
                    separator: " ",
                    trigger: "@",
                    suggestions: mentionUsers,
                  }}
                  editorState={editorStatsCustom}
                  onEditorStateChange={(editorState) => {
                    setEditorStateCustom(editorState);
                  }}
                />
              </Form.Item>

              <Form.Item name="pinNote" valuePropName="checked">
                <Checkbox>Pin this note to top.</Checkbox>
              </Form.Item>

              <Space>
                <Button type="danger" onClick={() => setAdingNote(false)}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={projectUsers === null}
                >
                  Save
                </Button>
              </Space>
            </Form>
          )}

          {notes === null ? (
            <CenterLayout>
              <Loading />
            </CenterLayout>
          ) : notes.length === 0 ? (
            addingNote ? null : (
              <CenterLayout>
                <Empty description="Seems, you dont have any notes yet!">
                  <Button onClick={() => setAdingNote(true)}>Add Note</Button>
                </Empty>
              </CenterLayout>
            )
          ) : (
            <Timeline style={{ marginTop: 15 }}>
              {notes
                .filter((nItem) => nItem.isPinned === true)
                .map((note, i) => (
                  <Timeline.Item
                    color="green"
                    style={{
                      backgroundColor:
                        note._id === noteEditId ? "" : "rgb(255 243 243)",
                      padding: 10,
                      marginBottom: 20,
                    }}
                  >
                    
                    <>
                      {note._id === noteEditId ? (
                        <Form onFinish={callEditNote} layout="vertical">
                          <Form.Item
                            label="type @ to tag a team member"
                            rules={[
                              {
                                required: true,
                                message: "Note field is required",
                              },
                            ]}
                          >
                            <Editor
                              mention={{
                                separator: " ",
                                trigger: "@",
                                suggestions: mentionUsers,
                              }}
                              editorState={editorStatsCustom}
                              onEditorStateChange={(editorState) => {
                                setEditorStateCustom(editorState);
                              }}
                            />
                          </Form.Item>

                          <Form.Item name="pinNote" valuePropName="checked" initialValue={note.isPinned}>
                            <Checkbox>Pin this note to top.</Checkbox>
                          </Form.Item>


                          <Form.Item name="nofity" valuePropName="checked">
                            <Checkbox>Notify again to team mates.</Checkbox>
                          </Form.Item>



                          <Space>
                            <Button
                              type="danger"
                              onClick={() => setNoteEditId("")}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              disabled={projectUsers === null}
                            >
                              Save
                            </Button>
                          </Space>
                        </Form>
                      ) : (
                        <>
                        {Parser(note.note)}{" "}
                          <Space style={{ marginTop: 10 }}>
                            <Badge
                              style={{
                                backgroundColor: "#faad14",
                                color: "#fff",
                              }}
                              count="Pinned"
                            />
                            <Badge
                              style={{
                                backgroundColor: "#f0f0f0",
                                color: "#595959",
                              }}
                              count={moment(note.created_at).fromNow()}
                            />
                            <Badge
                              style={{
                                backgroundColor: "rgb(139, 61, 255)",
                                color: "#fff",
                              }}
                              count={"Added By : " + note.user?.fullName}
                            />
                            <Popconfirm
                              title="Would you like to unpin this note?"
                              okText="Yes"
                              onConfirm={() => changePinStatus(false, note._id)}
                            >
                              <Button size="small">
                                <VerticalAlignBottomOutlined />
                              </Button>
                            </Popconfirm>
                          </Space>
                          <br />
                          <Space style={{ marginTop: 7 }}>
                            <Button
                              size="small"
                              onClick={() => {
                                setNoteEditId(note._id);
                                setEditorStateCustom(
                                  EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                      convertFromHTML(note.note)
                                    )
                                  )
                                );
                              }}
                            >
                              <EditOutlined />
                            </Button>

                            <Popconfirm
                              title="Are you sure ?"
                              onConfirm={() => deleteNote(note._id)}
                            >
                              <Button size="small" type="danger">
                                <DeleteOutlined />
                              </Button>
                            </Popconfirm>
                          </Space>
                        </>
                      )}
                    </>
                  </Timeline.Item>
                ))}

              {notes
                .filter((nItem) => nItem.isPinned !== true)
                .map((note, i) => (
                  <Timeline.Item color="green">
                  
                    <>
                      {note._id === noteEditId ? (
                        <Form onFinish={callEditNote} layout="vertical">
                          <Form.Item
                            label="type @ to tag a team member"
                            rules={[
                              {
                                required: true,
                                message: "Note field is required",
                              },
                            ]}
                          >
                            <Editor
                              mention={{
                                separator: " ",
                                trigger: "@",
                                suggestions: mentionUsers,
                              }}
                              editorState={editorStatsCustom}
                              onEditorStateChange={(editorState) => {
                                setEditorStateCustom(editorState);
                              }}
                            />
                          </Form.Item>

                          <Form.Item name="pinNote" valuePropName="checked" initialValue={note.isPinned}>
                            <Checkbox>Pin this note to top.</Checkbox>
                          </Form.Item>

                          <Form.Item name="nofity" valuePropName="checked">
                            <Checkbox>Notify again to team mates.</Checkbox>
                          </Form.Item>


                          <Space>
                            <Button
                              type="danger"
                              onClick={() => setNoteEditId("")}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              disabled={projectUsers === null}
                            >
                              Save
                            </Button>
                          </Space>
                        </Form>
                      ) : (
                        <>
                          {Parser(note.note)}{" "}
                          <Space style={{ marginTop: 10 }}>
                            <Badge
                              style={{
                                backgroundColor: "#f0f0f0",
                                color: "#595959",
                              }}
                              count={moment(note.created_at).fromNow()}
                            />
                            <Badge
                              style={{
                                backgroundColor: "rgb(139, 61, 255)",
                                color: "#fff",
                              }}
                              count={"Added By : " + note.user?.fullName}
                            />

                            <Popconfirm
                              title="Would you like to pin this note?"
                              okText="Yes"
                              onConfirm={() => changePinStatus(true, note._id)}
                            >
                              <Button size="small">
                                <PushpinOutlined />
                              </Button>
                            </Popconfirm>
                          </Space>
                          <br />
                          <Space style={{ marginTop: 7 }}>
                            <Button
                              size="small"
                              onClick={() => {
                                setNoteEditId(note._id);
                                setEditorStateCustom(
                                  EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                      convertFromHTML(note.note)
                                    )
                                  )
                                );
                              }}
                            >
                              <EditOutlined />
                            </Button>
                            <Popconfirm
                              title="Are you sure ?"
                              onConfirm={() => deleteNote(note._id)}
                            >
                              <Button size="small" type="danger">
                                <DeleteOutlined />
                              </Button>
                            </Popconfirm>
                          </Space>
                        </>
                      )}
                    </>
                  </Timeline.Item>
                ))}
            </Timeline>
          )}
        </Card>
      </AppSpin>
    </>
  );
};

export default HistoryNotes;
