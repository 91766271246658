import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useContext, useEffect } from "react";
import "./urldetails.css";

import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ProjectContext from "../../../context/projectContext";
import { anchorApi } from "../../../utils/axios";
import HandleError from "../../../utils/errorHandle";
import { updateUrl } from "../../../utils/projectData";
import HandleSuccess from "../../../utils/successHandle";
import AppSpin from "../../AppSpin";
import CenterLayout from "../../CenterLayout";
import HistoryNotes from "../../common/HistoryNotes";

const { Option } = Select;

export default function UrlDetails({
  activeproject,
  projecturls,
  setprojecturls,
  setThreadProjectUrl,
  calledFrom,
  isUploadLog,
}) {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currenUrlFromString = searchParams.get("current_url");

  const [form] = Form.useForm();

  const { currentprojecturl, setcurrentprojecturl } =
    useContext(ProjectContext);

  const [notes, setNotes] = useState(null);
  const [projectUsers, setProjectUsers] = useState(null);
  const [addingNote, setAdingNote] = useState(false);
  const [actionloading, setactionloading] = useState(false);
  const [value, setValue] = useState("");
  const [sendNotification, setSendNotification] = useState(true);

  const [dateValues, setDateValues] = useState({});
  const [noteEditId, setNoteEditId] = useState("");

  const updateDateValue = (value, columnName) => {
    setDateValues((prev) => ({ ...prev, [columnName]: value }));
    updateUrlCaller({ [columnName]: value });
  };

  useEffect(() => {
    if ((currenUrlFromString === currentprojecturl?._id) === false) {
      setcurrentprojecturl(null);
    }

    anchorApi
      .get(
        `/project-notes/get-all/${activeproject?._id}/${currentprojecturl?._id}`
      )
      .then((res) => {
        setNotes(res.data);
      })
      .catch((err) => {
        console.log(err);
        HandleError(err?.response?.data);
      });
  }, [
    activeproject,
    currentprojecturl,
    currenUrlFromString,
    setcurrentprojecturl,
  ]);

  useEffect(() => {
    anchorApi
      .get(`/project/get-project-users/${activeproject?._id}`)
      .then((res) => {
        setProjectUsers(res.data);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
      });
  }, [activeproject, currentprojecturl, currenUrlFromString]);

  const addNote = (values) => {
    setactionloading(true);
    anchorApi
      .post(
        `/project-notes/create/${activeproject?._id}/${currentprojecturl?._id}`,
        values
      )
      .then((res) => {
        setAdingNote(false);
        const newNotes = notes !== null ? [res.data, ...notes] : [res.data];

        if (values.pinNote === true) {
          setNotes(
            newNotes.map((nNote) =>
              nNote._id === res.data._id
                ? res.data
                : { ...nNote, isPinned: false }
            )
          );
        } else {
          setNotes(newNotes);
        }

        if (setprojecturls !== null && projecturls !== null) {
          setprojecturls([]);

          const newData = [...projecturls];
          const testData = newData.map((item) => {
            if ([currentprojecturl?._id].includes(item._id)) {
              let oldObject = { ...item };
              oldObject.notesCount =
                oldObject.notesCount === undefined
                  ? 1
                  : oldObject.notesCount + 1;
              return oldObject;
            } else {
              return item;
            }
          });
          setprojecturls(testData);
        }

        setactionloading(false);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
        setactionloading(false);
      });
  };
  const editNote = (values) => {
    setactionloading(true);
    anchorApi
      .post(
        `/project-notes/edit-note/${activeproject?._id}/${currentprojecturl?._id}`,
        values
      )
      .then((res) => {
        setNoteEditId("");
        setNotes((prev) =>
          prev.map((nNote) => (nNote._id === res.data._id ? res.data : nNote))
        );
        setactionloading(false);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
        setactionloading(false);
      });
  };

  const deleteNote = (noteId) => {
    setactionloading(true);
    anchorApi
      .post(
        `/project-notes/delete/${activeproject?._id}/${currentprojecturl?._id}`,
        { noteId: noteId }
      )
      .then((res) => {
        setNotes((prev) => prev.filter((note) => note._id !== noteId));

        if (setprojecturls !== null && projecturls !== null) {
          setprojecturls([]);

          const newData = [...projecturls];
          const testData = newData.map((item) => {
            if ([currentprojecturl?._id].includes(item._id)) {
              let oldObject = { ...item };
              oldObject.notesCount =
                oldObject.notesCount === 1
                  ? undefined
                  : oldObject.notesCount - 1;
              return oldObject;
            } else {
              return item;
            }
          });
          setprojecturls(testData);
        }

        setactionloading(false);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
        setactionloading(false);
      });
  };

  const changePinStatus = (status, noteId) => {
    setactionloading(true);
    anchorApi
      .post(
        `/project-notes/change-pin-status/${activeproject?._id}/${currentprojecturl?._id}`,
        { noteId: noteId, status: status }
      )
      .then((res) => {
        if (status === true) {
          setNotes(
            notes.map((nNote) =>
              nNote._id === res.data.urlNote._id
                ? res.data.urlNote
                : { ...nNote, isPinned: false }
            )
          );
        } else {
          setNotes(notes.map((nNote) => ({ ...nNote, isPinned: false })));
        }
        setactionloading(false);
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
        setactionloading(false);
      });
  };

  const updateUrlCaller = (values) => {
    updateUrl(activeproject?._id, [currentprojecturl?._id], {
      ...values,
      ...dateValues,
      ...(values.Cycle && {
        Cycle: values.Cycle ? JSON.parse(values.Cycle) : null,
      }),
    })
      .then((res) => {
        const mergedValues = {
          ...values,
          ...dateValues,
          Cycle: values.Cycle ? JSON.parse(values.Cycle) : null,
        };

        if (currentprojecturl !== null) {
          setcurrentprojecturl((prev) => ({
            ...prev,
            ...mergedValues,
            status: values.moveToManagement ? "management" : prev.status,
          }));
        }

        if (setThreadProjectUrl !== undefined) {
          setThreadProjectUrl((prev) => ({
            ...prev,
            ...mergedValues,
            status: values.moveToManagement ? "management" : prev.status,
          }));
        }

        if (setprojecturls !== null && projecturls !== null) {
          setprojecturls([]);

          const updatingCell = Object.keys(mergedValues);
          const newData = [...projecturls];
          const testData = newData.map((item) => {
            if ([currentprojecturl?._id].includes(item._id)) {
              let oldObject = { ...item };

              updatingCell.map(
                (cell) =>
                  (oldObject[cell] =
                    mergedValues[cell] === "" || !mergedValues[cell]
                      ? oldObject[cell]
                      : mergedValues[cell])
              );
              return oldObject;
            } else {
              return item;
            }
          });
          setprojecturls(testData);
        }
      })
      .catch((err) => {
        // setactionloading(false);
        HandleError(err?.response?.data);
      });
  };

  const closePopup = () => {
    const source = searchParams.get("source");
    setcurrentprojecturl(null);

    const query = searchParams.get("query");
    const log = searchParams.get("log");

    if (query !== null) {
      if (isUploadLog) {
        navigate(
          `/project/upload-log/${activeproject._id}?log=${log}&query=${query}${
            source ? `&${source}=true&source=urlDetails` : ""
          }`
        );
      } else {
        navigate(
          `/project/${
            currentprojecturl.status === "initial"
              ? "research"
              : currentprojecturl.status
          }/${activeproject._id}?query=${query}${
            source ? `&${source}=true&source=urlDetails` : ""
          }`
        );
      }
    } else {
      if (isUploadLog) {
        navigate(
          `/project/upload-log/${activeproject._id}?log=${log}${
            source ? `&${source}=true&source=urlDetails` : ""
          }`
        );
      } else {
        navigate(
          `/project/${
            currentprojecturl.status === "initial"
              ? "research"
              : currentprojecturl.status
          }/${activeproject._id}${
            source ? `?${source}=true&source=urlDetails` : ""
          }`
        );
      }
    }
  };

  const handleBack = () => {
    if (calledFrom === "inbox") {
      setcurrentprojecturl(null);
    } else {
      closePopup(true);
    }
  };

  const isNumber = (value) => {
    return !isNaN(value);
  };

  const numberWithCommas = (value) => {
    if (value === null) return false;
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Drawer
      contentWrapperStyle={{ color: "red" }}
      visible={currentprojecturl !== null}
      onClose={handleBack}
      closable={false}
      title={
        <Space>
          <Button
            icon={<ArrowLeftOutlined />}
            size="small"
            onClick={() =>
              calledFrom !== "inbox"
                ? closePopup(true)
                : setcurrentprojecturl(null)
            }
          ></Button>{" "}
          {currentprojecturl?.Domain}
        </Space>
      }
      footer={null}
      width={800}
      extra={
        <Button
          icon={<CloseCircleOutlined />}
          type="primary"
          onClick={() =>
            calledFrom !== "inbox"
              ? closePopup(true)
              : setcurrentprojecturl(null)
          }
          danger
        ></Button>
      }
    >
      <AppSpin spining={false}>
        <Card
          size="small"
          title={
            <Space>
              Update URL{" "}
              <a
                href={currentprojecturl && currentprojecturl["URL"]}
                target="_blank"
                rel="noreferrer"
              >
                <LinkOutlined /> {currentprojecturl && currentprojecturl["URL"]}
              </a>
            </Space>
          }
        >
          <Form layout="vertical" form={form} onFinish={updateUrlCaller}>
            <Row gutter={16}>
              {activeproject?.columns.map(
                (column, i) =>
                  column.name !== "Domain" &&
                  column.name !== "URL" &&
                  column.name !== "Date Added" &&
                  column.name !== "Last Modified" &&
                  column.name !== "Email" &&
                  column.name !== "Status" &&
                  (column.type === "text" || column.type === "link" ? (
                    <Col span={8}>
                      <Form.Item
                        label={
                          <Space size={2}>
                            {column.name}{" "}
                            {column.type === "link" ? (
                              currentprojecturl[column.name] &&
                              currentprojecturl[column.name] !== "" ? (
                                <Tooltip
                                  title={
                                    column.type === "link"
                                      ? currentprojecturl[column.name] &&
                                        currentprojecturl[column.name]
                                      : ""
                                  }
                                >
                                  <a
                                    href={currentprojecturl[column.name]}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <LinkOutlined />
                                  </a>
                                </Tooltip>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </Space>
                        }
                        name={column.name}
                        key={i}
                        initialValue={
                          currentprojecturl &&
                          isNumber(currentprojecturl[column.name])
                            ? numberWithCommas(currentprojecturl[column.name])
                            : currentprojecturl[column.name]
                        }
                      >
                        <Input
                          placeholder={column.name}
                          onBlur={({ target }) =>
                            updateUrlCaller({ [column.name]: target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  ) : column.type === "cycle" ? (
                    <Col span={8}>
                      <Form.Item
                        label={column.name}
                        key={i}
                        name={column.name}
                        initialValue={JSON.stringify(
                          currentprojecturl[column.name]
                        )}
                      >
                        <Select
                          placeholder={column.name}
                          onChange={(value) => {
                            updateUrlCaller({ [column.name]: value });
                          }}
                        >
                          {activeproject.cycleList?.reverse()?.map((item) => (
                            <Option value={JSON.stringify(item)}>
                              {moment(item?.start).format("MMM DD YYYY")} -{" "}
                              {moment(item?.end).format("MMM DD YYYY")}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={8}>
                      {column.type === "date" ? (
                        <Form.Item label={column.name} key={i}>
                          <DatePicker
                            style={{ width: "100%" }}
                            picker="date"
                            onChange={(date, dateString) =>
                              updateDateValue(dateString, column.name)
                            }
                            value={
                              currentprojecturl[column.name] === undefined ||
                              currentprojecturl[column.name] === "" ||
                              currentprojecturl[column.name] === null
                                ? ""
                                : moment(
                                    currentprojecturl[column.name],
                                    "MM/DD/YYYY"
                                  )
                            }
                            format="MM/DD/YYYY"
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          label={column.name}
                          name={column.name}
                          key={i}
                          initialValue={
                            currentprojecturl && currentprojecturl[column.name]
                          }
                        >
                          <Select
                            mode={column.multipleFieldsMap ? "tags" : ""}
                            placeholder={column.name}
                            onChange={(value) =>
                              updateUrlCaller({ [column.name]: value })
                            }
                          >
                            {column.options?.map((item) => (
                              <Option value={item.label}>
                                <Space>
                                  {item.color && (
                                    <div
                                      style={{
                                        height: "12px",
                                        width: "12px",
                                        backgroundColor: item.color,
                                      }}
                                    ></div>
                                  )}

                                  {item.label}
                                </Space>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Col>
                  ))
              )}

              {activeproject?.columns.map(
                (column, i) =>
                  column.name === "Status" && (
                    <Col span={8}>
                      <Form.Item
                        label={column.name}
                        name={column.name}
                        key={i}
                        initialValue={
                          currentprojecturl && currentprojecturl["Status"]
                        }
                      >
                        <Select
                          placeholder={column.name}
                          onChange={(value) =>
                            updateUrlCaller({ [column.name]: value })
                          }
                        >
                          {column.options
                            ?.filter(
                              (oI) =>
                                oI.label === "Approved" ||
                                oI.label === "Rejected" ||
                                oI.label === "Review Price / Website"
                            )
                            ?.sort((a, b) => {
                              if (a.label < b.label) {
                                return -1;
                              }
                              if (a.label > b.label) {
                                return 1;
                              }
                              return 0;
                            })
                            ?.map((item) => (
                              <Option value={item.label}>
                                <Space>
                                  <div
                                    style={{
                                      height: "12px",
                                      width: "12px",
                                      backgroundColor: item.color,
                                    }}
                                  ></div>
                                  {item.label}
                                </Space>
                              </Option>
                            ))}
                          {column.options
                            ?.filter(
                              (oI) =>
                                oI.label !== "Approved" &&
                                oI.label !== "Rejected" &&
                                oI.label !== "Review Price / Website"
                            )
                            ?.sort((a, b) => {
                              if (a.label < b.label) {
                                return -1;
                              }
                              if (a.label > b.label) {
                                return 1;
                              }
                              return 0;
                            })
                            ?.map((item) => (
                              <Option value={item.label}>
                                <Space>
                                  <div
                                    style={{
                                      height: "12px",
                                      width: "12px",
                                      backgroundColor: item.color,
                                    }}
                                  ></div>
                                  {item.label}
                                </Space>
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )
              )}
            </Row>

            {calledFrom === "inbox" &&
              currentprojecturl?.status !== "management" && (
                <Form.Item name="moveToManagement" valuePropName="checked">
                  <Checkbox
                    onChange={() => setSendNotification((prev) => !prev)}
                  >
                    Move this URL from{" "}
                    <strong>
                      {currentprojecturl?.status === "initial"
                        ? "Research"
                        : currentprojecturl?.status}
                    </strong>{" "}
                    to <strong>Management</strong>.
                  </Checkbox>
                </Form.Item>
              )}
            {/* <PopupFooter action={handleBack} marginTop={10} /> */}
          </Form>
        </Card>
        <HistoryNotes
          actionloading={actionloading}
          setAdingNote={setAdingNote}
          addingNote={addingNote}
          addNote={addNote}
          value={value}
          setValue={setValue}
          sendNotification={sendNotification}
          setSendNotification={setSendNotification}
          projectUsers={projectUsers}
          notes={notes}
          changePinStatus={changePinStatus}
          deleteNote={deleteNote}
          editNote={editNote}
          noteEditId={noteEditId}
          setNoteEditId={setNoteEditId}
        />

        {calledFrom !== "inbox" && (
          <Card size="small" title="Conversations" style={{ marginTop: 20 }}>
            <CenterLayout>
              <Empty description="Seems, you dont have any email conversations yet!">
                <Link to={`/project/inbox/${activeproject._id}`}>
                  <Button>Visit Project Inbox</Button>
                </Link>
              </Empty>
            </CenterLayout>
          </Card>
        )}
      </AppSpin>
    </Drawer>
  );
}
