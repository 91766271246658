import React from "react";

import { Button, Popconfirm, Tooltip } from "antd";
import { LeftCircleOutlined } from "@ant-design/icons";
import { backToResearch } from "../../../utils/projectData";
import HandleError from "../../../utils/errorHandle";
import HandleSuccess from "../../../utils/successHandle";

export default function BackToResearchURLS({
  activeproject,
  query,
  record,
  setactionloading,
  setprojecturls,
  fromCell,
  selctedurls,
  setselctedurls,
  setprojecturlsCount,
  allprojecturlsselected,
  setallprojecturlsselected,
  isUploadLog
}) {
  const moveToResearch = () => {
    const parsedQuery = query !== null && query !== "" && JSON.parse(query);

    const includeLiveLinks =
      parsedQuery !== null && parsedQuery.includeLiveLinks !== undefined
        ? true
        : false;

    setactionloading(true);
    backToResearch(
      activeproject?._id,
      fromCell ? [record?._id] : selctedurls,
      allprojecturlsselected,
      includeLiveLinks
    )
      .then((res) => {
        if(!isUploadLog){
          setprojecturls((prev) =>
          prev.filter((url) =>
            fromCell ? url._id !== record?._id : !selctedurls.includes(url._id)
          )
        );
        setprojecturlsCount((prev) =>
          fromCell
            ? prev - 1
            : allprojecturlsselected
            ? 0
            : prev - selctedurls.length
        );
        }
       
        setselctedurls([]);
        HandleSuccess(res.data);
        setactionloading(false);
        setallprojecturlsselected(false);
      })
      .catch((err) => {
        setactionloading(false);
        HandleError(err.response?.data);
      });
  };

  return (
    <Tooltip title="Move to Research" placement="bottom">
      {fromCell ? (
        <Button
          onClick={() => moveToResearch()}
          icon={<LeftCircleOutlined />}
          size="small"
          type="text"
          style={{ ...(fromCell && { color: "#8b3dff" }) }}
          disabled={!fromCell && selctedurls?.length === 0}
        ></Button>
      ) : (
        <Popconfirm
          onConfirm={() => moveToResearch()}
          title="Do you want to move to Research?"
          okText="Yes"
          disabled={!fromCell && selctedurls?.length === 0}
        >
          <Button
            icon={<LeftCircleOutlined />}
            size="small"
            type="text"
            style={{ ...(fromCell && { color: "#8b3dff" }) }}
            disabled={!fromCell && selctedurls?.length === 0}
          >Move to Research</Button>
        </Popconfirm>
      )}
    </Tooltip>
  );
}
