import React, { useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

//libraies
import { Button, Checkbox, Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

//context
import SettingContext from "../../../context/Setting";

//utils
import { anchorApi } from "../../../utils/axios";
import HandleError from "../../../utils/errorHandle";

export default function UploadLogSavedFilter({ log }) {
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const queryUrl = searchParams.get("query");

  const [filters, setFilters] = React.useState([]);

  const [activefilter, setactivefilter] = React.useState(undefined);

  const { activeproject } = useContext(SettingContext);

  useEffect(() => {
    anchorApi
      .get("/filter")
      .then((res) => {
        setFilters(res.data);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
      });
  }, []);

  const filterdata = async (value, filter) => {
    const parsedQuery = await JSON.parse(queryUrl);
    const includeLiveLinks = parsedQuery?.includeLiveLinks
      ? parsedQuery?.includeLiveLinks
      : undefined;

    const notStatus = parsedQuery?.notStatus
      ? parsedQuery?.notStatus
      : undefined;

    if (value) {
      const filters = { filters: filter.query };

      const query = JSON.stringify({
        ...filters,
        pagination: { page: 1, limit: 50 },
        savedFilter: filter._id,
        ...(includeLiveLinks !== undefined
          ? { includeLiveLinks: includeLiveLinks }
          : {}),
        ...(notStatus !== undefined ? { notStatus: notStatus } : {}),
      });

      navigate(
        `/project/upload-log/${activeproject?._id}?query=${query}&log=${log}`
      );
    } else {
      setactivefilter(undefined);
      navigate(`/project/upload-log/${activeproject?._id}`);
    }
  };

  useEffect(() => {
    const start = async () => {
      const urlData = await JSON.parse(queryUrl);

      if (urlData?.savedFilter) {
        const filter = filters.find(
          (filter) => filter._id === urlData?.savedFilter
        );
        setactivefilter(filter);
      } else {
        setactivefilter(undefined);
      }
    };
    start();
  }, [queryUrl, filters]);

  const reset = async () => {
    const parsedQuery = await JSON.parse(queryUrl);

    const includeLiveLinks = parsedQuery?.includeLiveLinks
      ? parsedQuery?.includeLiveLinks
      : undefined;

    const notStatus = parsedQuery?.notStatus
      ? parsedQuery?.notStatus
      : undefined;

    const queryString = JSON.stringify({
      ...(includeLiveLinks !== undefined
        ? { includeLiveLinks: includeLiveLinks }
        : {}),
      ...(notStatus !== undefined ? { notStatus: notStatus } : {}),
    });

    setactivefilter(undefined);
    navigate(
      `/project/upload-log/${activeproject?._id}?query=${queryString}&log=${log}`
    );
  };

  return (
    <Space>
      <Dropdown
        placement="bottomLeft"
        overlay={() => (
          <>
            <Menu style={{ width: "200px" }}>
              {filters.map((filter) => (
                <Menu.Item key={filter._id}>
                  <Space>
                    <Checkbox
                      onChange={(value) => filterdata(value, filter)}
                      disabled={activefilter?._id === filter._id ? true : false}
                      checked={activefilter?._id === filter._id ? true : false}
                    >
                      {filter.name}
                    </Checkbox>
                  </Space>
                </Menu.Item>
              ))}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <Button
                  type="danger"
                  size="small"
                  style={{
                    width: "100%",
                  }}
                  onClick={reset}
                >
                  Reset
                </Button>
              </div>
            </Menu>
          </>
        )}
      >
        {activefilter ? (
          <Button type="primary" size="small">
            Saved Filters: {activefilter?.name} <DownOutlined />
          </Button>
        ) : (
          <Button type="dashed" size="small">
            Saved Filters: <DownOutlined />
          </Button>
        )}
      </Dropdown>
    </Space>
  );
}
