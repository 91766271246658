import { Modal, Table } from "antd";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import SettingContext from "../../../../context/Setting";
import { anchorApi } from "../../../../utils/axios";

export const EmailCampaignList = ({
  showManageCampaigns,
  setShowManagecampaigns,
}) => {
  const { activeproject } = useContext(SettingContext);

  const [campaigns, setcampaigns] = useState(null);
  useEffect(() => {
    anchorApi
      .get(`/email-campaign/project-email-campaigns/${activeproject._id}`)
      .then((res) => {
        setcampaigns(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 100,
      fixed: "left",
      render: text => text !== undefined ? text: "Name Not Available"
    },
    {
      title: "Sent",
      dataIndex: "sent",
      key: "sent",
      width: 100,
    },
    {
      title: "Unsent",
      dataIndex: "unsent",
      key: "unsent",
      width: 100,
    },
    ,
    {
      title: "Recipients",

      dataIndex: "recipiants",
      key: "recipiants",
      render: (text) => text?.length,
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
  ];

  return (
    <Modal
      visible={showManageCampaigns}
      title="Manage Email Campaigns"
      footer={null}
      onCancel={() => setShowManagecampaigns(false)}
      width={700}
    >
      {campaigns && (
        <Table
          size="small"
          bordered={true}
          columns={columns}
          dataSource={campaigns}
          pagination={false}
        />
      )}
    </Modal>
  );
};
