import {
  SortDescendingOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import SettingContext from "../../../context/Setting";

export default function SortColumn({ column, query, source }) {
  const { activeproject } = useContext(SettingContext);
  const [current, setcurrent] = useState(null);
  let navigate = useNavigate()


  useEffect(() => {
    const set = async () => {
      if (query !== undefined) {
        const parsedQuery = await JSON.parse(query);

        if (parsedQuery !== null) {
          if (parsedQuery.sorts?.length !== 0) {
            const currentState = parsedQuery?.sorts?.filter(
              (sItem) => sItem.column === column.name
            );
            if (
              currentState !== null &&
              currentState !== undefined &&
              currentState?.length !== 0
            ) {
              setcurrent(currentState[0]);
            }
          }
        }
      }
    };

    set();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleSort = async () => { 
    const parsedQuery = query !== null && query !== "" && JSON.parse(query);
    const includeLiveLinks = parsedQuery?.includeLiveLinks
      ? parsedQuery?.includeLiveLinks
      : undefined;
    const page = parsedQuery?.pagination?.page
      ? parsedQuery?.pagination?.page
      : 1;
    const limit = parsedQuery?.pagination?.limit
      ? parsedQuery?.pagination?.limit
      : 50;
    const sorts = parsedQuery?.sorts ? [...parsedQuery?.sorts] : [];

    let newSortArray = [...sorts];

    //see if there is already that column in the sorting
    const targetCheck = newSortArray.filter(
      (sItem) => sItem.column === column.name
    );

    if (targetCheck.length === 0) {
      newSortArray.push({ column: column.name, type: column.name === "Date Added" ? "ASC": "DESC" });
    } else {
      newSortArray = newSortArray.map((sItem) =>
        sItem.column === column.name
          ? { ...sItem, type: sItem.type === "DESC" ? "ASC" : "DESC" }
          : sItem
      );
    }

    delete parsedQuery.pagination;

    const queryMNew = JSON.stringify({
      ...parsedQuery,
      sorts: newSortArray,
      ...(includeLiveLinks !== undefined
        ? { includeLiveLinks: includeLiveLinks }
        : {}),
    });
    navigate(
      `/project/${source ? source : "research"}/${
        activeproject?._id
      }?query=${queryMNew}`
    );
  };

  if(column.type === "email" || column.type === "select" ||  column.type === "select" === "tags"){
    return null
  }

  if (column.name === "Date Added") {
    if (current === null) {
      return (
        <Button
          icon={<SortDescendingOutlined />}
          size="small"
          type="link"
          onClick={handleSort}
          style={{color: "rgb(84, 81, 81)"}}
        ></Button>
      );
    } else {
      if (current?.type === "ASC") {
        return (
          <Button
            icon={<SortAscendingOutlined />}
            size="small"
            type="link"
            onClick={handleSort}
            style={{color: "rgb(84, 81, 81)"}}
          ></Button>
        );
      } else {
        return (
          <Button
            icon={<SortDescendingOutlined />}
            size="small"
            type="link"
            onClick={handleSort}
            style={{color: "rgb(84, 81, 81)"}}
          ></Button>
        );
      }
    }
  }

  if (current === null || current?.type === "ASC") {
    return (
      <Button
        icon={<SortAscendingOutlined />}
        size="small"
        type="link"
        onClick={handleSort}
        style={{color: "rgb(84, 81, 81)"}}
      ></Button>
    );
  }

  return (
    <Button
      icon={<SortDescendingOutlined />}
      size="small"
      type="link"
      onClick={handleSort}
      style={{color: "rgb(84, 81, 81)"}}
    ></Button>
  );
}
