import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//utils
import { defaultColumns, frontendUrl } from "../utils/variables";
import { anchorApi } from "../utils/axios";
import ErrorHandle from "../utils/errorHandle";
import HandleError from "../utils/errorHandle";
import HandleSuccess from "../utils/successHandle";

const ColumnTemplateContext = React.createContext();

export const ColumnTemplateProvider = (props) => {
  let navigate = useNavigate();
  const location = useLocation();

  const [current, setcurrent] = useState(0);

  const [templates, settemplates] = useState([]);
  const [popupopened, setpopupopended] = useState(false);
  const [formloading, setformloading] = useState(false);
  const [loading, setloading] = useState(false);

  const [actioncompleted, setactioncompleted] = useState(false);

  const [columns, setcolumns] = useState(defaultColumns);

  const [deletingtemplate, setdeletingtemplate] = useState(false);
  const [editingtemplate, seteditingtemplate] = useState(null);
  const [editingtemplatecolumns, seteditingtemplatecolumns] = useState([]);

  //set the action completed when somewhere navigated
  useEffect(() => {
    setactioncompleted(false);
  }, [location.pathname]);

  const addColumnTemplate = (values, form, sourceProjectId) => {
    setformloading(true);
    anchorApi
      .post("/columnTemplate/create", { ...values, columns })
      .then((res) => {
        settemplates((prev) => [...prev, res.data]);
        setformloading(false);
        form.resetFields();
        setcolumns(defaultColumns);

        if (sourceProjectId) {
          window.location.href = `${frontendUrl}edit-draft-project/${sourceProjectId}`;
        } else {
          setactioncompleted(true);
        }
      })
      .catch((err) => {
        setformloading(false);
        ErrorHandle(err.response?.data);
      });
  };

  const getAllColumnTemplates = () => {
    setloading(true);
    anchorApi
      .get("/columnTemplate")
      .then((res) => {
        settemplates(res.data);
        setloading(false);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setloading(false);
      });
  };

  const deletetemplate = (templateId) => {
    setformloading(true);
    anchorApi
      .post(`/columnTemplate/delete/${templateId}`)
      .then((res) => {
        settemplates((prev) =>
          prev.filter((template) => template._id !== templateId)
        );
        setformloading(false);
        setdeletingtemplate(false);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const gettemplate = (templateId, form) => {
    setloading(true);
    anchorApi
      .get(`/columnTemplate/detail/${templateId}`)
      .then((res) => {
        seteditingtemplate(res.data);
        form.setFieldsValue({ name: res.data?.name });
        seteditingtemplatecolumns(res.data?.columns);
        setloading(false);
      })
      .catch((err) => {
        navigate("/column-templates/list");
        HandleError(err.response?.data);
        setloading(false);
      });
  };

  const updateemplate = (values, templateId) => {
    setformloading(true);
    anchorApi
      .post(`/columnTemplate/update/${templateId}`, {
        ...values,
        columns: editingtemplatecolumns.filter((item) => !item.default),
      })
      .then((res) => {
        HandleSuccess(res.data);
        setformloading(false);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  return (
    <ColumnTemplateContext.Provider
      value={{
        current,
        setcurrent,
        templates,
        settemplates,
        popupopened,
        setpopupopended,
        formloading,
        setformloading,
        loading,
        setloading,
        columns,
        setcolumns,
        addColumnTemplate,
        actioncompleted,
        setactioncompleted,
        getAllColumnTemplates,
        deletetemplate,
        deletingtemplate,
        setdeletingtemplate,
        gettemplate,
        editingtemplate,
        seteditingtemplate,
        editingtemplatecolumns,
        seteditingtemplatecolumns,
        updateemplate,
      }}
    >
      {props.children}
    </ColumnTemplateContext.Provider>
  );
};

export default ColumnTemplateContext;
