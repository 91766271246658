import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  BellOutlined,
  CheckOutlined,
  ReloadOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Badge, Button, Drawer, List, Tabs } from "antd";
import UserContext from "../../context/User";
import { anchorApi } from "../../utils/axios";
import HandleSuccess from "../../utils/successHandle";
import HandleError from "../../utils/errorHandle";

export const GlobalNotification = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const notificationStatus = searchParams.get("notification");

  const { user } = useContext(UserContext);

  const [notifications, setNotifications] = useState(null);

  const [status, setStatus] = useState("unread");

  const closePopup = () => {
    const source = searchParams.get("source");

    if (source === "urlDetails") {
      //find all the url params and remove the notification param
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("notification");
      urlParams.delete("source");
      const newRelativePathQuery =
        window.location.pathname + "?" + urlParams.toString();
      navigate(newRelativePathQuery);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (user !== null) {
      anchorApi
        .get(`/notification/get-all/${status}`)
        .then(({ data }) => {
          setNotifications(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user, notificationStatus, status]);

  const refreshNotifications = () => {
    anchorApi
      .get(`/notification/get-all/${status}`)
      .then(({ data }) => {
        setNotifications(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const markAs = (id, status) => {
    anchorApi
      .post(`/notification/mark-as/${id}`, {
        status,
      })
      .then(({ data }) => {
        refreshNotifications();
        HandleSuccess(data);
      })
      .catch((err) => {
        console.log(err);
        HandleError(err?.response?.data);
      });
  };

  const goToNotification = () => {
    //find all the url params and add the notification param
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("notification", "true");
    const newRelativePathQuery =
      window.location.pathname + "?" + urlParams.toString();
    return newRelativePathQuery;
  };

  const handleNotification = (item) => {
    if (!item?.isRead) {
      anchorApi
        .post(`/notification/change-status/${item._id}`, {
          status: !item.isRead,
        })
        .then(({ data }) => {
          setNotifications((prev) => {
            const index = prev.findIndex((i) => i._id === item._id);
            prev[index].isRead = !prev[index].isRead;
            return [...prev];
          });
          HandleSuccess(data);
        })
        .catch((err) => {
          console.log(err);
          HandleError(err?.response?.data);
        });
    }

    navigate(
      `/project/${
        item.projectUrl?.status === "initial"
          ? "research"
          : item.projectUrl?.status
      }/${item.project}?current_url=${item.projectUrl?._id}&source=notification`
    );
  };
  const handleIsReadStatus = (item) => {
    anchorApi
      .post(`/notification/change-status/${item._id}`, {
        status: !item.isRead,
      })
      .then(({ data }) => {
        setNotifications((prev) => {
          const index = prev.findIndex((i) => i._id === item._id);
          prev[index].isRead = !prev[index].isRead;
          return [...prev];
        });
        HandleSuccess(data);
      })
      .catch((err) => {
        console.log(err);
        HandleError(err?.response?.data);
      });
  };

  return (
    notifications && (
      <>
        <Badge count={notifications.filter((item) => !item.isArchived).length}>
          <Link to={goToNotification()}>
            <Button>
              <BellOutlined />
            </Button>
          </Link>
        </Badge>
        {notificationStatus === "true" && (
          <Drawer
            visible={true}
            title="Notifications"
            onClose={closePopup}
            bodyStyle={{ padding: "0px 24px" }}
            extra={
              <Button
                type="primary"
                icon={<ReloadOutlined />}
                size="small"
                onClick={refreshNotifications}
              ></Button>
            }
          >
            <Tabs
              defaultActiveKey="unread"
              onChange={(key) => {
                setStatus(key);
              }}
            >
              <Tabs.TabPane tab="New" key="unread">
                <List
                  itemLayout="horizontal"
                  dataSource={notifications}
                  renderItem={(item, index) =>
                    item.projectUrl ? (
                      <List.Item
                        style={{
                          backgroundColor: !item.isRead
                            ? "rgb(240 240 240 / 48%)"
                            : "#fff",
                          padding: "10px 20px",
                        }}
                      >
                        <List.Item.Meta
                          avatar={
                            <Button
                              type="primary"
                              shape="circle"
                              icon={<CheckOutlined />}
                              size="small"
                              danger
                              onClick={() => {
                                markAs(item._id, "archived");
                              }}
                            />
                          }
                          title={
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleNotification(item);
                                }}
                              >
                                <strong>{item.content.title}</strong>
                              </span>
                              <Button
                                onClick={() => {
                                  handleIsReadStatus(item);
                                }}
                                type="text"
                                icon={
                                  item.isRead ? (
                                    <EyeInvisibleOutlined />
                                  ) : (
                                    <EyeOutlined />
                                  )
                                }
                                size="small"
                              />
                            </div>
                          }
                          description={item.content.text}
                        />
                      </List.Item>
                    ) : null
                  }
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Archived" key="archived">
                <List
                  itemLayout="horizontal"
                  dataSource={notifications}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<ArrowLeftOutlined />}
                            size="small"
                            danger
                            onClick={() => {
                              markAs(item._id, "read");
                            }}
                          />
                        }
                        title={
                          <Link
                            to={`/project/${
                              item.projectUrl?.status === "initial"
                                ? "research"
                                : item.projectUrl?.status
                            }/${item.project}?current_url=${
                              item.projectUrl?._id
                            }&source=notification`}
                            style={{ color: "gray" }}
                          >
                            {item.content.title}
                          </Link>
                        }
                        description={item.content.text}
                      />
                    </List.Item>
                  )}
                />
              </Tabs.TabPane>
            </Tabs>
          </Drawer>
        )}
      </>
    )
  );
};
