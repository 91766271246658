import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Avatar,
  Badge,
  Button,
  Card,
  Divider,
  Dropdown,
  Menu,
  Space,
} from "antd";

import {
  EditOutlined,
  PlusCircleOutlined,
  TableOutlined,
  UserOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  ApiOutlined,
  LogoutOutlined,
  CaretRightOutlined,
  ApartmentOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";

//contexts
import SettingContext from "../context/Setting";
import ProjectFolderContext from "../context/projectFolders";

//compoents
import AddNewProjectFolder from "./Projects/Folder/AddNew";
import { anchorApi } from "../utils/axios";
import UserContext from "../context/User";
import { checkParentItemCondition } from "../utils/checkPermission";
import CenterLayout from "./CenterLayout";
import Loading from "./Loading";
import { Assignee } from "./Project/Actions/Assignee";
import { GlobalNotification } from "./common/GlobalNotification";
import moment from "moment";

const inActiveStyle = {
  fontSize: "14px",
  fontWeight: "400",
  letterSpacing: "0.3px",
  paddingLeft: 30,
  cursor: "pointer",
  marginTop: 7,
};

const activeStyle = {
  fontSize: "14px",
  fontWeight: "500",
  letterSpacing: "0.3px",
  padding: 4,
  background: "rgb(102 18 226 / 17%)",
  borderLeft: "2px solid #8b3dff",
  paddingLeft: 26,
  marginTop: 7,
  cursor: "pointer",
};

export default function Header() {
  const { pagetitle, activeproject, setactiveproject } =
    useContext(SettingContext);
  const { popupopened, setpopupopended, folders, getAllProjectsFolders } =
    useContext(ProjectFolderContext);

  const { user } = useContext(UserContext);

  const signout = () => {
    anchorApi
      .post("/auth/logout")
      .then((res) => {
        window.location.href = "/login";
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (folders === null) {
      getAllProjectsFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders, user]);

  return (
    <Space
      style={{
        padding: "0px 20px",
        height: 56,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #e9ebf0",
      }}
    >
      <Space>
        <h3
          style={{
            color: "rgb(84 81 81)",
            fontSize: "17px",
            fontWeight: "600",
            margin: 0,
            padding: 0,
          }}
        >
          {activeproject ? (
            <>
            <Space>
              <Dropdown
                overlay={() => (
                  <Card size="small" style={{ width: 300 }}>
                    {folders === null ? (
                      <CenterLayout height={400}>
                        <Loading />
                      </CenterLayout>
                    ) : (
                      <ul
                        style={{
                          padding: "0px",
                          margin: "0px",
                          listStyle: "none",
                        }}
                      >
                        {folders.map((folder, i) => (
                          <li
                            key={folder._id}
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              marginBottom: 7,
                              padding: 2,
                              paddingLeft: 15,
                            }}
                          >
                            <Space>
                              <FolderOpenOutlined />
                              {folder.name.substring(0, 13)}
                            </Space>

                            {folder?.projects?.map((project, i) => (
                              <li
                                key={i}
                                style={
                                  activeproject._id === project._id
                                    ? activeStyle
                                    : inActiveStyle
                                }
                              >
                                <Space
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <a
                                    style={{ marginLeft: 5 }}
                                    href={
                                      project.type !== "drafted"
                                        ? `/project/research/${project._id}`
                                        : `/edit-draft-project/${project._id}`
                                    }
                                  >
                                    {" "}
                                    <Space>
                                      <ApartmentOutlined />
                                      <Space>{project?.name}</Space>
                                    </Space>
                                  </a>
                                </Space>
                              </li>
                            ))}
                          </li>
                        ))}
                      </ul>
                    )}
                  </Card>
                )}
                trigger={["click"]}
                placement="bottomLeft"
              >
                <Space size={4} style={{ cursor: "pointer" }}>
                  {pagetitle} <CaretRightOutlined />
                </Space>
              </Dropdown>
              <Assignee
                activeproject={activeproject}
                setactiveproject={setactiveproject}
              />
              <Badge
                  count={`${moment(activeproject?.cycle_start).format("MMM DD YYYY")} - ${moment(activeproject?.cycle_end).format("MMM DD YYYY")}`}
                  style={{ background: "#1677ff", color: "#fff" }}
                />
              {activeproject?.isArchived && (
                <Badge
                  count="Archived"
                  style={{ background: "#faad14", color: "black" }}
                />
              )}
            </Space></>
          ) : (
            pagetitle
          )}
        </h3>
      </Space>
      <Space>
        {user !== null && (
          <>
            {(checkParentItemCondition("Project", user) ||
              checkParentItemCondition("Email Template", user)) && (
              <Dropdown
                placement="bottomLeft"
                overlay={() => (
                  <Menu style={{ width: "200px" }}>
                    {checkParentItemCondition("Project", user) && (
                      <Menu.Item
                        key="03454"
                        icon={<EditOutlined />}
                        onClick={() => setpopupopended(true)}
                      >
                        New Folder/Clients
                      </Menu.Item>
                    )}
                    {checkParentItemCondition("Project", user) && (
                      <Menu.Item key="23sds3" icon={<PlusCircleOutlined />}>
                        <Link to="/project/add-new-project">New Project</Link>
                      </Menu.Item>
                    )}
                    {checkParentItemCondition("Email Template", user) && (
                      <Menu.Item key="16756sdsd7" icon={<TableOutlined />}>
                        <Link to="/email-templates/add-new">New Template</Link>
                      </Menu.Item>
                    )}
                  </Menu>
                )}
              >
                <Button type="text">
                  <PlusCircleOutlined /> Add New
                </Button>
              </Dropdown>
            )}
          </>
        )}

        <Divider type="vertical" />

        <GlobalNotification />

        <Divider type="vertical" />
        <Dropdown
          overlay={() => (
            <Menu style={{ width: "200px" }}>
              <Menu.Item key="0" icon={<UserOutlined />}>
                My Account
              </Menu.Item>
              <Menu.Item key="167567" icon={<SettingOutlined />}>
                <a href="https://www.aliyun.com">Settings</a>
              </Menu.Item>
              <Menu.Item key="156576" icon={<DollarCircleOutlined />}>
                <a href="https://www.aliyun.com">Billing and Payment</a>
              </Menu.Item>
              <Menu.Item key="1565" icon={<ApiOutlined />}>
                <a href="https://www.aliyun.com">API Keys</a>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="3" icon={<LogoutOutlined />} onClick={signout}>
                Signout
              </Menu.Item>
            </Menu>
          )}
          trigger={["click"]}
          placement="bottomLeft"
        >
          <Avatar
            size={25}
            icon={<UserOutlined />}
            style={{ cursor: "pointer" }}
          />
        </Dropdown>
      </Space>

      {popupopened && <AddNewProjectFolder setpopupopended={setpopupopended} />}
    </Space>
  );
}
