import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

//styles
import "./styles/projectglobal.css";

//libraies
import {
  Button,
  Popconfirm,
  Space,
  Divider,
  Menu,
  Dropdown,
  Alert,
  Progress,
  message,
} from "antd";
import { Resizable } from "react-resizable";
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";

//contexts
import SettingContext from "../../context/Setting";

//components
import CenterLayout from "../../components/CenterLayout";
import Loading from "../../components/Loading";
import TableColunItem from "../../components/Project/TableColunItem/Index";
import AppSpin from "../../components/AppSpin";
import Pagination from "./Pagination";
import ProojectTable from "./Table";
import BlackListUrls from "../../components/Project/Actions/BlackListUrls";
import BulkUpdate from "../../components/Project/Actions/BulkEdit";
import ProjectContext from "../../context/projectContext";
import UrlDetails from "../../components/Project/Actions/UrlDetails";
import SelectAllUrls from "../../components/Project/Actions/SelectAll";
import ExportUrls from "../../components/Project/Actions/ExportUrls";
import HideColumn from "../../components/Projects/Column/HideColumn";
import NewColumnToProject from "../../components/Projects/Column/AddNew";
import SortColumn from "../../components/Projects/Column/Sorting";
import EmailScrapping from "../../components/Project/EmailScrapping/EmailScrapping";
import CopyToAnotherProject from "../../components/Projects/CopyToAnotherProject";

//utils
import {
  deleteUrls,
  getProjectUploadBatchData,
  getProjectJobs,
  updateColumn,
} from "../../utils/projectData";
import { anchorApi } from "../../utils/axios";
import HandleError from "../../utils/errorHandle";
import HandleSuccess from "../../utils/successHandle";
import ApproveUrls from "../../components/Project/Actions/ApproveUrls";
import DeApproveUrls from "../../components/Project/Actions/DeApproveUrls";
import BackToResearchURLS from "../../components/Project/Actions/BackToResearch";
import MoveToManagement from "../../components/Project/Actions/MoveToManagement";
import UploadLogSavedFilter from "../../components/Project/Filter/UploadLogSavedFilter";
import UploadLogFilter from "../../components/Project/Filter/UploadLogFilter";
import UploadNoteFilter from "../../components/Project/Filter/UploadNoteFilter";

const ResizableTitle = (props) => {
  const { onResize, onResizeStop, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      onResizeStop={onResizeStop}
      draggableOpts={{
        enableUserSelectHack: false,
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default function UploadLog() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");
  const currentUrl = searchParams.get("current_url");
  const log = searchParams.get("log");

  const { activeproject } = useContext(SettingContext);
  const {
    currentprojecturl,
    projecturlsCount,
    setprojecturlsCount,
    setcurrentprojecturl,
  } = useContext(ProjectContext);
  const [columns, setcolumns] = useState([]);
  const [projecturls, setprojecturls] = useState(null);
  const [allprojecturlsselected, setallprojecturlsselected] = useState(false);

  const [filtermodalvis, setfiltermodelvis] = useState(false);
  const [bulkppdatemoalvis, setbulkppdatemoalvis] = useState(false);
  const [copyToAnotherProjec, setCopyToAnotherProjectmoalvis] = useState(false);

  const [setselectcolumn, setselectedcolumn] = useState([]);

  const [actionloading, setactionloading] = useState(false);

  const [selctedurls, setselctedurls] = useState([]);
  const [filterDataCount, setFilterDataCount] = useState(0);

  const [projectJob, setProjectJob] = useState(null);

  useEffect(() => {
    if (activeproject !== null) {
      setcolumns([
        {
          title: "Actions",
          dataIndex: "Actions",
          width: 150,
          fixed: "left",
          index: 0,
          render: (text, record) => (
            <Space size={4}>
              {record.status === "initial" && (
                <ApproveUrls
                  activeproject={activeproject}
                  query={query}
                  record={record}
                  setactionloading={setactionloading}
                  setprojecturls={setprojecturls}
                  fromCell={true}
                  selctedurls={selctedurls}
                  setselctedurls={setselctedurls}
                  setprojecturlsCount={setprojecturlsCount}
                  isUploadLog={true}
                />
              )}

              {record.status === "outreach" && (
                <>
                  <BackToResearchURLS
                    activeproject={activeproject}
                    query={query}
                    record={record}
                    setactionloading={setactionloading}
                    setprojecturls={setprojecturls}
                    fromCell={true}
                    selctedurls={selctedurls}
                    setselctedurls={setselctedurls}
                    setprojecturlsCount={setprojecturlsCount}
                    isUploadLog={true}
                  />
                  <MoveToManagement
                    activeproject={activeproject}
                    query={query}
                    record={record}
                    setactionloading={setactionloading}
                    setprojecturls={setprojecturls}
                    fromCell={true}
                    selctedurls={selctedurls}
                    setselctedurls={setselctedurls}
                    setprojecturlsCount={setprojecturlsCount}
                    isUploadLog={true}
                  />
                </>
              )}
              {record.status !== "management" && (
                <>
                  <DeApproveUrls
                    activeproject={activeproject}
                    query={query}
                    record={record}
                    setactionloading={setactionloading}
                    setprojecturls={setprojecturls}
                    fromCell={true}
                    selctedurls={selctedurls}
                    setselctedurls={setselctedurls}
                    setprojecturlsCount={setprojecturlsCount}
                    isUploadLog={true}
                  />
                  <BlackListUrls
                    activeproject={activeproject}
                    query={query}
                    record={record}
                    setactionloading={setactionloading}
                    setprojecturls={setprojecturls}
                    fromCell={true}
                    selctedurls={selctedurls}
                    setselctedurls={setselctedurls}
                    setprojecturlsCount={setprojecturlsCount}
                    isUploadLog={true}
                  />
                </>
              )}
            </Space>
          ),
        },
        {
          title: "Current Tab",
          dataIndex: "status",
          width: 150,
          fixed: "left",
          index: 1001,
          render: (text, record) => (
            <Space size={4}>
              {record.status === "initial" ? "Research" : record.status}
            </Space>
          ),
        },
        ...activeproject.columns
          .filter((cItem) => !cItem.hideonomanagement)
          .sort((a, b) => a.index - b.index)
          .map((item, i) => {
            if (
              i ===
              activeproject.columns.filter(
                (cPItem) => !cPItem.hideonomanagement
              ).length -
                1
            ) {
              return {
                ...item,
                title: () => (
                  <Space
                    key={i}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirect: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {item.name.toUpperCase()}{" "}
                    <Space size={2}>
                      <SortColumn
                        column={item}
                        query={query}
                        source="management"
                      />
                      <HideColumn
                        columns={columns}
                        setcolumns={setcolumns}
                        whereToHide="hideonomanagement"
                        columnId={item._id}
                      />
                      <NewColumnToProject
                        columns={columns}
                        setcolumns={setcolumns}
                      />
                    </Space>
                  </Space>
                ),
                dataIndex: item.name,
                render: (text, record) => (
                  <TableColunItem
                    setprojecturls={setprojecturls}
                    column={item}
                    text={text}
                    record={record}
                    isUploadLog={true}
                  />
                ),
                width: item.width || 100,
                ...(item.name === "Domain" && { fixed: "left" }),
              };
            } else {
              return {
                ...item,
                title: () => (
                  <Space
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirect: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {item.name.toUpperCase()}{" "}
                    <Space size={2}>
                      <SortColumn
                        column={item}
                        query={query}
                        source="management"
                      />
                      <HideColumn
                        columns={columns}
                        setcolumns={setcolumns}
                        whereToHide="hideonomanagement"
                        columnId={item._id}
                      />
                    </Space>
                  </Space>
                ),
                dataIndex: item.name,
                render: (text, record) => (
                  <TableColunItem
                    setprojecturls={setprojecturls}
                    column={item}
                    text={text}
                    record={record}
                    isUploadLog={true}
                  />
                ),
                width: item.width || 100,
                ...(item.name === "Domain" && { fixed: "left" }),
              };
            }
          }),
      ]);
      setactionloading(true);
      getProjectUploadBatchData(activeproject._id, query, log)
        .then((res) => {
          setFilterDataCount(res?.filterProjectUrlsCount);
          setprojecturls(
            res.projectUrls.map((item) => ({
              ...item?.data,
              ...item,
              key: item?._id,
            }))
          );
          setactionloading(false);
          setprojecturlsCount(res.projectUrlsCount);
        })
        .catch((err) => {
          HandleError(err.response?.data);
          setactionloading(false);
        });
      getProjectJobs(activeproject._id, query, "initial")
        .then((res) => {
          setProjectJob(res);
        })
        .catch((err) => {
          setProjectJob(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeproject, query, log]);

  useEffect(() => {
    //find the url in the projecturls by thr current url id
    if (
      currentUrl !== null &&
      currentUrl !== undefined &&
      activeproject !== null
    ) {
      setactionloading(true);
      anchorApi
        .get(`/project/url-detail/${currentUrl}`)
        .then((res) => {
          setcurrentprojecturl({ ...res.data, ...res.data.data });
          setactionloading(false);
        })
        .catch((err) => {
          setcurrentprojecturl(null);
          HandleError({
            error: "Url not found in the project urls",
            type: "string",
          });
          setactionloading(false);
        });
    }
  }, [currentUrl, activeproject]);

  useEffect(() => {
    if (selctedurls.length === 0) {
      setallprojecturlsselected(false);
    }
  }, [selctedurls, projecturls]);

  const deleteBulkUrls = async () => {
    const parsedQuery = query !== null && query !== "" && JSON.parse(query);
    const includeLiveLinks =
      parsedQuery !== null && parsedQuery.includeLiveLinks !== undefined
        ? true
        : false;

    deleteUrls(
      activeproject?._id,
      selctedurls,
      allprojecturlsselected,
      includeLiveLinks
    )
      .then((res) => {
        setprojecturls((prev) =>
          prev.filter((url) => !selctedurls.includes(url._id))
        );
        setprojecturlsCount((prev) =>
          allprojecturlsselected ? 0 : prev - selctedurls.length
        );
        setselctedurls([]);
        HandleSuccess(res.data);
        setactionloading(false);
      })
      .catch((err) => {
        setactionloading(false);
        HandleError(err.response?.data);
      });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setselctedurls(selectedRowKeys);
    },
    selectedRowKeys: selctedurls,
  };

  const handleResize =
    (index) =>
    (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = { ...newColumns[index], width: size.width };
      setcolumns(newColumns);
    };

  const handleResizeStop =
    (index) =>
    (_, { size }) => {
      const newColumns = [...columns];
      newColumns[index] = { ...newColumns[index], width: size.width };
      setcolumns(newColumns);
      updateColumn(activeproject._id, newColumns[index]._id, {
        width: size.width,
      })
        .then((res) => {})
        .catch((err) => {});
    };

  const mergeColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResizeStop: handleResizeStop(index),
      onResize: handleResize(index),
    }),
  }));

  const refreshJobStatus = () => {
    getProjectJobs(activeproject._id, query, "initial")
      .then((res) => {
        setProjectJob(res);
      })
      .catch((err) => {
        setProjectJob(null);
      });
    message.success("Leads uploading status refreshed");
  };

  const calculateProjectJobProgress = () => {
    if (projectJob !== null) {
      const total = projectJob?.progress?.total;
      const completed = projectJob?.progress?.completed;
      const progress = Math.round((completed / total) * 100);
      return progress;
    }
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <BlackListUrls
          activeproject={activeproject}
          query={query}
          setactionloading={setactionloading}
          setprojecturls={setprojecturls}
          fromCell={false}
          selctedurls={selctedurls}
          setselctedurls={setselctedurls}
          projecturlsCount={projecturlsCount}
          allprojecturlsselected={allprojecturlsselected}
          setallprojecturlsselected={setallprojecturlsselected}
          setprojecturlsCount={setprojecturlsCount}
        />{" "}
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          onConfirm={deleteBulkUrls}
          title="Would you like to delete ?"
          okText="Yes"
          disabled={selctedurls.length === 0}
        >
          <Button
            icon={<DeleteOutlined />}
            size="small"
            type="text"
            disabled={selctedurls.length === 0}
          >
            Delete URLs
          </Button>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          marginBottom: 20,
          border: "1px dashed #8b3dff",
          padding: "7px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Space>
          <Button
            target="_blank"
            href={`/url-preview?prevUrl=management&active=${selctedurls[0]}&activeprojectId=${activeproject._id}&selctedurls=${selctedurls}`}
            icon={<EyeOutlined />}
            size="small"
            type="text"
            disabled={selctedurls.length === 0}
          >
            Preview URLs
          </Button>
          <Button
            icon={<EditOutlined />}
            size="middle"
            type="text"
            disabled={selctedurls?.length === 0}
            onClick={() => setbulkppdatemoalvis(true)}
          ></Button>
          {bulkppdatemoalvis && (
            <BulkUpdate
              activeproject={activeproject}
              query={query}
              setactionloading={setactionloading}
              setprojecturls={setprojecturls}
              fromCell={false}
              selctedurls={selctedurls}
              setselctedurls={setselctedurls}
              setbulkppdatemoalvis={setbulkppdatemoalvis}
              bulkppdatemoalvis={bulkppdatemoalvis}
              actionloading={actionloading}
              projecturls={projecturls}
              projecturlsCount={projecturlsCount}
              allprojecturlsselected={allprojecturlsselected}
              setallprojecturlsselected={setallprojecturlsselected}
              source="management"
            />
          )}
          <Button
            icon={<CopyOutlined />}
            size="middle"
            type="text"
            disabled={selctedurls?.length === 0}
            onClick={() => setCopyToAnotherProjectmoalvis(true)}
          ></Button>

          {copyToAnotherProjec && (
            <CopyToAnotherProject
              activeproject={activeproject}
              setactionloading={setactionloading}
              setprojecturls={setprojecturls}
              selctedurls={selctedurls}
              setCopyToAnotherProjectmoalvis={setCopyToAnotherProjectmoalvis}
              copyToAnotherProjec={copyToAnotherProjec}
              actionloading={actionloading}
              allprojecturlsselected={allprojecturlsselected}
            />
          )}

          <Dropdown overlay={menu}>
            <Button size="small">
              <Space>
                Quick
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>

          <ExportUrls
            source="management"
            query={query}
            selctedurls={selctedurls}
            setselctedurls={setselctedurls}
          />
          <Divider type="vertical" />
          <EmailScrapping
            activeproject={activeproject}
            query={query}
            setactionloading={setactionloading}
            setprojecturls={setprojecturls}
            fromCell={false}
            selctedurls={selctedurls}
            setselctedurls={setselctedurls}
            actionloading={actionloading}
            projecturls={projecturls}
            projecturlsCount={projecturlsCount}
            allprojecturlsselected={allprojecturlsselected}
            setallprojecturlsselected={setallprojecturlsselected}
            source="management"
          />
          <Divider type="vertical" />
          <Space>
            <UploadLogSavedFilter log={log} />
            <UploadLogFilter
              filtermodalvis={filtermodalvis}
              setfiltermodelvis={setfiltermodelvis}
              query={query}
              setselectcolumn={setselectcolumn}
              setselectedcolumn={setselectedcolumn}
              source="upload-log"
              log={log}
            />
            <Divider />
            <UploadNoteFilter
              source="management"
              query={query}
              activeproject={activeproject}
              log={log}
            />
            <Divider type="vertical" />
          </Space>
        </Space>
      </div>

      {selctedurls.length === projecturls?.length &&
        projecturlsCount > projecturls?.length && (
          <SelectAllUrls
            filterDataCount={filterDataCount}
            projecturls={projecturls}
            projecturlsCount={projecturlsCount}
            allprojecturlsselected={allprojecturlsselected}
            setallprojecturlsselected={setallprojecturlsselected}
            source="Outreach"
          />
        )}

      {projecturls !== null ? (
        <AppSpin spining={actionloading}>
          {projectJob && (
            <Alert
              style={{ marginBottom: 40 }}
              message="Leads Uploading"
              description={
                <>
                  <span>
                    Leads are uploading in the background. Please wait for a
                    while. If you want to see the latest report, please click on
                    the refresh button.
                  </span>
                  <Progress percent={calculateProjectJobProgress()} />
                </>
              }
              type="info"
              action={
                <Space direction="vertical">
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => refreshJobStatus()}
                  >
                    Refresh
                  </Button>
                  <Button
                    size="small"
                    danger
                    type="ghost"
                    onClick={() => setProjectJob(null)}
                  >
                    Ignore
                  </Button>
                </Space>
              }
              closable
            />
          )}

          <ProojectTable
            mergeColumns={mergeColumns}
            rowSelection={rowSelection}
            projecturls={projecturls}
            ResizableTitle={ResizableTitle}
          />
          <Pagination
            filterDataCount={filterDataCount}
            query={query}
            setactionloading={setactionloading}
            activeproject={activeproject}
            projecturls={projecturls}
            source="management"
            projecturlsCount={projecturlsCount}
          />
        </AppSpin>
      ) : (
        <CenterLayout height={300}>
          <Loading />
        </CenterLayout>
      )}

      {currentprojecturl !== null && (
        <UrlDetails
          activeproject={activeproject}
          projecturls={projecturls}
          setprojecturls={setprojecturls}
          isUploadLog={true}
        />
      )}
    </div>
  );
}
