import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ProjectContext = React.createContext();

export const ProjectProvider = (props) => {
  const location = useLocation();

  const [project, setproject] = useState(null);

  const [currentprojecturl, setcurrentprojecturl] = useState(null);
  const [projecturlsCount, setprojecturlsCount] = useState(null);

  const [updateModelVis, setUpdateModelVis] = useState(false);
  const [formloading, setFormloading] = useState(false);

  const [addColumnModelVis, setAddColumnModelVis] = useState(false);

  useEffect(() => {
    setcurrentprojecturl(null);
  }, [location.pathname]);

  const me = () => {
    console.log("Update me");
  };

  return (
    <ProjectContext.Provider
      value={{
        project,
        setproject,
        me,
        currentprojecturl,
        setcurrentprojecturl,
        updateModelVis,
        setUpdateModelVis,
        formloading,
        setFormloading,
        addColumnModelVis,
        setAddColumnModelVis,
        projecturlsCount,
        setprojecturlsCount,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
