import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import { Space, Tabs, Typography } from "antd";

//variables
import { contentWidth } from "../utils/variables";
import { PlusCircleOutlined, UserSwitchOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

export default function UserManagementLayout() {
  const location = useLocation();

  const [activenavlink, setactivenavlink] = useState(null);

  useEffect(() => {
    if (location.pathname === "/setting/team/list") {
      setactivenavlink("1");
      return;
    }

    if (location.pathname === "/setting/team/add-new") {
      setactivenavlink("2");
      return;
    }
    setactivenavlink("dashboard");
  }, [location.pathname]);

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: contentWidth,
        }}
      >
        <Space
          style={{
            marginBottom: 20,
            marginTop: 20,
            textAlign: "center",
            width: "100%",
          }}
          align="center"
          direction="vertical"
        >
          <Title
            level={2}
            style={{ textAlign: "center", color: "rgb(84, 81, 81)" }}
          >
            Team Management
          </Title>
          <Text style={{ color: "rgb(107 102 102)" }}>
            Here you have a list of your all team members. These team memeber
            has your data access in based of your given permission. You can add,
            delete or update any of the team member from here.
          </Text>
        </Space>
        {activenavlink !== null && (
          <Tabs
            tabPosition={"top"}
            size="small"
            defaultActiveKey={activenavlink}
            activeKey={activenavlink}
          >
            <TabPane
              tab={
                <Link to="/setting/team/list">
                  <Space size={6}>
                    <UserSwitchOutlined style={{ marginRight: 0 }} /> List
                  </Space>
                </Link>
              }
              key={"1"}
            ></TabPane>
            <TabPane
              tab={
                <Link to="/setting/team/add-new">
                  <Space size={6}>
                    <PlusCircleOutlined style={{ marginRight: 0 }} /> Add Team
                    Member
                  </Space>
                </Link>
              }
              key={"2"}
            ></TabPane>
          </Tabs>
        )}

        <div style={{ marginTop: 30 }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
