import React, { useState } from "react";

//utils
import { anchorApi } from "../utils/axios";

const GlobalInboxContext = React.createContext();

export const GlobalInboxProvider = (props) => {
  const [loadingNeeded, setLoadingNeeded] = useState(true);
  const [currentThread, setCurrentThread] = useState(null);
  const [threadsLoading, setThreadsLoading] = useState(false);
  const [threadsReloadLoading, setThreadsReloadLoading] = useState(false);

  const [threads, setThreads] = useState([]);

  const [totalInboxSize, settotalInboxSize] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePaginationUp = (whereIn, filterProject) => {
    setThreadsReloadLoading(true);
    anchorApi
      .get(
        `/global-email/read-project-emails?page=${
          currentPage + 1
        }&in=${whereIn}&project=${filterProject}`
      )
      .then((res) => {
        setCurrentPage((prev) => prev + 1);
        setThreads(res.data.myThreads);
        settotalInboxSize(res.data.resultSizeEstimate);
        setThreadsReloadLoading(false);
      })
      .catch((err) => {
        setThreadsReloadLoading(false);
      });
  };

  const handlePaginationDown = (whereIn, filterProject) => {
    setThreadsReloadLoading(true);
    anchorApi
      .get(
        `/global-email/read-project-emails?page=${
          currentPage - 1
        }&in=${whereIn}&project=${filterProject}`
      )
      .then((res) => {
        setCurrentPage((prev) => prev - 1);
        setThreads(res.data.myThreads);
        settotalInboxSize(res.data.resultSizeEstimate);
        setThreadsReloadLoading(false);
      })
      .catch((err) => {
        setThreadsReloadLoading(false);
      });
  };

  return (
    <GlobalInboxContext.Provider
      value={{
        loadingNeeded,
        setLoadingNeeded,
        currentThread,
        setCurrentThread,
        threadsLoading,
        setThreadsLoading,
        threads,
        setThreads,
        totalInboxSize,
        settotalInboxSize,
        currentPage,
        setCurrentPage,
        handlePaginationUp,
        handlePaginationDown,
        threadsReloadLoading,
        setThreadsReloadLoading,
      }}
    >
      {props.children}
    </GlobalInboxContext.Provider>
  );
};

export default GlobalInboxContext;
