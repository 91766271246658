import { Button, Space } from "antd";
import React from "react";

export default function PopupFooter({ action, btnText, marginTop }) {
  return (
    <Space
      style={{
        width: "100%",
        marginTop: marginTop === undefined ? 30 : marginTop,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Button onClick={() => action(false)}>Cancel</Button>
      <Button type="primary" htmlType="submit">
        {btnText ? btnText : "Save Changes"}
      </Button>
    </Space>
  );
}
