import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//utils
import { anchorApi } from "../utils/axios";
import ErrorHandle from "../utils/errorHandle";
import HandleError from "../utils/errorHandle";
import HandleSuccess from "../utils/successHandle";

const EmailTemplateContext = React.createContext();

export const EmailTemplateProvider = (props) => {
  let navigate = useNavigate();
  const location = useLocation();

  const [current, setcurrent] = useState(0);

  const [templates, settemplates] = useState([]);
  const [popupopened, setpopupopended] = useState(false);
  const [formloading, setformloading] = useState(false);
  const [loading, setloading] = useState(false);

  const [actioncompleted, setactioncompleted] = useState(false);

  const [deletingtemplate, setdeletingtemplate] = useState(false);
  const [editingtemplate, seteditingtemplate] = useState(null);

  //set the action completed when somewhere navigated
  useEffect(() => {
    setactioncompleted(false);
  }, [location.pathname]);

  const addEmailTemplate = (values, form) => {
    setformloading(true);
    anchorApi
      .post("/email-template/create", values)
      .then((res) => {
        seteditingtemplate(res.data.template);
        settemplates((prev) => [...prev, res.data.template]);
        setformloading(false);
        form.resetFields();
        setactioncompleted(true);
        HandleSuccess({ msg: "Email template created successfully" });
      })
      .catch((err) => {
        setformloading(false);
        ErrorHandle(err.response?.data);
      });
  };

  const getAllEmailTemplates = () => {
    setloading(true);
    anchorApi
      .get("/email-template")
      .then((res) => {
        settemplates(res.data);
        setloading(false);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setloading(false);
      });
  };

  const deletetemplate = (templateId) => {
    setformloading(true);
    anchorApi
      .post(`/email-template/delete/${templateId}`)
      .then((res) => {
        settemplates((prev) =>
          prev.filter((template) => template._id !== templateId)
        );
        setformloading(false);
        setdeletingtemplate(false);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const gettemplate = (templateId, form, setValue) => {
    setloading(true);
    anchorApi
      .get(`/email-template/detail/${templateId}`)
      .then((res) => {
        seteditingtemplate(res.data);
        form.setFieldsValue({
          name: res.data?.name,
          subject: res.data?.subject,
          body: res.data?.body,
        });
        // setValue(res.data?.body);
        setloading(false);
      })
      .catch((err) => {
        navigate("/email-templates/list");
        HandleError(err.response?.data);
        setloading(false);
      });
  };

  const updateemplate = (values, templateId) => {
    setformloading(true);
    anchorApi
      .post(`/email-template/update/${templateId}`, values)
      .then((res) => {
        HandleSuccess(res.data);
        setformloading(false);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const addFollowup = (templateId, values, form, setValue, setAddModelVis) => {
    setformloading(true);
    anchorApi
      .post(`/email-template/add-follow-up/${templateId}`, values)
      .then((res) => {
        seteditingtemplate(res.data.emailTemplate);
        form.resetFields();
        setValue("");
        setformloading(false);
        setAddModelVis(false);
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };
  const updateFollowup = (
    templateId,
    followupId,
    values,
    form,
    setValue,
    setAddModelVis
  ) => {
    setformloading(true);
    anchorApi
      .post(
        `/email-template/update-follow-up/${templateId}/${followupId}`,
        values
      )
      .then((res) => {
        seteditingtemplate(res.data.emailTemplate);
        form.resetFields();
        setValue("");
        setformloading(false);
        setAddModelVis(false);
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  const deleteFollowup = (followupId) => {
    setformloading(true);
    anchorApi
      .post(
        `/email-template/delete-follow-up/${editingtemplate?._id}/${followupId}`
      )
      .then((res) => {
        seteditingtemplate(res.data.emailTemplate);
        HandleSuccess(res.data);
        setformloading(false);
      })
      .catch((err) => {
        HandleError(err.response?.data);
        setformloading(false);
      });
  };

  return (
    <EmailTemplateContext.Provider
      value={{
        current,
        setcurrent,
        templates,
        settemplates,
        popupopened,
        setpopupopended,
        formloading,
        setformloading,
        loading,
        setloading,
        addEmailTemplate,
        actioncompleted,
        setactioncompleted,
        getAllEmailTemplates,
        deletetemplate,
        deletingtemplate,
        setdeletingtemplate,
        gettemplate,
        editingtemplate,
        seteditingtemplate,
        updateemplate,
        addFollowup,
        updateFollowup,
        deleteFollowup,
      }}
    >
      {props.children}
    </EmailTemplateContext.Provider>
  );
};

export default EmailTemplateContext;
