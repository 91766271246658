import { Button } from "antd";
import React from "react";

export default function SelectAllUrls({
  projecturls,
  projecturlsCount,
  source,
  allprojecturlsselected,
  setallprojecturlsselected,
  filterDataCount,
}) { 

  // console.log("filterDataCount", filterDataCount);

  if (allprojecturlsselected) {
    return (
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          padding: "4px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(241,243,244,0.871)",
        }}
      >
        All {projecturlsCount} URLS in {source || "Research"} are selected.
        <Button
          type="link"
          style={{ marginLeft: -5 }}
          onClick={() => setallprojecturlsselected(false)}
        >
          Clear selection
        </Button>
      </div>
    );
  }

  // if (JSON.parse(query)?.filters) {
  //   return (
  //     <div
  //       style={{
  //         marginTop: 20,
  //         marginBottom: 20,
  //         padding: "4px 20px",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         backgroundColor: "rgba(241,243,244,0.871)",
  //       }}
  //     >
  //       All {projecturls?.length} URLS on this page are selected.
  //       <Button
  //         type="link"
  //         style={{ marginLeft: -5 }}
  //         onClick={() => setallprojecturlsselected(true)}
  //       >
  //         Select all Filtered {filterDataCount} URLS in {source || "Research"}
  //       </Button>
  //     </div>
  //   );
  // }

  return (
    <div
      style={{
        marginTop: 20,
        marginBottom: 20,
        padding: "4px 20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(241,243,244,0.871)",
      }}
    >
      All {projecturls?.length} URLS on this page are selected.
      <Button
        type="link"
        style={{ marginLeft: -5 }}
        onClick={() => setallprojecturlsselected(true)}
      >
        Select all {filterDataCount} URLS in {source || "Research"}
        {/* Select all {projectUrls.length} URLS in {source || "Research"} */}
      </Button>
    </div>
  );
}
