import React, { useContext, useState } from "react";
import { CloudDownloadOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";

import SettingContext from "../../../context/Setting";
import { anchorApi } from "../../../utils/axios";
import HandleError from "../../../utils/errorHandle";
import HandleSuccess from "../../../utils/successHandle";

export default function ExportUrls({
  source,
  query,
  selctedurls,
  setselctedurls,
}) {
  const { activeproject } = useContext(SettingContext);

  const [loading, setLoading] = useState(false);

  const exportUrls = async () => {
    setLoading(true);
    anchorApi
      .post(`/project/export-urls/${activeproject?._id}`, {
        status: source,
        query,
        selctedurls: selctedurls,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data.csv]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", res.data.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        setLoading(false);
        HandleSuccess(res.data);
      })
      .catch((err) => {
        HandleError(err?.response?.data);
        setLoading(false);
      });
  };

  return (
    <Tooltip title="Export URLS" placement="bottom">
      <Popconfirm title="Do you want to download urls?" onConfirm={exportUrls}>
        {loading ? (
          <SyncOutlined spin />
        ) : (
          <Button
            icon={<CloudDownloadOutlined />}
            size="middle"
            type="text"
          ></Button>
        )}
      </Popconfirm>
    </Tooltip>
  );
}
