import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//utils
import { anchorApi } from "../utils/axios";

const ProjectInboxContext = React.createContext();

export const ProjectInboxProvider = (props) => {
  const location = useLocation();

  const [projectEmail, setprojectEmail] = useState(null);
  const [currentThread, setCurrentThread] = useState(null);
  const [projectEmailLoading, setprojectEmailLoading] = useState(false);

  const [loadingNeeded, setLoadingNeeded] = useState(true);

  const [projectThreadsLoading, setprojectThreadsLoading] = useState(false);
  const [projectThreadsReloadLoading, setprojectThreadsReloadLoading] =
    useState(false);
  const [projectThreads, setprojectThreads] = useState([]);

  const [totalInboxSize, settotalInboxSize] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  //single thread
  const [getMessagesByThread, setGetMessagesByThread] = useState(false);
  const [selectedthread, setSelectedthread] = useState(null);

  const [threadMessages, setThreadMessages] = useState([]);
  const [threadProjectUrl, setThreadProjectUrl] = useState(null);
  const [defaultOpen, setDefaultOpen] = useState(null);
  const [hassError, setHassError] = useState(false);

  useEffect(() => {
    setprojectThreads([]);
    setHassError(false);
    setLoadingNeeded(true);
  }, [location.pathname]);

  const handlePaginationUp = (whereIn) => {
    setprojectThreadsReloadLoading(true);
    anchorApi
      .get(
        `/project-email/read-project-emails?project=${
          projectEmail.project
        }&page=${currentPage + 1}&pageToken=${nextPage}&in=${whereIn}`
      )
      .then((res) => {
        setCurrentPage((prev) => prev + 1);
        setprojectThreads(res.data.myThreads);
        settotalInboxSize(res.data.resultSizeEstimate);
        setNextPage(res.data?.nextPageToken);
        setprojectThreadsReloadLoading(false);
      })
      .catch((err) => {
        console.log("err", err.response?.data);
        setprojectThreadsReloadLoading(false);
      });
  };

  const handlePaginationDown = (whereIn) => {
    setprojectThreadsReloadLoading(true);
    anchorApi
      .get(
        `/project-email/read-project-emails?project=${projectEmail.project}&=${
          currentPage - 1
        }&in=${whereIn}`
      )
      .then((res) => {
        console.log(res.data);

        setCurrentPage((prev) => prev - 1);
        setprojectThreads(res.data.myThreads);
        settotalInboxSize(res.data.resultSizeEstimate);
        setNextPage(res.data?.nextPageToken);
        setprojectThreadsReloadLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setprojectThreadsReloadLoading(false);
      });
  };

  return (
    <ProjectInboxContext.Provider
      value={{
        projectEmail,
        setprojectEmail,
        projectEmailLoading,
        setprojectEmailLoading,
        projectThreadsLoading,
        setprojectThreadsLoading,
        projectThreads,
        setprojectThreads,
        totalInboxSize,
        settotalInboxSize,
        nextPage,
        setNextPage,
        currentPage,
        setCurrentPage,
        handlePaginationUp,
        handlePaginationDown,
        currentThread,
        setCurrentThread,
        getMessagesByThread,
        setGetMessagesByThread,
        selectedthread,
        setSelectedthread,
        threadMessages,
        setThreadMessages,
        threadProjectUrl,
        setThreadProjectUrl,
        defaultOpen,
        setDefaultOpen,
        hassError,
        setHassError,
        projectThreadsReloadLoading,
        setprojectThreadsReloadLoading,
        loadingNeeded,
        setLoadingNeeded,
      }}
    >
      {props.children}
    </ProjectInboxContext.Provider>
  );
};

export default ProjectInboxContext;
