import React from "react";

import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useEffect } from "react";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function FormItem({
  key,
  name,
  activeproject,
  fields,
  remove,
  add,
  restField,
  oldvalues,
  form,
}) {
  const [current, setcurrent] = useState(null);
  const [condition, setcondition] = useState("");

  const changeValue = (value) => {
    form.setFieldsValue({
      filters: form
        .getFieldValue("filters")
        .map((item) =>
          item !== undefined
            ? item.column === value
              ? { ...item, query: undefined, condition: undefined }
              : item
            : item
        ),
    });
    setcondition(null);
    const target = activeproject?.columns.filter(
      (item) => item.name === value
    )[0];
    setcurrent(target);
  };

  useEffect(() => {
    const currentField = oldvalues?.[name]?.column;

    if (oldvalues?.[name]?.query === undefined) {
      setcondition("empty");
    }

    if (currentField) {
      const target = activeproject?.columns.filter(
        (item) => item.name === currentField
      )[0];
      setcurrent(target);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldvalues, activeproject]);

  // const watchFilterForm = Form.useWatch('');

  return (
    <Row key={key} style={{ display: "flex" }} align="baseline" gutter={16}>
      <Col span={6}>
        <Form.Item
          {...restField}
          name={[name, "column"]}
          rules={[
            {
              required: true,
              message: "Column is required.",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Columns"
            onChange={(value) => changeValue(value)}
            showSearch
          >
            {activeproject?.columns.map((column, i) => (
              <Option value={column.name}>{column.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          {...restField}
          name={[name, "condition"]}
          rules={[
            {
              required: true,
              message: "Condition is required.",
            },
          ]}
        >
          <Select
            style={{ width: "160px" }}
            placeholder="Condition"
            onChange={(value) => setcondition(value)}
            showSearch
          >
            {current !== null &&
            (current.type === "select" || current.type === "tags") ? (
              <>
                <Option value="Includes">Includes</Option>
                <Option value="==">Equal To</Option>
                <Option value="!==">Not Equal To</Option>
                <Option value="empty">Empty</Option>
              </>
            ) : current?.type === "email" ? (
              <>
                <Option value="==">Equal To</Option>
                <Option value="%LIKE%">Contains</Option>
                <Option value="empty">Empty</Option>
              </>
            ) : current?.type === "date" ? (
              <Option value={`between`}>{`Between`}</Option>
            ) : (
              <>
                <Option value={`<`}>{`More Than`}</Option>
                <Option value={`>`}>{`Less Than`}</Option>
                <Option value="==">Equal To</Option>
                <Option value="!==">Not Equal To</Option>
                <Option value="%LIKE%">Contains</Option>
                <Option value="empty">Empty</Option>
              </>
            )}
          </Select>
        </Form.Item>
      </Col>

      {condition !== "empty" && (
        <Col span={8}>
          <Form.Item
            {...restField}
            name={[name, "query"]}
            rules={[
              {
                required: true,
                message: "Query is required.",
              },
            ]}
          >
            {current !== null ? (
              current.type === "select" || current.type === "tags" ? (
                <Select
                  placeholder="options"
                  allowClear={true}
                  mode="multiple"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {current.options?.map((item) => (
                    <Option value={item.label}>{item.label}</Option>
                  ))}
                </Select>
              ) : current.type === "date" ? (
                <RangePicker format="MM-DD-YYYY" style={{ width: "100%" }} />
              ) : (
                <Input placeholder="query" />
              )
            ) : (
              <Input placeholder="query" />
            )}
          </Form.Item>
        </Col>
      )}

      <Col span={4}>
        <Button
          style={{ marginRight: 10 }}
          disabled={fields.length === 1}
          onClick={() => remove(name)}
        >
          <MinusCircleOutlined />
        </Button>
        <Button type="primary" onClick={() => add()}>
          <PlusCircleOutlined />
        </Button>
      </Col>
    </Row>
  );
}
