export const primaryColor = "#8b3dff";

export const frontendUrl = "https://app.anchortext.io/";
export const backendUrl = "https://tool.anchortext.io/api/v2";
export const baseBackendUrl = "https://tool.anchortext.io";

// export const frontendUrl = "http://localhost:3000/";
// export const backendUrl = "http://localhost:2000/api/v2";
// export const baseBackendUrl = "http://localhost:2000";

export const contentWidth = 900;

export const colorLabels = [
  "red",
  "green",
  "blue",
  "gray",
  "teal",
  "orange",
  "yellow",
  "purple",
];

export const defaultStatusList = [
  { label: "Added", color: "orange" },
  { label: "Scheduled", color: "black" },
  { label: "Email Sent", color: "teal" },
  { label: "Bounced", color: "yellow" },
  { label: "Replied", color: "lightgray" },
  { label: "Review Price / Website", color: "blue" },
  { label: "Negotiation", color: "#808000" },
  { label: "Approved", color: "#B5DA46" },
  { label: "Writing Article", color: "#A52A2A" },
  { label: "Writing Sent", color: "#8A2BE2" },
  { label: "Invoice unpaid", color: "#6495ED" },
  { label: "Awaiting Invoice", color: "#FF7F50" },
  { label: "Link Insertion In Review", color: "#DC143C" },
  { label: "Paid - Need Insertion", color: "#B5DA46" },
  { label: "Paid", color: "#B5DA46" },
  { label: "Upfront Payment Request", color: "#4B0082" },
  { label: "Live", color: "#B5DA46" },
  { label: "Rejected", color: "red" },
];

export const defaultColumns = [
  {
    name: "Domain",
    dataIndex: "domain",
    type: "text",
    default: true,
    mapAble: false,
    index: 0,
    width: 120,
  },
  {
    name: "URL",
    dataIndex: "url",
    type: "link",
    default: true,
    mapAble: true,
    index: 1,
    width: 400,
  },

  {
    name: "DR",
    dataIndex: "dr",
    type: "text",
    default: true,
    mapAble: true,
    extrainfo: [],
    width: 120,
    index: 2,
  },
  {
    name: "DT",
    dataIndex: "dt",
    type: "text",
    default: true,
    mapAble: true,
    extrainfo: [],
    width: 120,
    index: 3,
  },

  {
    name: "Email",
    dataIndex: "email",
    type: "email",
    default: true,
    mapAble: true,
    index: 4,
    width: 300,
  },

  {
    name: "Status",
    dataIndex: "status",
    type: "select",
    default: true,
    mapAble: false,
    index: 5,
    width: 200,
    options: defaultStatusList,
  },
  {
    name: "Anchor",
    dataIndex: "anchor",
    status: true,
    visibility: true,
    type: "text",
    mapAble: true,
    default: true,
    index: 6,
    width: 200,
  },
  {
    name: "Anchor Type",
    dataIndex: "anchor_type",
    status: true,
    visibility: true,
    type: "select",
    options: [
      { label: "Exact", color: null },
      { label: "Targeted", color: null },
      { label: "Brand", color: null },
      { label: "Misc", color: null },
      { label: "URL", color: null },
      { label: "Image/Empty", color: null },
    ],
    width: 100,
    mapAble: false,
    default: true,
    index: 7,
  },
  {
    name: "Target URL",
    dataIndex: "target_url",
    status: true,
    visibility: true,
    type: "link",
    width: 300,
    mapAble: true,
    default: true,
    index: 8,
  },
  {
    name: "Industry",
    dataIndex: "industry",
    status: true,
    visibility: true,
    type: "tags",
    options: [],
    width: 100,
    mapAble: false,
    default: true,
    index: 9,
  },
  {
    name: "Cycle",
    dataIndex: "cycle",
    status: true,
    visibility: true,
    type: "cycle",
    options: [],
    width: 100,
    mapAble: false,
    default: true,
    index: 10,
  },
  {
    name: "Paid Date",
    dataIndex: "Paid Date",
    status: true,
    visibility: true,
    type: "date",
    options: [],
    width: 150,
    mapAble: false,
    default: true,
    index: 11,
  },
  {
    name: "Last Modified",
    dataIndex: "lastModified",
    type: "date",
    index: 150,
    default: true,
    mapAble: false,
    positionLast: true,
    width: 200,
  },
  {
    name: "Date Added",
    dataIndex: "dateAdded",
    type: "date",
    index: 151,
    default: true,
    mapAble: false,
    positionLast: true,
    width: 200,
  },
];

export const anchorType = [
  "Exact",
  "Targeted",
  "Brand",
  "Misc",
  "URL",
  "Image/Empty",
];

export const DRMETRICSUNIT = [
  0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
  100,
];

export const bgColors = [
  "#f5222d",
  "#096dd9",
  "#006d75",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(255, 159, 64, 0.2)",
];

export const borderColors = [
  "#820014",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
];

export const oldestDate = "01/01/2015";
