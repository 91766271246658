import React, { useContext } from "react";
import { Link } from "react-router-dom";

import {
  HomeOutlined,
  InboxOutlined,
  DiffOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  SendOutlined,
  SettingOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import SettingContext from "../context/Setting";
import { Tooltip } from "antd";
import UserContext from "../context/User";
import { checkParentItemCondition } from "../utils/checkPermission";

const linkCss = {
  color: "#fff",
  fontSize: "18px",
  fontWeight: "bold",
  marginBottom: 15,
};

const activeLinkCss = {
  color: "#fff",
  fontSize: "16px",
  fontWeight: "bold",
  marginBottom: 15,
  width: 32,
  height: 32,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 4,
  background: "#8b3dff",
};

export default function Sidebar() {
  const { activenavlink } = useContext(SettingContext);
  const { user } = useContext(UserContext);

  return (
    <div
      style={{
        height: "100vh",
        width: "56px",
        backgroundColor: "#2F2C2C",
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 10,
      }}
    >
      {user && (
        <>
          <h1
            style={{
              color: "#8b3dff",
              fontSize: "23px",
              fontWeight: "bold",
              margin: 0,
              padding: 0,
            }}
          >
            A
          </h1>
          <ul
            style={{
              margin: 0,
              padding: 0,
              listStyle: "none",
              marginTop: 60,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Tooltip
              title="Dashboard"
              placement="right"
              overlayStyle={{ marginLeft: 40 }}
            >
              <Link to="/dashboard">
                <li
                  style={
                    activenavlink === "dashboard" ? activeLinkCss : linkCss
                  }
                >
                  <HomeOutlined />
                </li>
              </Link>
            </Tooltip>
            {checkParentItemCondition("Project", user) && (
              <Tooltip
                title="Projects"
                placement="right"
                overlayStyle={{ marginLeft: 40 }}
              >
                <Link to="/projects">
                  <li
                    style={
                      activenavlink === "projects" ? activeLinkCss : linkCss
                    }
                  >
                    <ApartmentOutlined />
                  </li>
                </Link>
              </Tooltip>
            )}
            {checkParentItemCondition("Email", user) && (
              <Tooltip
                title="Inbox"
                placement="right"
                overlayStyle={{ marginLeft: 40 }}
              >
                <Link to="/inbox">
                  <li
                    style={
                      activenavlink === "inboxGlobal" ? activeLinkCss : linkCss
                    }
                  >
                    <InboxOutlined />
                  </li>
                </Link>
              </Tooltip>
            )}
            <Tooltip
              title="Filters"
              placement="right"
              overlayStyle={{ marginLeft: 40 }}
            >
              <Link to="/filters/list">
                <li
                  style={activenavlink === "filters" ? activeLinkCss : linkCss}
                >
                  <FilterOutlined />
                </li>
              </Link>
            </Tooltip>
            {checkParentItemCondition("Column Template", user) && (
              <Tooltip
                title="Column Templates"
                placement="right"
                overlayStyle={{ marginLeft: 40 }}
              >
                <Link to="/column-templates/list">
                  <li
                    style={
                      activenavlink === "columnTemplates"
                        ? activeLinkCss
                        : linkCss
                    }
                  >
                    <DiffOutlined />
                  </li>
                </Link>
              </Tooltip>
            )}
            {checkParentItemCondition("Email Template", user) && (
              <Tooltip
                title="Email Templates"
                placement="right"
                overlayStyle={{ marginLeft: 40 }}
              >
                <Link to="/email-templates/list">
                  <li
                    style={
                      activenavlink === "emailTemplates"
                        ? activeLinkCss
                        : linkCss
                    }
                  >
                    <SendOutlined />
                  </li>
                </Link>
              </Tooltip>
            )}
            <Tooltip
              title="URLs DB"
              placement="right"
              overlayStyle={{ marginLeft: 40 }}
            >
              <Link to="/urls-db/all-non-blocked-urls">
                <li
                  style={
                    activenavlink === "blockedUrls" ? activeLinkCss : linkCss
                  }
                >
                  <InfoCircleOutlined />
                </li>
              </Link>
            </Tooltip>
            {checkParentItemCondition("Users", user) && (
              <Tooltip
                title="Global Settings"
                placement="right"
                overlayStyle={{ marginLeft: 40 }}
              >
                <Link to="/setting/team/list">
                  <li
                    style={
                      activenavlink === "teamManagement"
                        ? activeLinkCss
                        : linkCss
                    }
                  >
                    <SettingOutlined />
                  </li>
                </Link>
              </Tooltip>
            )}
          </ul>
        </>
      )}
    </div>
  );
}
