const CenterLayout = ({ children, height }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        ...(height && { height: height }),
      }}
    >
      {children}
    </div>
  );
};

export default CenterLayout;
