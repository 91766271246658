import React from "react";

import { Empty, Form, Input, Modal } from "antd";

//components
import PopupFooter from "../../PopupFooter";
import AppSpin from "../../AppSpin";

export default function DeleteFolder({
  deleteFolder,
  folder,
  setdeletefolderpopup,
  formloading,
}) {
  return (
    <Modal
      title="Delete Project Folder"
      visible={true}
      footer={null}
      onCancel={() => setdeletefolderpopup(false)}
    >
      <AppSpin spining={formloading}>
        <Empty
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxiQ_pU9StomrYj25siy9Y1zrofAjWGqDFMQ&usqp=CAU"
          imageStyle={{
            height: 90,
          }}
          description={
            <>
              <h3>Are you sure?</h3>
              <span>
                Once you delete this folder every project's <br></br>will be
                deleted belongs to this folder.
              </span>
            </>
          }
        />

        <Form
          layout="vertical"
          requiredMark={false}
          style={{ marginTop: 20 }}
          onFinish={() => deleteFolder(folder._id, setdeletefolderpopup)}
        >
          <Form.Item
            label={`Type - ${folder.name}`}
            name="folder"
            rules={[
              {
                required: true,
                message: "Please confirm this folder name!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("folder") === folder.name) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Folder name you typed do not match!")
                  );
                },
              }),
            ]}
          >
            <Input size="large" placeholder={`Type - ${folder.name}`} />
          </Form.Item>
          <PopupFooter btnText="Delete" action={setdeletefolderpopup} />
        </Form>
      </AppSpin>
    </Modal>
  );
}
