export const checkParentItemCondition = (item, user) => {
  if (!user.isSubAccount) {
    return true;
  }

  if (user.permissions[item]) {
    if (user.permissions[item].status) {
      return true;
    }
    return false;
  } else {
    return false;
  }
};
