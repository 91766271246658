import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

//libraies
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Space,
} from "antd";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";

//context
import SettingContext from "../../../context/Setting";

//components
import FormItem from "./FormItem";
import AppSpin from "../../AppSpin";

//utils
import { anchorApi } from "../../../utils/axios";
import HandleError from "../../../utils/errorHandle";

const { RangePicker } = DatePicker;

export default function UploadLogFilter({
  filtermodalvis,
  setfiltermodelvis,
  query,
  setselectedcolumn,
  setselectcolumn,
  source,
  log,
}) {
  let navigate = useNavigate();

  const [saveFilter, setSaveFiler] = useState(false);
  const [saveFilterLoading, setSaveFilerLoading] = useState(false);

  const [activefilter, setactivefilter] = React.useState(undefined);

  const { activeproject } = useContext(SettingContext);

  const [form] = Form.useForm();

  const [oldvalues, setoldvalues] = useState("not_ready");

  useEffect(() => {
    const set = async () => {
      const parsedQuery = await JSON.parse(query);

      if (parsedQuery !== null) {
        if (parsedQuery?.savedFilter) {
          setactivefilter(filter);
        } else {
          setactivefilter(undefined);
        }

        if (parsedQuery.filters) {
          const formattedQueries = [];

          const isDate = function (date) {
            return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
          };

          parsedQuery.filters?.map((mQuer) => {
            const queryObject = { ...mQuer };
            if (typeof mQuer.query === "object" && mQuer.column !== "Cycle") {
              queryObject["query"] = [];
              mQuer.query?.map((itemOb) => {
                if (isDate(itemOb)) {
                  const dateFormatted = moment(
                    moment(itemOb).format("MM-DD-YYYY"),
                    "MM-DD-YYYY"
                  );
                  queryObject["query"].push(dateFormatted);
                } else {
                  queryObject["query"] = itemOb;
                }
              });
            } else {
              if (isDate(mQuer.query)) {
                const dateFormatted = moment(mQuer.query, "MM-DD-YYYY");
                queryObject["query"] = dateFormatted;
              } else {
                queryObject["query"] = mQuer.query;
              }
            }
            if (mQuer.column === "Cycle") {
              console.log(parsedQuery.filters?.length);
              if (parsedQuery.filters?.length === 1) {
                formattedQueries.push({
                  column: null,
                  condition: null,
                  query: null,
                });
              }
            } else {
              formattedQueries.push(queryObject);
            }
          });

          setoldvalues(formattedQueries);
          form.setFieldsValue({
            filters: formattedQueries,
          });
        } else {
          setoldvalues(null);
          form.setFieldsValue({
            filters: [{ column: null, condition: null, query: null }],
          });
        }
      } else {
        setoldvalues(null);
        form.setFieldsValue({
          filters: [{ column: null, condition: null, query: null }],
        });
      }
    };

    set();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const filter = async (values) => {
    const parsedQuery = await JSON.parse(query);

    const includeLiveLinks = parsedQuery?.includeLiveLinks
      ? parsedQuery?.includeLiveLinks
      : undefined;

    const notStatus = parsedQuery?.notStatus
      ? parsedQuery?.notStatus
      : undefined;

    if (values.saveFilter) {
      //create filter

      setSaveFilerLoading(true);

      anchorApi
        .post(`/filter/create`, values)
        .then((res) => {
          setSaveFilerLoading(false);
          delete values.saveFilter;
          delete values.templateName;
          const query = JSON.stringify({
            ...values,
            pagination: { page: 1, limit: 50 },
            ...(includeLiveLinks !== undefined
              ? { includeLiveLinks: includeLiveLinks }
              : {}),
            ...(notStatus !== undefined ? { notStatus: notStatus } : {}),
          });
          setfiltermodelvis(false);

          navigate(`/project/upload-log/${activeproject?._id}?query=${query}`);
        })
        .catch((err) => {
          HandleError(err?.response?.data);
        });
    } else {
      delete values.saveFilter;
      delete values?.templateName;
      const query = JSON.stringify({
        ...values,
        pagination: { page: 1, limit: 50 },
        ...(includeLiveLinks !== undefined
          ? { includeLiveLinks: includeLiveLinks }
          : {}),
        ...(notStatus !== undefined ? { notStatus: notStatus } : {}),
      });

      setfiltermodelvis(false);
      navigate(
        `/project/upload-log/${activeproject?._id}?query=${query}&log=${log}`
      );
    }
  };

  const reset = async () => {
    const parsedQuery = await JSON.parse(query);

    const includeLiveLinks = parsedQuery?.includeLiveLinks
      ? parsedQuery?.includeLiveLinks
      : undefined;

    const queryString = JSON.stringify({
      ...(includeLiveLinks !== undefined
        ? { includeLiveLinks: includeLiveLinks }
        : {}),
    });

    setfiltermodelvis(false);
    navigate(
      `/project/upload-log/${activeproject?._id}?query=${queryString}&log=${log}`
    );
  };

  const quickFilter = async (value) => {
    const getValue = value;

    let startingDate = "";
    let endingDate = "";

    switch (getValue) {
      case "this_week":
        startingDate = moment().startOf("week").format("MM-DD-YYYY");
        endingDate = moment().endOf("week").format("MM-DD-YYYY");
        break;
      case "this_month":
        startingDate = moment().startOf("month").format("MM-DD-YYYY");
        endingDate = moment().endOf("month").format("MM-DD-YYYY");
        break;
      default:
        break;
    }

    const parsedQuery = await JSON.parse(query);
    const filters = parsedQuery?.filters ? parsedQuery?.filters : [];

    if (parsedQuery) {
      if (getValue === "current_cycle") {
        filters.push({
          column: "Cycle",
          condition: "==",
          query: {
            start: activeproject?.cycle_start,
            end: activeproject?.cycle_end,
          },
        });
      } else {
        //find the if the column "Last Modified" exists
        const index = filters.findIndex(
          (item) => item.column === "Last Modified"
        );

        //if it exists, update the query with the new value
        if (index !== -1) {
          filters[index].query = [startingDate, endingDate];
        } else {
          filters.push({
            column: "Last Modified",
            condition: "between",
            query: [startingDate, endingDate],
          });
        }
      }

      const queryString = JSON.stringify({
        ...parsedQuery,
        filters: filters,
      });

      setfiltermodelvis(false);
      window.location.href = `/project/upload-log/${activeproject?._id}?query=${queryString}&log=${log}`;
    } else {
      let filters = [];

      if (getValue === "current_cycle") {
        filters = [
          {
            column: "Cycle",
            condition: "==",
            query: {
              start: activeproject?.cycle_start,
              end: activeproject?.cycle_end,
            },
          },
        ];
      } else {
        filters = [
          {
            column: "Last Modified",
            condition: "between",
            query: [startingDate, endingDate],
          },
        ];
      }
      const queryString = JSON.stringify({
        filters: filters,
      });

      setfiltermodelvis(false);
      window.location.href = `/project/upload-log/${activeproject?._id}?query=${queryString}&log=${log}`;
    }
  };

  return (
    <AppSpin spining={saveFilterLoading}>
      {oldvalues === "not_ready" ? (
        <Button
          size="small"
          type={
            oldvalues !== null && activefilter === undefined
              ? "primary"
              : "dashed"
          }
          icon={<FilterOutlined />}
          onClick={() => setfiltermodelvis(true)}
        >
          Filter
        </Button>
      ) : oldvalues !== null && activefilter === undefined ? (
        <>
          <Badge count={oldvalues?.length}>
            <Button
              size="small"
              type={
                oldvalues !== null && activefilter === undefined
                  ? "primary"
                  : "dashed"
              }
              icon={<FilterOutlined />}
              onClick={() => setfiltermodelvis(true)}
            >
              Filter
            </Button>
          </Badge>
        </>
      ) : (
        <Button
          size="small"
          type={
            oldvalues !== null && activefilter === undefined
              ? "primary"
              : "dashed"
          }
          icon={<FilterOutlined />}
          onClick={() => setfiltermodelvis(true)}
        >
          Filter
        </Button>
      )}

      <Modal
        title="Filter URLs"
        footer={null}
        visible={filtermodalvis}
        onCancel={() => setfiltermodelvis(false)}
        width={800}
      >
        {oldvalues !== "not_ready" && (
          <Form
            layout="vertical"
            onFinish={filter}
            initialValues={{
              filters:
                oldvalues !== null
                  ? oldvalues
                  : [{ column: null, condition: null, query: null }],
            }}
            form={form}
          >
            <Form.List
              name="filters"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("At least one filter condition is required!")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <FormItem
                      key={key}
                      name={name}
                      add={add}
                      remove={remove}
                      restField={{ ...restField }}
                      fields={fields}
                      activeproject={activeproject}
                      setselectcolumn={setselectcolumn}
                      setselectedcolumn={setselectedcolumn}
                      oldvalues={oldvalues}
                      form={form}
                    />
                  ))}
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>

            <Space>
              <Button
                size="small"
                type="outlined"
                onClick={() => quickFilter("current_cycle")}
              >
                Current cycle
              </Button>
              <Button
                size="small"
                type="outlined"
                onClick={() => quickFilter("this_week")}
              >
                This week
              </Button>
              <Button
                size="small"
                type="outlined"
                onClick={() => quickFilter("this_month")}
              >
                This month
              </Button>
            </Space>

            <Divider />

            <Form.Item name="saveFilter" valuePropName="checked">
              <Checkbox onChange={(value) => setSaveFiler(!saveFilter)}>
                I want to save these filters
              </Checkbox>
            </Form.Item>
            {saveFilter && (
              <Form.Item
                name={"templateName"}
                label="Filter Name"
                rules={[
                  {
                    required: true,
                    message: "Filter name is required and needs to be unique",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            )}
            <Space
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={() => setfiltermodelvis(false)}>Cancel</Button>

              {oldvalues !== null && (
                <Button type="danger" onClick={reset}>
                  Reset
                </Button>
              )}

              <Button type="primary" htmlType="submit">
                Apply
              </Button>
            </Space>
          </Form>
        )}
      </Modal>
    </AppSpin>
  );
}
