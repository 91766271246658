import React, { useContext, useEffect } from "react";
import moment from "moment";

import { Form, Input, Select, DatePicker, Modal } from "antd";

//contexts
import ProjectFolderContext from "../../context/projectFolders";

//componenst
import AppSpin from "../AppSpin";
import { useState } from "react";
import ProjectContext from "../../context/projectContext";
import PopupFooter from "../PopupFooter";
import SettingContext from "../../context/Setting";
import { anchorApi } from "../../utils/axios";
import HandleSuccess from "../../utils/successHandle";
import HandleError from "../../utils/errorHandle";

const { Option } = Select;

export default function UpdateProjectBasicDetailsModal() {
  const [form] = Form.useForm();

  const { getAllProjectsFolders, folders } = useContext(ProjectFolderContext);

  const { updateModelVis, formloading, setUpdateModelVis, setFormloading } =
    useContext(ProjectContext);

  const { activeproject } = useContext(SettingContext);

  const [startingDate, setStaringDate] = useState(null);
  useEffect(() => {
    if (folders === null) {
      getAllProjectsFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeproject) {
      form.setFieldsValue({
        projectFolder: activeproject.projectFolder,
        name: activeproject.name,
        url: activeproject.url,
        email: activeproject.email,
        startingDate: moment(activeproject.startingDate, "MM-DD-YYYY"),
        dueDate: moment(activeproject.dueDate, "MM-DD-YYYY"),
        linkGoal: activeproject.linkGoal,
      });
      setStaringDate(activeproject.startingDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeproject]);

  const submitBasicDetails = (values) => {

    console.log(startingDate);

    setFormloading(true);
    anchorApi
      .post(`/project/update-basic/${activeproject._id}`, {
        ...values,
        startingDate,
      })
      .then((res) => {
        HandleSuccess({ msg: "Project basic details updated successfully!" });
        setFormloading(false);
        window.location.reload();
        //TODO: Avoid reload and update the folder context
      })
      .catch((err) => {
        HandleError(err?.response?.data);
        setFormloading(false);
      });
  };

  const onChange = (_, dateString) => {
    setStaringDate(dateString);
  };

  console.log(
    "activeproject.startingDate",
    moment(activeproject.startingDate, "MM-DD-YYYY")
  );

  return (
    <AppSpin spining={formloading}>
      <Modal
        visible={updateModelVis}
        footer={null}
        onCancel={() => setUpdateModelVis(false)}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={submitBasicDetails}
          form={form}
        >
          <Form.Item
            name="projectFolder"
            rules={[
              {
                required: true,
                message: "Project Folder field is required",
              },
            ]}
            label="Folder"
          >
            <Select size="large" placeholder="Select folder group" disabled>
              {folders !== null &&
                folders.map(
                  (folder, i) =>
                    folder.type !== "default" && (
                      <Option key={folder._id} value={folder._id}>
                        {folder.name}
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Project Name field is required!",
              },
            ]}
            label="Name"
          >
            <Input placeholder="Project" size="large" />
          </Form.Item>
          <Form.Item
            name="url"
            rules={[
              {
                required: true,
                message: "Project URL field is required!",
                type: "url",
              },
            ]}
            label="URL"
          >
            <Input placeholder="https://mydomain.com" size="large" />
          </Form.Item>
          <Form.Item
            name="linkGoal"
            rules={[
              {
                required: true,
                message: "Link goal field is required!",
              },
            ]}
            label="Link Goal"
          >
            <Input placeholder="20" size="large" />
          </Form.Item>
          <Form.Item
            name="startingDate"
            rules={[
              {
                required: true,
                message: "Starting Date field is required!",
                type: "date",
              },
            ]}
            label="Starting date"
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              onChange={onChange}
              format="MM-DD-YYYY"
            />
          </Form.Item>
          <PopupFooter action={setUpdateModelVis} />
        </Form>
      </Modal>
    </AppSpin>
  );
}
